.tab-section{
    padding-top:4px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:30px;
}
.tab-section .tab-item{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.tab-section .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}
.covidnews-list-section{
    position: absolute;
    top:80px;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#fff;
    padding-left:5px;
    padding-right:5px;
    overflow: auto;
}
.covidnews-list-section .list{
    border-bottom:1px solid #d9d9d9;
    padding:5px;
}
.covidnews-list-section .list .title{
    color:#0066ff;
    font-size: 16px;
    font-weight: bold;
}

.covidnews-list-section .list .shortdesc{
    color:#404040;
    font-size: 14px;
    margin-top:4px;
}
.covidnews-list-section .list .time{
    color:#a6a6a6;
    font-size: 12px;
    margin-top:3px;
}
.covidnews-list-section .list .shortdesc{
    color:#333;
    font-size: 13px;
    margin-top:3px;
}
.covidnews-list-section .no-data{
    color:#8c8c8c;
    font-size:18px;
    text-align: center;
    height:100px;
    line-height: 100px; 
    vertical-align: middle;
}

.covidnews-list-section .loadmore-container{
    text-align: center;
    padding:10px;
    font-size: 16px;
    color:#0066ff;
    text-decoration: underline;
}

.covidnews-list-section .loadmore-loading-container{
    text-align: center;
    padding:5px;

}
.covidnews-list-section .loadmore-loading-container img{
    width:50px;
    height:50px;
}

.text-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.tree-image-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.image-tree-mode{
    position: absolute;
    left: 0px;
    top: -10px;
    width: 90px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .thumbnail img {
    position: absolute;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait {
    width: 100%;
    height: auto;
  }
  

  .center-cropped {
    width: 100px;
    height: 100px;
    overflow:hidden;
    padding: 6px;
    margin: auto;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    /* background-color: #dbdbdb;
    padding: 6px; */
    transform: translateX(-50%);
  }


  .img-cropped {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
  }
  .img-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }

  .img-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  

.category-Text{
    color: #fff;
    background-color: #0066ff;
    /* display: inline-block; */
    padding: 0px 1px;
    margin-right: 5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.descriptime{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.descript-video{
    position: relative;
    display: flex;
    flex-direction: row;
}

.tree-mode-image{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-align: center;
}

.timeupdate{
    flex: 1;
    text-align: left;
    font-size: small;
}

.reading{
    flex: 1;
    text-align: right;
    font-size: small;
}

.image-big-mode{
    background-color: white;
    width:100%;
    height:200px;
  }
  .image-big-mode img{
    width:100%;
    height:100%;
    left: 50%;
    position: relative;
    background-color: #dbdbdb;
    transform: translateX(-50%);
  }



  .small-video{
    position: absolute;
    left: 0px;
    top: 5px;
    width: 100px;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .descript-container-border{
    border-bottom: 1px solid #d9d9d9;
  }

  .descript-container-small{
    margin-left: 100px;
    height: 100px;
    padding-left: 10px;
    position: relative;
  }

  .headline-text-name{
    color: #333;
    font-size: 14px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    /* display: inline; */
  }

  .sortdesc-headline-text{
    color: #999999;
    font-size: 12px;
    height: 38px;
    -o-text-overflow: inherit;
    text-overflow: inherit;
    overflow: hidden;
    white-space: normal;
  }

.tab-section-category-news{
    padding: 10px;
    border-bottom:1px solid #d9d9d9;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: inherit;
    overflow: auto;
}

.listing-item-category-news{
    flex: 1;
}

.icon-filter-cateory-news{
    flex:0
}


.covid-center-form-title{
  font-size: 18px;
  text-align: center;
  padding:10px;
  padding-top:20px;
  color:#005ce6;
}

.covid-center-form1{
  padding:0px;
  position: absolute;
  top:80px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow: auto;
  background-color:#ffffff;
}

.covid-center-form{
  padding:0px;
  position: absolute;
  top:80px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow: auto;
  background-color:#ffffff;
}


.field-container{
  background-color:#fff;
  padding:5px 20px;
  padding-top:10px;
}

.field-container .field-label{
  color:#000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom:5px;
}

.field-container .bottom-bordered{
  border-bottom:1px solid #d9d9d9;
  padding-bottom:5px;
}
.field-container .field-value{
  
}
.field-container .field-value input,select, textarea{
  border:0px;
  border-bottom:1px solid #d9d9d9 !important;
  border-radius: 0px;
  background-color:#fcfcfc;
  padding:5px;
  width:100%;
  margin-bottom: 20px;
}

select.covid-select{
  width:100%;
  padding:0px;
  text-align:right;
}
select.covid-select option{
  text-align:right;
}
.field-container .react-datepicker-wrapper{
  display: inherit;
}
.field-container .react-datepicker__input-container{
  display: inherit;
}
.date-picker{
  width:100%;
}

.submit-button{
  position: absolute;
  bottom:0;
  left:0;
  right:0;
  color:rgb(0, 0, 0);
  background-color:#f8f8f8;
  margin-top: 10px;
  padding:12px;
  text-align: center;
}

.covid-button-container{
  position: absolute;
  bottom:0px;
  left:0px;
  right:0px;
  padding: 5px;
  background-color: white;
}
.covid-button-container button{
  border-radius:0px;
  height:50px;
  border-radius: 10px;
}

.continer-payment-method-covid{
  margin-bottom: 50px;
}

.continer-request-covid{
  margin-bottom: 10px;
}

.covid-list-menu{
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}
