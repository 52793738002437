/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}*/
table{
  width:100%;
}

.main-container{
  background-color:#f2f2f2;
  height: 100vh;
  overflow: auto;
}
.header{
  /*background-image: url('./images/me_pic_head-bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;*/
  width:100%;
  /*height:150px;*/
}
.header img{
  width:100%;
  height:100%;
}

.headerHome{
  /*background-image: url('./images/me_pic_head-bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;*/
  /* padding: 10px; */
  background-color: white;
  width:100%;
  /* height:150px; */
}
.headerHome img{
  width:100%;
  height:100%;
}

.headerHomeBottom{
  /*background-image: url('./images/me_pic_head-bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;*/
  /* padding: 10px; */
  background-color: white;
  width:100%;
  /* height:150px; */
}
.headerHomeBottom img{
  width:100%;
  height:120%;
}

.icons{
  background-color:#ffffff;
  /* margin-top: 8px; */
  margin-bottom:8px;
  padding-top:8px;
  padding-bottom:8px;
}

.icon{
  text-align: center;
  line-height: 1.2;
  padding:4px;
  padding-top:8px;
  padding-bottom:8px;
}

.big-icons{
  background-color:#fff;
  padding-top:4px;
  padding-bottom:4px;
}

.big-image-container{
  /*padding:4px;*/
  /*box-shadow: 0px 0px 4px 1px rgba(0,0,0,.1);*/
  position: relative;
}
.big-image-col{
  padding-top:4px;
  padding-bottom:4px;
}
.big-image-container img{
  width:100%;
  position: relative;
}

.big-image-label{
  position: absolute;
  bottom:0px;
  left:0px;
  right:0px;
  height:20px;
  color:#000;
  text-align: right;
  padding-right:20px;
  padding-bottom:30px;
  font-weight: bold;
  font-size: 13px;
  /*background-color:rgba(255,255,255,.4);*/
}

.link-label{
  font-size: 11px;
  color:#000;
  text-decoration: none;
  position: relative;
}

.link-label img{
  width:60px;
  margin-bottom:6px;
}

.imgdefault{
  border:1px solid #d9d9d9;
}
.no-data{
  height:100px;
  line-height: 100px;
  width:100%;
  text-align: center;
  color:#d9d9d9;
  font-size: 16px;
}
/* 
.root {
  height: 18;
  width: 18;
  cursor: 'pointer';
  border: 0;
  background: 'none';
  padding: 0;
}

.dot {
  backgroundColor: '#2196F3';
  height: 12;
  width: 12;
  borderRadius: 6;
  margin: 3;
}
.active {
  backgroundColor: '#ffff';
  border: 30px solid green;
} */