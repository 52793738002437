.directory-list-item{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
}

.directory-list-item .name-container{
    font-size:16px;
    color:#000;
}

.directory-list-item .phone-container{
    font-size: 13px; 
    color:#8c8c8c;
}
.directory-list-item .address-container{
    font-size: 13px; 
    color:#8c8c8c;
}

.directory-list-item .image-container{
    position: absolute;
    left:0px;
    top:20px;
    width:90px;
    height:100px;
    vertical-align: middle;
    text-align: center;

}

.directory-list-item .image-container img{
    max-height:80px;
    max-width:80px;
    margin:auto;
}