.orderfood-center {
  padding-top: 10px;
}

.orderfood-center .padding-container-left {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
}
.orderfood-center .padding-container-right {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
}

.orderfood-center .img-container {
  text-align: center;
  position: relative;
  height: 150px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.orderfood-center .img-container img {
  width: 100%;
  max-height: 150px;
  margin: auto;
}

.orderfood-center .img-container .name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}

.orderfood-list {
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}
.orderfoods-list-col-dot {
  padding: 5px 10px;
  text-align: center;
  width: 30px;
  font-size: 9px;
}

.orderfoods-list-col1 {
  padding: 15px 10px;
}
.orderfoods-list-col1 .orderfoods-name {
  font-size: 12px;
  /* color:#000; */
  color: #8c8c8c;
}
.orderfoods-list-col1 .orderfoods-desc {
  font-size: 14px;
  color: #000;
}

.orderfoods-list-col-price {
  padding: 10px;
  text-align: right;
  padding-right: 20px;
}
.orderfoods-list-col-price .orderfoods-price {
  font-size: 14px;
  color: #4d4d4d;
}

.orderfoods-list-col-qty {
  padding-right: 10px;
  width: 90px;
}
.orderfoods-list-col-qty .orderfoods-btn {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #ff9900;
  margin-top: -2px;
}
.orderfoods-list-col-qty .orderfoods-qty {
  display: inline-block;
  margin-right: 3px;
  margin-left: 3px;
  border: 1px solid #d9d9d9;
  width: 30px;
  text-align: center;
  font-size: 14px;
}

.orderfood-status-label {
  color: #8c8c8c;
  font-size: 13px;
  text-align: center;
}

.orderfood-status-value {
  font-size: 16px;
  text-align: center;
}

.orderfoods-scroll-view {
  position: absolute;
  top: 76px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #fafafa;
}

.orderfoods-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #005ce6;
  color: #fff;
  font-size: 16px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}

.orderfood-icons {
  background-color: #fff;
}

.orderfood-icons-container {
  border-bottom: 1px solid #d9d9d9;
  overflow: auto;
}

.orderfood-icon-column {
}

.orderfood-link-label {
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  color: #8c8c8c;
}

.orderfood-link-label img {
  width: 40px;
  height: 40px;
}

table.table-icon {
  width: inherit;
}

.orderfood-link-active {
  color: #00abff !important;
  padding-bottom: 25px;
}
