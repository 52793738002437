
.list-item .data-container5{
    margin:10px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
}

.list-item .relative-container .imgitem-container5 img{
    height:50px;
    width:50px;
    border-radius: 50px;
    margin:auto;
}

.list-item .relative-container .imgitem-container5 {
    position: absolute;
    left:-10px;
    top:-2px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .moments-name{
    font-size: 15px !important;
    color:#005580;
    margin-left: 60px;
}
.list-item .moments-desc{
    margin-top: 15px;
    font-size: 14px !important;
    color:#666666;
}

.list-item .moments-date{
    font-size: 12px !important;
    color:#666666;
    margin-left: 60px;
}

.moments-texbox{
    margin-bottom:5px;
}

.btn-addcomment{
    font-size: 13px !important;
    margin-top: 5px;
}

.moments-comment{
    margin-top:10px;
}

