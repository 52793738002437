
.no-data-available{
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color:#d9d9d9;
}



.list-item .data-container7{
    margin:10px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
}

.list-item .relative-container .imgitem-container4 img{
    height:50px;
    width:50px;
    border-radius: 50px;
    margin:auto;
}

.list-item .relative-container .imgitem-container4{
    position: absolute;
    left:-10px;
    top:-2px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .moments-name{
    font-size: 15px !important;
    color:#005580;
    margin-left: 10px;
}