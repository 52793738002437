.list-icon {
  font-size: 35px;
}

span.paid {
  color: #00abff;
}

span.Received {
  color: #008dc1;
}

span.pending {
  color: #ff8d00;
}

.payment-list-item {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  /* visibility: hidden; */
}

.payment-list-item .name-container {
  font-size: 16px;
  color: #000;
}

.payment-list-item .category-container {
  font-size: 13px;
  color: #8c8c8c;
}
.payment-list-item .customerid-container {
  font-size: 13px;
  color: #8c8c8c;
}

.payment-list-item .paiddate-container {
  font-size: 12px;
  color: #29a329;
  text-align: right;
}
.payment-list-item .Received-container {
  font-size: 12px;
  color: #008dc1;
  text-align: right;
}
.payment-list-item .unpaid-container {
  font-size: 12px;
  color: #ff8d00;
  text-align: right;
}

.payment-list-item .amount-container {
  color: #666666;
  font-size: 15px;
  text-align: right;
}
