.merchant-profile-house {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile-house .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile-house .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile-house .menu-section-container {
}
.merchant-profile-house .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile-house .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile-house
  .menu-section-container
  td.category-column
  .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile-house .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile-house .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile-house
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile-house .menu-banner-container {
  padding: 10px;
}
.merchant-profile-house .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile-house .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile-house .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile-house .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile-house .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url("../../../images/banner-marketplace/House-Keeping.png")
    no-repeat top;
  background-size: 100% 150px;
}
.merchant-profile-house .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile-house .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile-house .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: initial;
}
.merchant-profile-house .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile-house .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile-house .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile-house .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile-house .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile-house .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile-house .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile-house .merchant-info-container table {
  width: 100%;
}
.merchant-profile-house .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile-house .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile-house .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile-house .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile-house .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile-house .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile-house .cart-icon {
  font-size: 17px;
}

.merchant-payment-house .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url("../../../images/banner-marketplace/House-Keeping.png")
    no-repeat top;
  background-size: 100% 110px;
}

.merchant-payment-house .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment-house .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment-house .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment-house .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment-house .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  background-color: #007bff;
  /* background-color: #ff9933; */
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}
