.merchant-payment{
    background-color: #fff;
}

.merchant-payment .picture-container{
    background-color:#fff;
    text-align: center;
    padding:0px !important;
    height: 151px;
    line-height: 151px;
    vertical-align: middle;
    border-bottom:1px solid #d9d9d9;
    margin-top:0px;
}
.merchant-payment .picture-container img{
    max-height: 150px;
    max-width: 150px;
    vertical-align: middle;
    margin-top:-4px;
}


.merchant-info{
    width:100%;
    padding-top:10px;
    padding-bottom:0px;
    padding-left:5px;
    padding-right:5px;
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}

.merchant-info .relative-container{
    position: relative;
    
}

.merchant-info .imgitem-container{
    position: absolute;
    left:0px;
    top:0px;
    width:80px;
    height:80px;
    line-height: 80px;
    /*border:1px solid #d9d9d9;*/
    vertical-align: middle;
    text-align: center;

}

.merchant-info .imgitem-container img{
    max-height:60px;
    max-width:60px;
    margin:auto;
}
.merchant-info .data-container{
    margin-left:90px;
    height:110px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left{
    margin-left:0px;
}

.merchant-info .data-container .name-container{
    color:#333;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.merchant-info .data-container .name-container .category{
    color:#fff;
    background-color:#00cc99;
    display: inline;
    padding:0px 1px;
    margin-right:5px;
    font-size: 12px;
}
.merchant-info .data-container .shortdesc-container{
    color:#999999;
    font-size: 12px;
    height:53px;
    overflow: hidden;
}
.merchant-info .data-container .tags-container{
    position: absolute;
    bottom:8px;
    left:0px;
    right:0px;
    padding-left:5px;
}
.merchant-info .data-container .tags-container .tag{
    font-size: 12px;
    background-color:#e6e6e6;
    color:#808080;
    display: inline;
    padding:3px 10px;
    border-radius: 10px;
    margin-right:3px;

}

.merchant-payment .menu-section-container{

}
.merchant-payment .menu-section-container table{
    width: 100%;
    border-collapse: collapse;
}
.merchant-payment .menu-section-container td.category-column{
    width:80px;
    background-color:#fdfdfd;
    color:#8c8c8c;
    vertical-align: top;
    padding-right:0px;
}
.merchant-payment .menu-section-container td.category-column .category-item{
    padding-top:5px; 
    padding-bottom:5px;
    padding-left:10px;
    font-size: 12px;
}
.merchant-payment .menu-section-container td.category-column .active{
    background-color:#fff;
    color:#333333;
    font-weight: bold;
}

.merchant-payment .menu-section-container td.menu-column{
    vertical-align: top;
}
.merchant-payment .menu-section-container td.menu-column .commodity-list-container{
    /* padding-bottom:50px; */
}

.shopping-cart-button-container{
    position: absolute;
    bottom:15px;
    right:15px;
    background-color: #ff9933;
    color:#000;
    padding:3px 10px;
    z-index: 100;
    border-radius:10px;
    box-shadow: 1px 0px 4px 1px rgba(0,0,0,.3); 

}

.merchant-payment .menu-banner-container{
    padding:10px;
}
.merchant-payment .menu-banner-container img{
    width:100%;
    max-height: 100px;
    border-radius: 8px;
}
.merchant-payment .tabs-container{
    
    border-bottom:1px solid #f2f2f2;
    padding-left:10px;
    padding-right:10px;
}
.merchant-payment .tabs-container .tab{
    display: inline-block;
    padding:0px 15px;
    color:#808080;
}
.merchant-payment .tabs-container .tab-active{
    display: inline-block;
    border-bottom:1px solid #ff9933;
    color:#ff9933;
}

.merchant-payment .profile-outer-container{
    padding:10px;
    padding-top:40px;
    background:url('../../../images/merchantbg.jpg') no-repeat top;
    background-size: 100% 110px;

}
.merchant-payment .profile-inner-container{
    border-radius: 6px;
    box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, .5);
    padding:6px 10px;
    border:1px solid rgba(242, 242, 242, .8);
    position: relative;
    background-color:#fff;
}
.merchant-payment .profile-inner-container .profile-name{
    font-size: 18px;
	text-align: center;
}
.merchant-payment .profile-inner-container .profile-desc{
    font-size: 22px;
    text-align: center;
    color:#3399ff;
}
.merchant-payment .profile-inner-container .profile-items{
    margin-top:5px;
    text-align: center;
    color:#a6a6a6;
}
.merchant-payment .profile-inner-container .profile-tags .tag{
    display: inline-block;
    background-color:#f2f2f2;
    color:#333333;
    padding:1px 4px;
    font-size: 14px;
    margin-right:3px;
}
.merchant-payment .profile-inner-container .profile-img{
    position: absolute;
    width:65px;
    height:65px;
    top:0px;
    right:10px;
    transform: translate(0, -50%);
    border-radius:6px;
}
.merchant-payment .profile-inner-container .profile-img img{
    width:100%;
    height:65px;
    border-radius:6px;
}

.merchant-payment .no-info{
    height: 140px;
    text-align: center;
    background-color:#fff;
    color:#808080;
    line-height: 140px;
    vertical-align: middle;
}

.merchant-payment .merchant-info-container{
    padding:20px 10px;
}
.merchant-payment .merchant-info-container table{
    width:100%;
}
.merchant-payment .merchant-info-container td{
    padding:8px 5px;
    font-size: 14px;
}
.merchant-payment .merchant-info-container tr{
    border-bottom:1px solid #f2f2f2;
}

.merchant-payment .floating-chart {
	position: fixed;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 50px;
	z-index: 2500;
}