.list-item .relative-container .imgitem-container img{
    height:80px;
    width:80px;
    margin:auto;
}

.list-item .relative-container .imgitem-container{
    position: absolute;
    left:0px;
    top:-10px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .data-container .name-container .categoryNews{
    color:rgb(0, 126, 245);
    display: inline-block;
    padding:0px 0px;
    margin-right:5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}