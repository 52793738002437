.project-detail{
    background-color:#f2f2f2;
}

.project-detail-header{
    background-color:#fff;
}

.project-detail-img-container{
    text-align: center;
    padding:10px;
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
}
.project-detail-img-container img{
    max-height: 150px;
    max-width: 150px;
}
.project-detail-name-container{
    text-align: center;
    font-size: 16px;
    padding:20px;
    padding-bottom:10px;
}
.project-detail-name-container span.aboutus-link{
    font-size: 12px; padding:2px; 
    border:1px solid #00cc99;
    color:#00cc99;
    border-radius: 7px;
}
.project-detail-company{
    text-align: center;
    font-size: 15px;
    color:#0066ff;
    padding-bottom:20px;
}
.project-detail-like-container{
    text-align: center;
    padding:4px;
}
.project-detail-like-container table{
    margin:auto;
}

.project-detail-like-container td{
    padding:2px 7px;
    color:#8c8c8c;
    font-size: 13px;
    
}
.project-detail-tab-container{

}
.project-detail-tab-container table{
    width:100%;
}
.project-detail-tab-container td{
    padding-top:10px;
    padding-bottom:10px;
}
.project-detail-tab-container .project-detail-tab-item{
    
    text-align: center;
    border-right: 1px solid #d9d9d9;
    height: 60px;
}
.project-detail-tab-container .tab-active{
    color:#00cc99;
    border-bottom:2px solid #00cc99;
}
.project-detail-tab-container .item-last{
    border-right:0px;
}
.project-detail-tab-container .project-detail-tab-icon{
    text-align: center;
    font-size: 25px;
}
.project-detail-tab-container .project-detail-tab-name{
    text-align: center;
    font-size: 13px;
}

.project-detail-info{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.project-detail-info table{
    width:100%;
}

.project-detail-info .column-field{
    color:#8c8c8c;
    padding:2px 4px;
    padding-bottom:6px;
    width:100px;
}
.project-detail-info .column-value{
    color:#000;
    padding:2px 4px;
    padding-bottom:6px;
}

.project-detail-description{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
    font-size:13px;
}
.project-detail-description .description-label{
    padding-bottom:15px;
    font-size: 15px;
    font-size: bold;
} 

.project-detail-list-container{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}