.Modal {
    position: absolute;
    top: 120px;
    left: 340px;
    right: 340px;
    bottom: 120px;
    background-color: papayawhip;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
  }