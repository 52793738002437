.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}


.payment-scroll-view-multiple{
    position: absolute;
    top:45px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fff;
    margin-bottom: 70px;
}

.payment-select-unit{
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
}
  
.payment-icons{
    background-color:#fff;
}

.payment-icons-container{
    border-bottom:1px solid #d9d9d9;
    overflow: auto;
}

.payment-icon-column{

}

.payment-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}
.payment-link-label img{
    width:40px;
    height:40px;
}
table.table-icon{
    width:inherit;
}

.payment-link-active{
    color:#00abff !important;
}

.list-icon{
    font-size: 35px;
}

span.paid{
    color: #00abff;
}

span.pending{
    color: #ff8d00;
}

.payment-list-item{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
    /* visibility: hidden; */
}

.payment-list-item .name-container{
    font-size:16px;
    color:#000;
}

.payment-list-item .category-container{
    font-size: 13px; 
    color:#8c8c8c;
}
.payment-list-item .customerid-container{
    font-size: 13px; 
    color:#8c8c8c;
}

.payment-list-item .paiddate-container{
    font-size: 12px;
    color:#29a329;
    text-align: right;
}
.payment-list-item .unpaid-container{
    font-size: 12px;
    color:#ff8d00;
    text-align: right;
}

.payment-list-item .amount-container{
    color:#666666;
    font-size: 15px;
    text-align: right;
}

.fullbuttonMultiple{
    width:100%;
    border-radius: 20px;
}