.health-park-introduction{
    background-color:#fff;
}

.health-park-introduction .tab-section{
    padding-top:0px !important;
    background-color:rgb(255, 255, 255);
    border-bottom:1px solid #d9d9d9;
    height:40px;
}
.health-park-introduction .tab-section .tab-item{
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    color:#000;
    height:39px;
    line-height: 39px;
    padding-bottom:0px !important;
}
.health-park-introduction .tab-section .active-tab{
    color:rgb(117, 15, 233);
    border-bottom:2px solid rgb(117, 15, 233);
}

.health-park-introduction .body-section{
    padding-left: 0px;
    padding-right: 0px;

}

.health-park-introduction .img-container{
    width: 100%;
    margin-bottom:10px;
}
.health-park-introduction .img-container img{
    width: 100%;
}


.health-park-introduction .img-container2{
    width: 100%;
    margin-bottom:2px;
}
.health-park-introduction .img-container2 img{
    width: 100%;
}
.health-park-introduction .title{
    padding:2px;
    color:#000;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-weight: bold;
    margin-bottom:10px;
}
.health-park-introduction .description{
    padding:2px;
    color:#000;
    font-size:14px;
    text-align: justify;
}

.health-list{
    border-bottom:10px solid #d9d9d9;
    background-color:#fff;
}

.health-list2{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}

.health-list-col-dot{
    padding:5px 10px;
    text-align: center;
    width:30px;
    font-size: 9px;
}

.health-list-col1{
    padding:15px 10px;
}
.health-list-col1 .health-name{
    font-size: 16px;
    color:#000;
}
.health-list-col1 .health-desc{
    font-size: 13px;
    color:#8c8c8c;
}

.health-list-col-price{
    padding:10px;
    text-align: right;
    padding-right:20px;
}
.health-list-col-price .health-price{
    font-size: 14px;
    color:#4d4d4d;
}

.health-list-col-qty{
    padding-right:10px;
    width:90px;
}
.health-list-col-qty .health-btn{
    display: inline-block;
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color:#ff9900;
    margin-top:-2px;
}
.health-list-col-qty .health-qty{
    display: inline-block;
    margin-right:3px;
    margin-left:3px;
    border:1px solid #d9d9d9;
    width:30px;
    text-align: center;
    font-size: 14px;
}

.health-list-col1 .health-checkin{
    font-size:13px;
    color:#8c8c8c;
}
.health-list-col1 .health-checkout{
    font-size:13px;
    color:#8c8c8c;
}

.today-declare{
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-bottom:1px solid #d9d9d9;
}

.today-declare-u{
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-bottom:5px solid #d9d9d9;
}

.flex-left{
    flex: 1;
    font-size: small;
    text-align: left;
}

.flex-right{
    flex: 1;
    text-align: right;
    font-size: small;
}

