.home-header {
  width: 100%;
}

.home-banner-container {
  height: 280px;
  line-height: 100px;
  text-align: center;
  vertical-align: middle;
}

img.home-banner {
  max-width: 100%;
  max-height: 100%;
}

video.home-banner {
  max-width: 100%;
  max-height: 100%;
}
.adv-title {
  color: #333;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
}
.adv-content {
  padding: 0px;
}

/*example1: using background image
The best way to do this is to use position:absolute to child span of parent anchor.*/

a.notif {
  position: relative;
  display: block;
  height: 50px;
  width: 50px;
  background: url("http://i.imgur.com/evpC48G.png");
  background-size: contain;
  text-decoration: none;
}
.num {
  position: absolute;
  right: 11px;
  top: 6px;
  color: #fff;
}
.link-label .moment-icon {
  display: inline-block;
  position: relative;
}
.link-label .moment-icon .fa-moment {
  position: absolute;
  top: 0px;
  right: -2px;
  height: 15px;
  width: 15px;
  background-color: red;
}
span.num {
  font-size: 12px !important;
  top: 0px;
  right: 1px;
}

.home-icons-container {
  margin-top: 10px;
}
.home-icons-container::after {
  content: " ";
  display: table;
  clear: both;
}
.home-icons-container .icon-column {
  width: 25%;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.continer-icon-center {
  margin-bottom: 4px;
  margin-top: 4px;
}

.container-data-global {
  position: relative;
  display: flex;
  flex-direction: row;
}

.center-icon-row {
  align-content: center;
  text-align: center;
  margin-top: 10;
  padding: 6px;
}

.center-icon-row-bottom {
  align-content: center;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}

.content-icon-colom {
  background-color: white;
  /* padding-top: 4px;
  padding-bottom: 4px; */
  margin: 6px;
  border: 1px solid rgb(212, 212, 212);
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.content-corona-positif {
  /* background-color: red; */
  background-color: white;
  margin: 4px;
  border: 1px solid rgb(211, 137, 0);
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.content-corona-sembuh {
  background-color: white;
  margin: 4px;
  border: 1px solid rgb(0, 160, 0);
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.content-corona-meninggal {
  /* background-color: rgb(187, 0, 171); */
  background-color: white;
  margin: 4px;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.corona-data-container-column {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.row-data-corona-container {
  /* margin-bottom: 10px; */
  position: relative;
  display: flex;
  flex-direction: row;
}

.column-data-corona-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.column-text-corona-data {
  position: relative;
  display: flex;
  flex-direction: column;
}

.content-img-text-icon {
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: center;
}

.content-text-data {
  padding-bottom: 8px;
  padding-top: 8px;
  justify-content: center;
}

.text-on-icon {
  text-align: center;
  margin-top: 2;
  font-size: 10;
}

.text-title-size {
  font-size: 10;
}

.filter-data-item-category {
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.click-tracing-container {
  text-align: center;
  margin-bottom: 10px;
  /* margin-top: 8px; */
  position: relative;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-style: oblique;
  font-family: none;
  font-size: small;
  justify-content: center;
  color: rgb(2, 81, 207);
  text-decoration-line: underline;
}
