.list-icon{
    font-size: 35px;
}

span.paid{
    color: #00abff;
}

span.pending{
    color: #ff8d00;
}

.visitor-list-item{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
}

.visitor-list-item .name-container{
    font-size:16px;
    color:#000;
}

.visitor-list-item .address-container{
    font-size: 13px; 
    color:#8c8c8c;
}
.visitor-list-item .date-container{
    font-size: 13px; 
    color:#8c8c8c;
}

.visitor-list-item .image-container{
    position: absolute;
    left:0px;
    top:0px;
    width:90px;
    height:100px;
    vertical-align: middle;
    text-align: center;

}

.visitor-list-item .image-container img{
    max-height:80px;
    max-width:80px;
    margin:auto;
}