.whitebg{
    background-color:#fff;
}
.top-navigation{
    padding:5px 10px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
}
.top-navigation .back-button{
    color:#0066ff;
    display: inline;
}
.privacy-title {
    color:#000;
    font-size: 20px;
    font-weight: bold;
    margin-top:5px;
    margin-bottom: 10px;
    padding:10px;
    border-bottom:2px solid #000;
}

.privacy-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.privacy-content .privacy-description{
    font-size: 14px;
    text-align: justify;
    margin-top:5px;
}
.privacy-content .privacy-datetime{
    font-size: 12px;
    color:#bfbfbf;
    text-align: left;
    margin-top:5px;
}
.text-bold{
    font-weight: bold;
}
.text-right{
    text-align: right;
}