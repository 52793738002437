.service-center-category-page{
    background-color:#fff;
}

.servicecentercategory-icons-container{
}
.servicecentercategory-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.servicecentercategory-icons-container .servicecentercategory-icon-column{
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top:10px;
    margin-bottom:5px;
}

.category-left-column{
    padding-left:20px; 
    padding-right:10px;
}
.category-right-column{
    padding-left:10px;
    padding-right:20px;
}

.service-center-category-content{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.2);
    border-radius:10px;
    overflow: hidden;
}

.servicecentercategory-link-label img.image-250 {
    width: 100%;
}

.servicecentercategory-link-label{
    
}

.servicecenter-category-label-name{
    font-size: 15px;
    padding:5px;
    color:#4d4d4d; 
}