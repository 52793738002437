.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.directory-scroll-view{
    position: absolute;
    top:45px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fff;
}

.directory-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}
.directory-link-label img{
    width:40px;
    height:40px;
}
table.table-icon{
    width:inherit;
}

.directory-link-active{
    color:#00abff !important;
}