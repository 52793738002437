.no-data-bill {
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #888;
  font-size: 16px;
}

.payment-scroll-view {
  position: absolute;
  top: 116px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #fff;
}

.payment-scroll-view2 {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #fff;
  margin-bottom: 70px;
}

.payment-select-unit {
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.payment-icons {
  background-color: #fff;
}

.payment-icons-container {
  border-bottom: 1px solid #d9d9d9;
  overflow: auto;
}

.payment-icon-column {
}

.payment-link-label {
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  color: #8c8c8c;
}
.payment-link-label img {
  width: 40px;
  height: 40px;
}
table.table-icon {
  width: inherit;
}

.payment-link-active {
  color: #00abff !important;
}
