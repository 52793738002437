body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}*/
table{
  width:100%;
}

.main-container{
  background-color:#f2f2f2;
  height: 100vh;
  overflow: auto;
}
.header{
  /*background-image: url('./images/me_pic_head-bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;*/
  width:100%;
  /*height:150px;*/
}
.header img{
  width:100%;
  height:100%;
}

.headerHome{
  /*background-image: url('./images/me_pic_head-bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;*/
  /* padding: 10px; */
  background-color: white;
  width:100%;
  /* height:150px; */
}
.headerHome img{
  width:100%;
  height:100%;
}

.headerHomeBottom{
  /*background-image: url('./images/me_pic_head-bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;*/
  /* padding: 10px; */
  background-color: white;
  width:100%;
  /* height:150px; */
}
.headerHomeBottom img{
  width:100%;
  height:120%;
}

.icons{
  background-color:#ffffff;
  /* margin-top: 8px; */
  margin-bottom:8px;
  padding-top:8px;
  padding-bottom:8px;
}

.icon{
  text-align: center;
  line-height: 1.2;
  padding:4px;
  padding-top:8px;
  padding-bottom:8px;
}

.big-icons{
  background-color:#fff;
  padding-top:4px;
  padding-bottom:4px;
}

.big-image-container{
  /*padding:4px;*/
  /*box-shadow: 0px 0px 4px 1px rgba(0,0,0,.1);*/
  position: relative;
}
.big-image-col{
  padding-top:4px;
  padding-bottom:4px;
}
.big-image-container img{
  width:100%;
  position: relative;
}

.big-image-label{
  position: absolute;
  bottom:0px;
  left:0px;
  right:0px;
  height:20px;
  color:#000;
  text-align: right;
  padding-right:20px;
  padding-bottom:30px;
  font-weight: bold;
  font-size: 13px;
  /*background-color:rgba(255,255,255,.4);*/
}

.link-label{
  font-size: 11px;
  color:#000;
  text-decoration: none;
  position: relative;
}

.link-label img{
  width:60px;
  margin-bottom:6px;
}

.imgdefault{
  border:1px solid #d9d9d9;
}
.no-data{
  height:100px;
  line-height: 100px;
  width:100%;
  text-align: center;
  color:#d9d9d9;
  font-size: 16px;
}
/* 
.root {
  height: 18;
  width: 18;
  cursor: 'pointer';
  border: 0;
  background: 'none';
  padding: 0;
}

.dot {
  backgroundColor: '#2196F3';
  height: 12;
  width: 12;
  borderRadius: 6;
  margin: 3;
}
.active {
  backgroundColor: '#ffff';
  border: 30px solid green;
} */
.picture-container{
    margin-top:3px;
    padding:3px 0px;
}

.picture-container .custom-col{   
    padding:2px;    
    overflow: hidden;
}

.picture-container .multi-image{   
    width:100%;
}
.showcase-container{
    position: fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background-color:rgba(0,0,0,1.7);
    padding:20px;
    z-index: 2000;
    overflow: auto;
    text-align: center;
    padding-top:50px;
}

.showcase-container .middle-container{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    overflow: auto;
}
.showcase-container .middle-container table{
    width: inherit;
}

.showcase-container img{
    
    max-width: 100%;
    max-height: 100%;
    
}


.showcase-container .close-container{
    position: absolute;
    top:10px;
    right:20px;
    display: inline-block;
    
}
.showcase-container .close-container .close-icon{
    color:#fff;
    font-size: 30px;
    
}



.single-image{
    
    max-width:100%;
    max-height:220px;
}

.single-full-image{
    width: 100%;
}

.list-item .relative-container .imgitem-container img{
    height:80px;
    width:80px;
    margin:auto;
}

.list-item .relative-container .imgitem-container{
    position: absolute;
    left:0px;
    top:-10px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .data-container .name-container .categoryNews{
    color:rgb(0, 126, 245);
    display: inline-block;
    padding:0px 0px;
    margin-right:5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tab-section{
    padding-top:4px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:30px;
}
.tab-section .tab-item{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.tab-section .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}

.news-tab-section{
    height:30px;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 4px;
    padding-right: 4px;
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
}

.news-tab-section .tab-item-news{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.news-tab-section .active-tab-news{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}


.news-list-section{
    position: absolute;
    top:70px;
    bottom:10px;
    left:0px;
    right:0px;
    background-color:#fff;
    padding-left:5px;
    padding-right:5px;
    overflow: auto;
}
.news-list-section .list{
    border-bottom:1px solid #d9d9d9;
    padding:5px;
}
.news-list-section .list .title{
    color:#0066ff;
    font-size: 16px;
    font-weight: bold;
}

.news-list-section .list .shortdesc{
    color:#404040;
    font-size: 14px;
    margin-top:4px;
}
.news-list-section .list .time{
    color:#a6a6a6;
    font-size: 12px;
    margin-top:3px;
}
.news-list-section .list .shortdesc{
    color:#333;
    font-size: 13px;
    margin-top:3px;
}
.news-list-section .no-data{
    color:#8c8c8c;
    font-size:18px;
    text-align: center;
    height:100px;
    line-height: 100px; 
    vertical-align: middle;
}

.news-list-section .loadmore-container{
    text-align: center;
    padding:10px;
    font-size: 16px;
    color:#0066ff;
    text-decoration: underline;
}

.news-list-section .loadmore-loading-container{
    text-align: center;
    padding:5px;

}
.news-list-section .loadmore-loading-container img{
    width:50px;
    height:50px;
}

.text-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.tree-image-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.image-tree-mode{
    position: absolute;
    left: 0px;
    top: -10px;
    width: 90px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .thumbnail img {
    position: absolute;
    height: 100%;
    width: auto;
    transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait {
    width: 100%;
    height: auto;
  }
  

  .center-cropped {
    width: 100px;
    height: 100px;
    overflow:hidden;
    padding: 6px;
    margin: auto;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    /* background-color: #dbdbdb;
    padding: 6px; */
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }
  .center-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }


  .img-cropped {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
  }
  .img-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }

  .img-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  

.category-Text{
    color: #fff;
    background-color: #0066ff;
    /* display: inline-block; */
    padding: 0px 1px;
    margin-right: 5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.descriptime{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.descript-video{
    position: relative;
    display: flex;
    flex-direction: row;
}

.tree-mode-image{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-align: center;
}

.timeupdate{
    flex: 1 1;
    text-align: left;
    font-size: small;
}

.reading{
    flex: 1 1;
    text-align: right;
    font-size: small;
}

.image-big-mode{
    background-color: white;
    width:100%;
    height:200px;
  }
  .image-big-mode img{
    width:100%;
    height:100%;
    left: 50%;
    position: relative;
    background-color: #dbdbdb;
    transform: translateX(-50%);
  }



  .small-video{
    position: absolute;
    left: 0px;
    top: 5px;
    width: 100px;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .descript-container-border{
    border-bottom: 1px solid #d9d9d9;
  }

  .descript-container-small{
    margin-left: 100px;
    height: 100px;
    padding-left: 10px;
    position: relative;
  }

  .headline-text-name{
    color: #333;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    /* display: inline; */
  }

  .sortdesc-headline-text{
    color: #999999;
    font-size: 12px;
    height: 40px;
    text-overflow: inherit;
    overflow: hidden;
    white-space: normal;
  }

.tab-section-category-news{
    /* padding-left: 10px;
    padding-right: 10px; */
    border-bottom:1px solid #d9d9d9;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: inherit;
    overflow: auto;
}

.tab-section-all-news-cateogry{
  /* padding-left: 10px; */
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.tab-section-list-colom{
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: inherit;
  overflow: auto;
  z-index: 1;
  background-color: rgba(0,0,0,.2);
  

  /* position: 'absolute',
  top:0,bottom:0,left:0,right:0,flex:1,backgroundColor:'rgba(0,0,0,.2)',alignItems: 'center',alignSelf: 'center',justifyContent: 'center',padding:100,elevation: 6 */

}

.tab-section-list-news-category{
  padding: 6px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fafbfc;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;

}

.type-selection{
    margin-left: 6px;
    text-align: left;
    justify-content: center;
    font-size: 14px;
    border-left :3px solid #0066ff;
}

.listing-item-category-news{
    flex-direction: column;
}

.listing-item-down-category-news{
  flex-direction: column;
  border-right: 1px solid #d9d9d9;
}

.icon-filter-cateory-news{
    margin-left: 10px;
    margin-right: 5px;
    flex-wrap: nowrap;
    flex-direction: column;
}

.icon-undo-filter-cateory-news{
  padding-left: 10px;
  padding-right: 6px;
  flex-wrap: nowrap;
  flex-direction: column;
  border-left: 1px solid #d9d9d9;
  
}

.data-item-tab-category{
  padding: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}


.tab-section-all-list-cateogry{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.listing-item-category-news-down{
  flex-direction: column;
}

.news-category-tab-section{
  padding:4px;
  background-color:#fff;
  height: auto;
  overflow: auto;
  white-space: nowrap;
}


.news-category-tab-section .tab-item-news{
  text-align: center;
  font-size: 12px;
  padding-bottom:5px;
  padding-bottom: 5px;
  /* margin: 6px; */
  background-color: #fafbfc;
  color:#bfbfbf;
}
.news-category-tab-section .active-tab-news{
  color:#0066ff;
  border-bottom:2px solid #0066ff;
}


.news-list-section-data{
  position: absolute;
  top:75px;
  bottom:10px;
  left:0px;
  right:0px;
  background-color:#fff;
  padding-left:5px;
  padding-right:5px;
  overflow: auto;
}
.news-list-section-data .list{
  border-bottom:1px solid #d9d9d9;
  padding:5px;
}
.news-list-section-data .list .title{
  color:#0066ff;
  font-size: 16px;
  font-weight: bold;
}

.news-list-section-data .list .shortdesc{
  color:#404040;
  font-size: 14px;
  margin-top:4px;
}
.news-list-section-data .list .time{
  color:#a6a6a6;
  font-size: 12px;
  margin-top:3px;
}
.news-list-section-data .list .shortdesc{
  color:#333;
  font-size: 13px;
  margin-top:3px;
}
.news-list-section-data .no-data{
  color:#8c8c8c;
  font-size:18px;
  text-align: center;
  height:100px;
  line-height: 100px; 
  vertical-align: middle;
}

.news-list-section-data .loadmore-container{
  text-align: center;
  padding:10px;
  font-size: 16px;
  color:#0066ff;
  text-decoration: underline;
}

.news-list-section-data .loadmore-loading-container{
  text-align: center;
  padding:5px;

}
.news-list-section-data .loadmore-loading-container img{
  width:50px;
  height:50px;
}
.sub-header{
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    padding:10px 20px;
    position: relative;
    display: flex;
}

.back-container{
    display: inline-block;
    font-size: 16px;
    color:#0066ff;
}
.search-container{
    position: absolute;
    top:5px;
    right:60px;
    left:80px;
}
.search-container input{
    border:1px solid #d9d9d9;
    color:#333;
    border-radius:10px;
    width:100%;
    padding: 4px 15px;
    padding-left:35px;
}
.search-container input:focus{
    border:1px solid #0066ff;
}

.search-container .magnify{
    position: absolute;
    top:9px;
    left:10px;
    color:#808080;
}

.title-container{
    display: inline-block;
    margin:auto;
    height:24px;
    overflow: hidden;
    justify-items: center;
}
.right-container{
    display: inline-block;
    font-size: 16px;
    height:24px;
    color:#0066ff;
    float:right;
    text-align: right;
}
.home-header {
  width: 100%;
}

.home-banner-container {
  height: 280px;
  line-height: 100px;
  text-align: center;
  vertical-align: middle;
}

img.home-banner {
  max-width: 100%;
  max-height: 100%;
}

video.home-banner {
  max-width: 100%;
  max-height: 100%;
}
.adv-title {
  color: #333;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
}
.adv-content {
  padding: 0px;
}

/*example1: using background image
The best way to do this is to use position:absolute to child span of parent anchor.*/

a.notif {
  position: relative;
  display: block;
  height: 50px;
  width: 50px;
  background: url("http://i.imgur.com/evpC48G.png");
  background-size: contain;
  text-decoration: none;
}
.num {
  position: absolute;
  right: 11px;
  top: 6px;
  color: #fff;
}
.link-label .moment-icon {
  display: inline-block;
  position: relative;
}
.link-label .moment-icon .fa-moment {
  position: absolute;
  top: 0px;
  right: -2px;
  height: 15px;
  width: 15px;
  background-color: red;
}
span.num {
  font-size: 12px !important;
  top: 0px;
  right: 1px;
}

.home-icons-container {
  margin-top: 10px;
}
.home-icons-container::after {
  content: " ";
  display: table;
  clear: both;
}
.home-icons-container .icon-column {
  width: 25%;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.continer-icon-center {
  margin-bottom: 4px;
  margin-top: 4px;
}

.container-data-global {
  position: relative;
  display: flex;
  flex-direction: row;
}

.center-icon-row {
  align-content: center;
  text-align: center;
  margin-top: 10;
  padding: 6px;
}

.center-icon-row-bottom {
  align-content: center;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}

.content-icon-colom {
  background-color: white;
  /* padding-top: 4px;
  padding-bottom: 4px; */
  margin: 6px;
  border: 1px solid rgb(212, 212, 212);
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.content-corona-positif {
  /* background-color: red; */
  background-color: white;
  margin: 4px;
  border: 1px solid rgb(211, 137, 0);
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.content-corona-sembuh {
  background-color: white;
  margin: 4px;
  border: 1px solid rgb(0, 160, 0);
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.content-corona-meninggal {
  /* background-color: rgb(187, 0, 171); */
  background-color: white;
  margin: 4px;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 300ms ease;
  position: relative;
}

.corona-data-container-column {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.row-data-corona-container {
  /* margin-bottom: 10px; */
  position: relative;
  display: flex;
  flex-direction: row;
}

.column-data-corona-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.column-text-corona-data {
  position: relative;
  display: flex;
  flex-direction: column;
}

.content-img-text-icon {
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: center;
}

.content-text-data {
  padding-bottom: 8px;
  padding-top: 8px;
  justify-content: center;
}

.text-on-icon {
  text-align: center;
  margin-top: 2;
  font-size: 10;
}

.text-title-size {
  font-size: 10;
}

.filter-data-item-category {
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.click-tracing-container {
  text-align: center;
  margin-bottom: 10px;
  /* margin-top: 8px; */
  position: relative;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-style: oblique;
  font-family: none;
  font-size: small;
  justify-content: center;
  color: rgb(2, 81, 207);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}


.whitebg{
    background-color:#fff;
}
.top-navigation{
    padding:5px 10px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
}
.top-navigation .back-button{
    color:#0066ff;
    display: inline;
}

.news-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.news-content .news-title{
    color:#000;
    font-size: 16px;
    font-weight: bold;
    margin-top:5px;
}
.news-content .news-description{
    font-size: 14px;
    text-align: justify;
    margin-top:5px;
}
.news-content .news-datetime{
    font-size: 12px;
    color:#bfbfbf;
    text-align: left;
    margin-top:5px;
}

.descriptime-detail{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.park-introduction{
    background-color:#fff;
}

.park-introduction .tab-section{
    padding-top:0px !important;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:40px;
}
.park-introduction .tab-section .tab-item{
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    color:#bfbfbf;
    height:39px;
    line-height: 39px;
    padding-bottom:0px !important;
}
.park-introduction .tab-section .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}

.park-introduction .body-section{
    padding:10px;
}

.park-introduction .img-container{
    width: 100%;
    margin-bottom:10px;
}
.park-introduction .img-container img{
    width: 100%;
}
.park-introduction .title{
    padding:2px;
    color:#000;
    font-weight: bold;
    margin-bottom:10px;
}
.park-introduction .description{
    padding:2px;
    color:#000;
    font-size:14px;
}
.park-enterprise{
    background-color:#fff;
}

.park-enterprise .tab-section{
    padding-top:0px !important;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:40px;
}
.park-enterprise .tab-section .tab-item{
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    color:#bfbfbf;
    height:39px;
    line-height: 39px;
    padding-bottom:0px !important;
}
.park-enterprise .tab-section .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}

.park-enterprise .body-section{
    padding:10px;
}

.park-enterprise .img-container{
    width: 100%;
    margin-bottom:10px;
}
.park-enterprise .img-container img{
    width: 100%;
}
.park-enterprise .list .title{
    padding:2px;
    color:#000;
    font-weight: bold;
    font-size: 15px;
    margin-bottom:5px;
}
.park-enterprise .list {
    border-bottom:1px solid #d9d9d9;
    padding-top:5px;
    padding-bottom:5px;
}
.park-enterprise .list .time{
    color:#b3b3b3;
    font-size: 12px;
    margin-top:3px;
    
}
.park-enterprise .title{
    padding:2px;
    color:#000;
    font-weight: bold;
    margin-bottom:10px;
}
.park-enterprise .description{
    padding:2px;
    color:#000;
    font-size: 14px;
}
.park-enterprise .table-contact{
    width:100%;
}
.park-enterprise .table-contact td{
    padding:10px 3px;
    border-bottom:1px solid #d9d9d9;
}
.park-enterprise img.contact-pic{
    width:35px;
    height:35px;
    border-radius:50%;
}
.park-enterprise td.column-pic{
    width:60px;
}
.park-enterprise td.column-icon{
    width:40px;
}
.park-enterprise td.column-icon img{
    width:35px;
}

.park-enterprise .contact-name{
    font-size: 14px;
}
.park-enterprise .contact-position{
    font-size: 13px;
    color:#808080;
}

.list-item{
    width:100%;
    padding-top:10px;
    padding-bottom:0px;
    padding-left:5px;
    padding-right:5px;
    /*border-bottom:1px solid #d9d9d9;*/
}

.list-item .relative-container{
    position: relative;
    
}

.list-item .imgitem-container{
    position: absolute;
    left:0px;
    top:0px;
    width:90px;
    height:100px;
    line-height: 100px;
    /*border:1px solid #d9d9d9;*/
    vertical-align: middle;
    text-align: center;

}

.list-item .imgitem-container img{
    max-height:80px;
    max-width:80px;
    margin:auto;
}
.list-item .data-container{
    margin-left:90px;
    height:110px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
    /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
}

.list-item .full-left{
    margin-left:0px;
}

.list-item .data-container .name-container{
    color:#333;
    font-size: 14px;
    /*text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;*/
    vertical-align: top;
    display: inline;
}
.list-item .data-container .name-container .category{
    color:#fff;
    background-color:#00cc99;
    display: inline-block;
    padding:0px 1px;
    margin-right:5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list-item .data-container .shortdesc-container{
    color:#999999;
    font-size: 12px;
    height:55px;
    text-overflow: inherit;
    overflow: hidden;
    white-space: normal;
}
.list-item .data-container .tags-container{
    position: absolute;
    bottom:8px;
    left:0px;
    right:0px;
    padding-left:5px;
}
.list-item .data-container .tags-container .tag{
    font-size: 12px;
    background-color:#e6e6e6;
    color:#808080;
    display: inline;
    padding:3px 10px;
    border-radius: 10px;
    margin-right:3px;

}

.no-data-available{
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color:#d9d9d9;
}

.no-data-available{
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color:#d9d9d9;
}



.company-profile{
    background-color:#f2f2f2;
}

.company-profile-header{
    background-color:#fff;
}

.company-profile-img-container{
    text-align: center;
    padding:10px;
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
}
.company-profile-img-container img{
    max-height: 150px;
    max-width: 150px;
}
.company-profile-name-container{
    text-align: center;
    font-size: 16px;
}
.company-profile-name-container span.aboutus-link{
    font-size: 12px; padding:2px; 
    border:1px solid #00cc99;
    color:#00cc99;
    border-radius: 7px;
}
.company-profile-like-container{
    text-align: center;
    padding:4px;
}
.company-profile-like-container table{
    margin:auto;
}

.company-profile-like-container td{
    padding:2px 7px;
    color:#8c8c8c;
    font-size: 13px;
    
}
.company-profile-tab-container{

}
.company-profile-tab-container table{
    width:100%;
}
.company-profile-tab-container td{
    padding-top:10px;
    padding-bottom:10px;
}
.company-profile-tab-container .company-profile-tab-item{
    
    text-align: center;
    border-right: 1px solid #d9d9d9;
    height: 60px;
}
.company-profile-tab-container .tab-active{
    color:#00cc99;
    border-bottom:2px solid #00cc99;
}
.company-profile-tab-container .item-last{
    border-right:0px;
}
.company-profile-tab-container .company-profile-tab-icon{
    text-align: center;
    font-size: 25px;
}
.company-profile-tab-container .company-profile-tab-name{
    text-align: center;
    font-size: 13px;
}

.company-profile-info{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.company-profile-info table{
    width:100%;
}

.company-profile-info .column-field{
    color:#8c8c8c;
    padding:2px 4px;
    padding-bottom:6px;
    width:100px;
    font-size:14px;
}
.company-profile-info .column-value{
    color:#000;
    padding:2px 4px;
    padding-bottom:6px;
    font-size:14px;
}

.company-profile-description{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
    font-size:13px;
}
.company-profile-description .description-label{
    padding-bottom:15px;
    font-size: 15px;
    font-size: bold;
} 

.company-profile-list-container{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.product-detail{
    background-color:#f2f2f2;
}

.product-detail-header{
    background-color:#fff;
}

.product-detail-img-container{
    text-align: center;
    padding:10px;
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
}
.product-detail-img-container img{
    max-height: 150px;
    max-width: 150px;
}
.product-detail-name-container{
    text-align: center;
    font-size: 16px;
    padding:20px;
    padding-bottom:10px;
}
.product-detail-name-container span.aboutus-link{
    font-size: 12px; padding:2px; 
    border:1px solid #00cc99;
    color:#00cc99;
    border-radius: 7px;
}
.product-detail-company{
    text-align: center;
    font-size: 15px;
    color:#0066ff;
    padding-bottom:20px;
}
.product-detail-like-container{
    text-align: center;
    padding:4px;
}
.product-detail-like-container table{
    margin:auto;
}

.product-detail-like-container td{
    padding:2px 7px;
    color:#8c8c8c;
    font-size: 13px;
    
}
.product-detail-tab-container{

}
.product-detail-tab-container table{
    width:100%;
}
.product-detail-tab-container td{
    padding-top:10px;
    padding-bottom:10px;
}
.product-detail-tab-container .product-detail-tab-item{
    
    text-align: center;
    border-right: 1px solid #d9d9d9;
    height: 60px;
}
.product-detail-tab-container .tab-active{
    color:#00cc99;
    border-bottom:2px solid #00cc99;
}
.product-detail-tab-container .item-last{
    border-right:0px;
}
.product-detail-tab-container .product-detail-tab-icon{
    text-align: center;
    font-size: 25px;
}
.product-detail-tab-container .product-detail-tab-name{
    text-align: center;
    font-size: 13px;
}

.product-detail-info{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.product-detail-info table{
    width:100%;
}

.product-detail-info .column-field{
    color:#8c8c8c;
    padding:2px 4px;
    padding-bottom:6px;
    width:100px;
}
.product-detail-info .column-value{
    color:#000;
    padding:2px 4px;
    padding-bottom:6px;
}

.product-detail-description{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
    font-size:13px;
}
.product-detail-description .description-label{
    padding-bottom:15px;
    font-size: 15px;
    font-size: bold;
} 

.product-detail-list-container{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.service-detail{
    background-color:#f2f2f2;
}

.service-detail-header{
    background-color:#fff;
}

.service-detail-img-container{
    text-align: center;
    padding:10px;
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
}
.service-detail-img-container img{
    max-height: 150px;
    max-width: 150px;
}
.service-detail-name-container{
    text-align: center;
    font-size: 16px;
    padding:20px;
}
.service-detail-name-container span.aboutus-link{
    font-size: 12px; padding:2px; 
    border:1px solid #00cc99;
    color:#00cc99;
    border-radius: 7px;
}
.service-detail-like-container{
    text-align: center;
    padding:4px;
}
.service-detail-like-container table{
    margin:auto;
}

.service-detail-like-container td{
    padding:2px 7px;
    color:#8c8c8c;
    font-size: 13px;
    
}
.service-detail-tab-container{

}
.service-detail-tab-container table{
    width:100%;
}
.service-detail-tab-container td{
    padding-top:10px;
    padding-bottom:10px;
}
.service-detail-tab-container .service-detail-tab-item{
    
    text-align: center;
    border-right: 1px solid #d9d9d9;
    height: 60px;
}
.service-detail-tab-container .tab-active{
    color:#00cc99;
    border-bottom:2px solid #00cc99;
}
.service-detail-tab-container .item-last{
    border-right:0px;
}
.service-detail-tab-container .service-detail-tab-icon{
    text-align: center;
    font-size: 25px;
}
.service-detail-tab-container .service-detail-tab-name{
    text-align: center;
    font-size: 13px;
}

.service-detail-info{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.service-detail-info table{
    width:100%;
}

.service-detail-info .column-field{
    color:#8c8c8c;
    padding:2px 4px;
    padding-bottom:6px;
    width:100px;
}
.service-detail-info .column-value{
    color:#000;
    padding:2px 4px;
    padding-bottom:6px;
}

.service-detail-description{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
    font-size:13px;
}
.service-detail-description .description-label{
    padding-bottom:15px;
    font-size: 15px;
    font-size: bold;
} 

.service-detail-list-container{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.coming-soon{}

.coming-soon .coming-soon-content{
    text-align: center;
    height: 200px;
    line-height: 200px;
    vertical-align: middle;
    color: #808080;
    font-size:22px; 
}
.company-profile{
    background-color:#f2f2f2;
}

.company-profile-header{
    background-color:#fff;
}

.company-profile-img-container{
    text-align: center;
    padding:10px;
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
}
.company-profile-img-container img{
    max-height: 150px;
    max-width: 150px;
}
.company-profile-name-container{
    text-align: center;
    font-size: 16px;
}
.company-profile-name-container span.aboutus-link{
    font-size: 12px; padding:2px; 
    border:1px solid #00cc99;
    color:#00cc99;
    border-radius: 7px;
}
.company-profile-like-container{
    text-align: center;
    padding:4px;
}
.company-profile-like-container table{
    margin:auto;
}

.company-profile-like-container td{
    padding:2px 7px;
    color:#8c8c8c;
    font-size: 13px;
    
}
.company-profile-tab-container{

}
.company-profile-tab-container table{
    width:100%;
}
.company-profile-tab-container td{
    padding-top:10px;
    padding-bottom:10px;
}
.company-profile-tab-container .company-profile-tab-item{
    
    text-align: center;
    border-right: 1px solid #d9d9d9;
    height: 60px;
}
.company-profile-tab-container .tab-active{
    color:#00cc99;
    border-bottom:2px solid #00cc99;
}
.company-profile-tab-container .item-last{
    border-right:0px;
}
.company-profile-tab-container .company-profile-tab-icon{
    text-align: center;
    font-size: 25px;
}
.company-profile-tab-container .company-profile-tab-name{
    text-align: center;
    font-size: 13px;
}

.company-profile-info{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.company-profile-info table{
    width:100%;
}

.company-profile-info .column-field{
    color:#8c8c8c;
    padding:2px 4px;
    padding-bottom:6px;
    width:100px;
    font-size:14px;
}
.company-profile-info .column-value{
    color:#000;
    padding:2px 4px;
    padding-bottom:6px;
    font-size:14px;
}

.company-profile-description{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
    font-size:13px;
}
.company-profile-description .description-label{
    padding-bottom:15px;
    font-size: 15px;
    font-size: bold;
} 

.company-profile-list-container{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}


.project-detail{
    background-color:#f2f2f2;
}

.project-detail-header{
    background-color:#fff;
}

.project-detail-img-container{
    text-align: center;
    padding:10px;
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
}
.project-detail-img-container img{
    max-height: 150px;
    max-width: 150px;
}
.project-detail-name-container{
    text-align: center;
    font-size: 16px;
    padding:20px;
    padding-bottom:10px;
}
.project-detail-name-container span.aboutus-link{
    font-size: 12px; padding:2px; 
    border:1px solid #00cc99;
    color:#00cc99;
    border-radius: 7px;
}
.project-detail-company{
    text-align: center;
    font-size: 15px;
    color:#0066ff;
    padding-bottom:20px;
}
.project-detail-like-container{
    text-align: center;
    padding:4px;
}
.project-detail-like-container table{
    margin:auto;
}

.project-detail-like-container td{
    padding:2px 7px;
    color:#8c8c8c;
    font-size: 13px;
    
}
.project-detail-tab-container{

}
.project-detail-tab-container table{
    width:100%;
}
.project-detail-tab-container td{
    padding-top:10px;
    padding-bottom:10px;
}
.project-detail-tab-container .project-detail-tab-item{
    
    text-align: center;
    border-right: 1px solid #d9d9d9;
    height: 60px;
}
.project-detail-tab-container .tab-active{
    color:#00cc99;
    border-bottom:2px solid #00cc99;
}
.project-detail-tab-container .item-last{
    border-right:0px;
}
.project-detail-tab-container .project-detail-tab-icon{
    text-align: center;
    font-size: 25px;
}
.project-detail-tab-container .project-detail-tab-name{
    text-align: center;
    font-size: 13px;
}

.project-detail-info{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.project-detail-info table{
    width:100%;
}

.project-detail-info .column-field{
    color:#8c8c8c;
    padding:2px 4px;
    padding-bottom:6px;
    width:100px;
}
.project-detail-info .column-value{
    color:#000;
    padding:2px 4px;
    padding-bottom:6px;
}

.project-detail-description{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
    font-size:13px;
}
.project-detail-description .description-label{
    padding-bottom:15px;
    font-size: 15px;
    font-size: bold;
} 

.project-detail-list-container{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}


.talent-detail{
    background-color:#f2f2f2;
}

.talent-detail-header{
    background-color:#fff;
}

.talent-detail-img-container{
    text-align: center;
    padding:10px;
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
}
.talent-detail-img-container img{
    max-height: 150px;
    max-width: 150px;
}
.talent-detail-name-container{
    text-align: center;
    font-size: 16px;
    padding:20px;
    padding-bottom:10px;
}
.talent-detail-name-container span.aboutus-link{
    font-size: 12px; padding:2px; 
    border:1px solid #00cc99;
    color:#00cc99;
    border-radius: 7px;
}
.talent-detail-company{
    text-align: center;
    font-size: 15px;
    color:#0066ff;
    padding-bottom:20px;
}
.talent-detail-like-container{
    text-align: center;
    padding:4px;
}
.talent-detail-like-container table{
    margin:auto;
}

.talent-detail-like-container td{
    padding:2px 7px;
    color:#8c8c8c;
    font-size: 13px;
    
}
.talent-detail-tab-container{

}
.talent-detail-tab-container table{
    width:100%;
}
.talent-detail-tab-container td{
    padding-top:10px;
    padding-bottom:10px;
}
.talent-detail-tab-container .talent-detail-tab-item{
    
    text-align: center;
    border-right: 1px solid #d9d9d9;
    height: 60px;
}
.talent-detail-tab-container .tab-active{
    color:#00cc99;
    border-bottom:2px solid #00cc99;
}
.talent-detail-tab-container .item-last{
    border-right:0px;
}
.talent-detail-tab-container .talent-detail-tab-icon{
    text-align: center;
    font-size: 25px;
}
.talent-detail-tab-container .talent-detail-tab-name{
    text-align: center;
    font-size: 13px;
}

.talent-detail-info{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}
.talent-detail-info table{
    width:100%;
}

.talent-detail-info .column-field{
    color:#8c8c8c;
    padding:2px 4px;
    padding-bottom:6px;
    width:100px;
}
.talent-detail-info .column-value{
    color:#000;
    padding:2px 4px;
    padding-bottom:6px;
}

.talent-detail-description{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
    font-size:13px;
}
.talent-detail-description .description-label{
    padding-bottom:15px;
    font-size: 15px;
    font-size: bold;
} 

.talent-detail-list-container{
    background-color:#fff;
    padding:10px 20px;
    margin-top:10px;
}



.marketplace-header{
    width:100%;
}

.marketplace-footer{
    width:100%;
}

.marketplace-banner-container{
    height: 200px;
    line-height: 200px;
    text-align: center;
    vertical-align: middle;
  }

img.marketplace-banner{
    max-width:100%;
    max-height: 100%;
}

.map-container{
    width:100%;
    padding-top:5px;
    padding-bottom:5px;
    border-top:1px solid #d9d9d9;
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
    margin-bottom:10px;
    height: 240px;
}


.adv-container{
    /* padding-top:3px;
    padding-bottom:3px; */
    padding: 10px;
    border-top:1px solid #d9d9d9;
    border-bottom:1px solid #d9d9d9;
    background-color: #fff;
}

.adv-container .adv{
    margin-top: 3px;
    margin-bottom:6px;
    padding: 5px;
}
.adv-container .adv img{
    width:100%;
}

.marketplace-icons-container{
}
.marketplace-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.marketplace-icons-container .icon-column{
    width:25%;
    display: inline-block;
    text-align: center;
    padding-top:5px;
    padding-bottom:5px;
}
.hidden{
    display: none;
}

.sub-header-food{
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    padding:10px 20px;
    position: relative;
    display: flex;
}

.back-container{
    display: inline-block;
    font-size: 16px;
    color:#0066ff;
}
.search-container{
    position: absolute;
    top:5px;
    right:50px;
    left:80px;
}
.search-container input{
    border:1px solid #d9d9d9;
    color:#333;
    border-radius:10px;
    width:100%;
    padding: 4px 15px;
    padding-left:35px;
}
.search-container input:focus{
    border:1px solid #0066ff;
}

.search-container .magnify{
    position: absolute;
    top:9px;
    left:10px;
    color:#808080;
}

.title-container{
    display: inline-block;
    margin:auto;
    height:24px;
}
.right-container{
    display: inline-block;
    font-size: 16px;
    height:24px;
    color:#0066ff;
    float:right;
    text-align: right;
}
.list-item-food {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.list-item-food .relative-container {
  position: relative;
}

.list-item-food .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  line-height: 95px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  justify-items: center;
  background-color: white;
}

.list-item-food .imgitem-container img {
  width: 90%;
  height: 80%;
  max-height: 200px;
  max-width: 200px;
  margin: auto;
}
.list-item-food .data-container {
  margin-left: 100px;
  height: 100px;
  padding-left: 10px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
}

.list-item-food .full-left {
  margin-left: 0px;
}

.list-item-food .data-container .name-container {
  color: #333;
  font-size: 14px;
  /*text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;*/
  vertical-align: top;
  display: inline;
  /* font-family: cursive; */
}
.list-item-food .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline-block;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-item-food .data-container .shortdesc-container {
  color: #030303;
  font-size: 12px;
  height: 40px;
  text-overflow: inherit;
  overflow: hidden;
  white-space: normal;
}
.list-item-food .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.list-item-food .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile {
  background-color: #fff;
}

.merchant-profile .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile .menu-section-container {
}
.merchant-profile .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile .menu-section-container td.category-column .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:50px; */
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile .menu-banner-container {
  padding: 10px;
}
.merchant-profile .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background: url(/smart/static/media/merchantbg.ed89df57.jpg) no-repeat top;
  background-size: 100% 110px;
}
.merchant-profile .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(242, 242, 242, 0.8);
  position: relative;
  background-color: #fff;
}
.merchant-profile .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}
.merchant-profile .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #333333;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
}
.merchant-profile .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
}
.merchant-profile .profile-inner-container .profile-img img {
  width: 100%;
  height: 65px;
  border-radius: 6px;
}

.merchant-profile .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile .merchant-info-container table {
  width: 100%;
}
.merchant-profile .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile .cart-icon {
  font-size: 17px;
}

.merchant-payment .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background: url(/smart/static/media/merchantbg.ed89df57.jpg) no-repeat top;
  background-size: 100% 110px;
}

.merchant-payment .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(242, 242, 242, 0.8);
  position: relative;
  background-color: #fff;
}
.merchant-payment .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #3399ff;
}
.merchant-payment .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.list-item .commodity-name {
  /*font-size: 18px !important;*/
  font-weight: bold;
}

.list-item .commodity-imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.list-item .commodity-imgitem-container img {
  max-height: 80px;
  max-width: 80px;
  margin: auto;
}

.list-item .data-container .commodity-tags-container {
  padding-left: 0px;
}
.list-item .data-container .commodity-tags-container .tag {
  font-size: 12px;
  background-color: #f2f2f2;
  color: #808080;
  display: inline;
  padding: 1px 5px;
  border-radius: 0px;
  margin-right: 3px;
}

.list-item .commodity-price-container {
  color: #ff9933;
  font-size: 14px;
  max-height: 38px;
  overflow: auto;
}

.list-item .commodity-price-container span {
  font-size: 16px;
}
.list-item .commodity-price-container span.stroke-price {
  font-size: 12px;
  color: #cccccc;
  text-decoration: line-through;
}

.list-item .commodity-sold-container {
  font-size: 12px;
  color: #a6a6a6;
  margin-top: 5px;
}

.list-item .relative-container .qty-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-align: center;
  display: inline-block;
}
.list-item .relative-container .qty-container .qty-value {
  text-align: center;
  display: inline-block;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  vertical-align: middle;
  float: right;
}
.list-item .relative-container .qty-container .plus-minus-button {
  width: 100px;
  display: inline-block;
  text-align: center;
}
/* .list-item .relative-container .qty-container .plus-minus-button .plus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-left: 10px;
  font-weight: bold;
}
.list-item .relative-container .qty-container .plus-minus-button .minus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-right: 10px;
  font-weight: bold;
} */

.list-item .commodity-data-container {
  border-bottom: 0px;
  margin-bottom: 10px;
}

.product-content {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.product-content .product {
  color: #000;
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.product-content .product-title {
  color: #000;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}

.product-content .product-image {
  text-align: center;
}

.product-content .product-image img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  margin: auto;
}

.product-content .product-description {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.product-content .product-price {
  font-size: 14px;
  color: #ff6666;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}

.product-content .product-tags-container {
  padding-left: 0px;
  font-size: 12px;
  text-align: center;
}
.product-content .product-tags-container .tag {
  font-size: 12px;
  background-color: #f2f2f2;
  color: #808080;
  display: inline;
  padding: 1px 5px;
  border-radius: 0px;
  margin-right: 3px;
}

.list-item .commodity-name {
  /*font-size: 18px !important;*/
  font-weight: bold;
}

.list-item .commodity-imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  line-height: 95px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  justify-items: center;
  background-color: white;
}

.list-item .commodity-imgitem-container img {
  width: 90%;
  height: 80%;
  max-height: 200px;
  max-width: 200px;
  margin: auto;
}

.list-item .data-container .commodity-tags-container {
  padding-left: 0px;
}
.list-item .data-container .commodity-tags-container .tag {
  font-size: 12px;
  background-color: #f2f2f2;
  color: #808080;
  display: inline;
  padding: 1px 5px;
  border-radius: 0px;
  margin-right: 3px;
}

.list-item .commodity-price-container {
  color: #ff9933;
  font-size: 14px;
  max-height: 38px;
  overflow: auto;
}

.list-item .commodity-price-container span {
  font-size: 16px;
}
.list-item .commodity-price-container span.stroke-price {
  font-size: 12px;
  color: #cccccc;
  text-decoration: line-through;
}

.list-item .commodity-sold-container {
  font-size: 12px;
  color: #a6a6a6;
  margin-top: 5px;
}

.list-item .qty-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-align: center;
}
.list-item .qty-container .qty-value {
  text-align: center;

  display: inline-block;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #e6e6e6;
}
.list-item .qty-container .plus-minus-button {
  width: 60px;
}
.list-item .qty-container .plus-minus-button .plus-button {
  border-radius: 25%;
  height: 25px;
  width: 25px;
  line-height: 24px;
  /* font-family: fantasy; */
  font-weight: 800;
  vertical-align: middle;
  background-color: #007bff;
  /* background-color: #ff9933; */
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-left: 10px;
}
.list-item .qty-container .plus-minus-button .minus-button {
  border-radius: 25%;
  height: 25px;
  width: 25px;
  line-height: 24px;
  /* font-family: fantasy; */
  font-weight: 800;
  vertical-align: middle;
  background-color: #007bff;
  /* background-color: #ff9933; */
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.list-item .commodity-data-container {
  border-bottom: 0px;
  margin-bottom: 10px;
}
.list-item .commodity-data-container td.qty-col {
  text-align: center;
  width: 40px;
}
.list-item .commodity-data-container .qty-label {
  font-size: 14px;
  color: #808080;
}
.list-item .commodity-data-container td.subtotal-col {
  text-align: right;
  width: 100px;
  padding-right: 10px;
}
.list-item .commodity-data-container .price-amount {
  font-size: 14px;
  color: #ff9933;
}
.list-item .commodity-data-container .qty-amount {
  font-size: 15px;
  color: #808080;
}
.list-item .commodity-data-container .subtotal-amount {
  font-size: 14px;
  color: #808080;
}

.list-item .commodity-cart-container {
  margin-left: 85px;
  height: 84px !important;
}

.list-item .relative-container .qty-col .plus-minus-button {
  width: 100px;
  margin-top: 10px;
  display: inline-block;
  text-align: center;
}

.list-item .relative-container .qty-col .plus-minus-button .qty-value {
  text-align: center;
  display: inline-block;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  vertical-align: middle;
  float: right;
}

.list-item .relative-container .qty-col .plus-minus-button .plus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff9933;
  color: #000;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-left: 10px;
}
.list-item .relative-container .qty-col .plus-minus-button .minus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff9933;
  color: #000;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.merchant-payment{
    background-color: #fff;
}

.merchant-payment .picture-container{
    background-color:#fff;
    text-align: center;
    padding:0px !important;
    height: 151px;
    line-height: 151px;
    vertical-align: middle;
    border-bottom:1px solid #d9d9d9;
    margin-top:0px;
}
.merchant-payment .picture-container img{
    max-height: 150px;
    max-width: 150px;
    vertical-align: middle;
    margin-top:-4px;
}


.merchant-info{
    width:100%;
    padding-top:10px;
    padding-bottom:0px;
    padding-left:5px;
    padding-right:5px;
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}

.merchant-info .relative-container{
    position: relative;
    
}

.merchant-info .imgitem-container{
    position: absolute;
    left:0px;
    top:0px;
    width:80px;
    height:80px;
    line-height: 80px;
    /*border:1px solid #d9d9d9;*/
    vertical-align: middle;
    text-align: center;

}

.merchant-info .imgitem-container img{
    max-height:60px;
    max-width:60px;
    margin:auto;
}
.merchant-info .data-container{
    margin-left:90px;
    height:110px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left{
    margin-left:0px;
}

.merchant-info .data-container .name-container{
    color:#333;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.merchant-info .data-container .name-container .category{
    color:#fff;
    background-color:#00cc99;
    display: inline;
    padding:0px 1px;
    margin-right:5px;
    font-size: 12px;
}
.merchant-info .data-container .shortdesc-container{
    color:#999999;
    font-size: 12px;
    height:53px;
    overflow: hidden;
}
.merchant-info .data-container .tags-container{
    position: absolute;
    bottom:8px;
    left:0px;
    right:0px;
    padding-left:5px;
}
.merchant-info .data-container .tags-container .tag{
    font-size: 12px;
    background-color:#e6e6e6;
    color:#808080;
    display: inline;
    padding:3px 10px;
    border-radius: 10px;
    margin-right:3px;

}

.merchant-payment .menu-section-container{

}
.merchant-payment .menu-section-container table{
    width: 100%;
    border-collapse: collapse;
}
.merchant-payment .menu-section-container td.category-column{
    width:80px;
    background-color:#fdfdfd;
    color:#8c8c8c;
    vertical-align: top;
    padding-right:0px;
}
.merchant-payment .menu-section-container td.category-column .category-item{
    padding-top:5px; 
    padding-bottom:5px;
    padding-left:10px;
    font-size: 12px;
}
.merchant-payment .menu-section-container td.category-column .active{
    background-color:#fff;
    color:#333333;
    font-weight: bold;
}

.merchant-payment .menu-section-container td.menu-column{
    vertical-align: top;
}
.merchant-payment .menu-section-container td.menu-column .commodity-list-container{
    /* padding-bottom:50px; */
}

.shopping-cart-button-container{
    position: absolute;
    bottom:15px;
    right:15px;
    background-color: #ff9933;
    color:#000;
    padding:3px 10px;
    z-index: 100;
    border-radius:10px;
    box-shadow: 1px 0px 4px 1px rgba(0,0,0,.3); 

}

.merchant-payment .menu-banner-container{
    padding:10px;
}
.merchant-payment .menu-banner-container img{
    width:100%;
    max-height: 100px;
    border-radius: 8px;
}
.merchant-payment .tabs-container{
    
    border-bottom:1px solid #f2f2f2;
    padding-left:10px;
    padding-right:10px;
}
.merchant-payment .tabs-container .tab{
    display: inline-block;
    padding:0px 15px;
    color:#808080;
}
.merchant-payment .tabs-container .tab-active{
    display: inline-block;
    border-bottom:1px solid #ff9933;
    color:#ff9933;
}

.merchant-payment .profile-outer-container{
    padding:10px;
    padding-top:40px;
    background:url(/smart/static/media/merchantbg.ed89df57.jpg) no-repeat top;
    background-size: 100% 110px;

}
.merchant-payment .profile-inner-container{
    border-radius: 6px;
    box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, .5);
    padding:6px 10px;
    border:1px solid rgba(242, 242, 242, .8);
    position: relative;
    background-color:#fff;
}
.merchant-payment .profile-inner-container .profile-name{
    font-size: 18px;
	text-align: center;
}
.merchant-payment .profile-inner-container .profile-desc{
    font-size: 22px;
    text-align: center;
    color:#3399ff;
}
.merchant-payment .profile-inner-container .profile-items{
    margin-top:5px;
    text-align: center;
    color:#a6a6a6;
}
.merchant-payment .profile-inner-container .profile-tags .tag{
    display: inline-block;
    background-color:#f2f2f2;
    color:#333333;
    padding:1px 4px;
    font-size: 14px;
    margin-right:3px;
}
.merchant-payment .profile-inner-container .profile-img{
    position: absolute;
    width:65px;
    height:65px;
    top:0px;
    right:10px;
    transform: translate(0, -50%);
    border-radius:6px;
}
.merchant-payment .profile-inner-container .profile-img img{
    width:100%;
    height:65px;
    border-radius:6px;
}

.merchant-payment .no-info{
    height: 140px;
    text-align: center;
    background-color:#fff;
    color:#808080;
    line-height: 140px;
    vertical-align: middle;
}

.merchant-payment .merchant-info-container{
    padding:20px 10px;
}
.merchant-payment .merchant-info-container table{
    width:100%;
}
.merchant-payment .merchant-info-container td{
    padding:8px 5px;
    font-size: 14px;
}
.merchant-payment .merchant-info-container tr{
    border-bottom:1px solid #f2f2f2;
}

.merchant-payment .floating-chart {
	position: fixed;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 50px;
	z-index: 2500;
}

.no-data-available{
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color:#d9d9d9;
}



.list-item .data-container7{
    margin:10px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
}

.list-item .relative-container .imgitem-container4 img{
    height:50px;
    width:50px;
    border-radius: 50px;
    margin:auto;
}

.list-item .relative-container .imgitem-container4{
    position: absolute;
    left:-10px;
    top:-2px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .moments-name{
    font-size: 15px !important;
    color:#005580;
    margin-left: 10px;
}
.pictureuploader-container{
    padding:5px;
}

.pictureuploader-info-container{
    margin-top:5px;
}
.warning-info{
    float:left;
    color:#cc0000;
    font-size: 12px;
}
.max-file-info{
    float:right;
    color:#999999;
    font-size: 12px;
}

.pictureuploader-droparea{
    border-radius:5px;
    border:3px dashed #d9d9d9;
    background-color:#f2f2f2;
    min-height:200px;
    position: relative;
    overflow: hidden;
}

.pictureuploader-button-container{
    padding:5px 15px 0px 15px;
}

.drag-caption{
    position: absolute;
    text-align: center;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    color:#b3b3b3;
}

.pic-col{
    padding:10px;
    position: relative;
}
.pic-col img{
    width:100%;
}

.pic-col .delete{
    color:#f2f2f2;
    position: absolute;
    top:10px;
    right:20px;
}
.pic-col .delete:hover{
    color:#cc0000;
    cursor: pointer;
}

.pictureuploader-onupload{
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    z-index: 100;
    /*background: rgba(0,0,0,.4);*/
}
.pictureuploader-onupload::after{
    content: "";
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    z-index: 90;
    opacity: 0.5;
}

.pictureuploader-onupload .loading-container{
    width:180px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 110;
    
}
.pictureuploader-onupload .loading-container .bar-container{
    width:100%;
    height:20px;
    background-color:#f2f2f2;
    border-radius:3px;
    border:1px solid #b3b3b3;
    overflow: hidden;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.3) inset;
    
}
.pictureuploader-onupload .loading-container .bar-container .bar{
    height:19px;
    background-color:#0080ff;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.3) inset;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.pictureuploader-onupload .loading-container .upload-text{
    text-align: center;
    color:#fff;
    font-size: 12px;
}

.list-item .data-container3{
    margin:10px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
}

.list-item .relative-container .imgitem-container3 img{
    height:50px;
    width:50px;
    border-radius: 50px;
    object-fit: cover;
    margin:auto;
}

.list-item .relative-container .imgitem-container3{
    position: absolute;
    left:-10px;
    top:-2px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .imgitem-container2{
    position: absolute;
    left:0px;
    top:0px;
    width:90px;
    text-align: center;
    padding-top:5px;

}

.list-item .imgitem-container2 img{
    max-height:70px;
    max-width:70px;
    margin:auto;
}

.list-item .moments-name{
    font-size: 15px !important;
    color:#005580;
    margin-left: 60px;
}
.list-item .moments-desc{
    margin-top: 15px;
    font-size: 14px !important;
    color:#666666;
}

.list-item .moments-date{
    font-size: 12px !important;
    color:#666666;
    margin-left: 60px;
}

.comment-container{
    margin-bottom:5px;
    
    background-color:#f2f2f2;
    font-size: 13px;
    padding:5px;
}

.commenter-label{
    font-size: 13px;
    color:#808080;
    margin-bottom:2px;
}
.commenter-value{
    font-size: 14px !important;
    color:#404040;
}

.showmore{
    font-size: 13px;
    color:#005580;
    margin-top:10px;
}

.addcomment-container{
    margin-top:10px;
    font-size: 13px;
}
.addcomment-container button{
    font-size: 13px;
}

.commenter{
    color:#005580;
}
.sub-header-moment{
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    padding:10px 20px;
    position: relative;
    display: flex;
}

.back-container{
    display: inline-block;
    font-size: 16px;
    color:#0066ff;
}
.search-container{
    position: absolute;
    top:5px;
    right:50px;
    left:80px;
}
.search-container input{
    border:1px solid #d9d9d9;
    color:#333;
    border-radius:10px;
    width:100%;
    padding: 4px 15px;
    padding-left:35px;
}
.search-container input:focus{
    border:1px solid #0066ff;
}

.search-container .magnify{
    position: absolute;
    top:9px;
    left:10px;
    color:#808080;
}

.title-container{
    display: inline-block;
    margin:auto;
    height:24px;
}
.right-container{
    display: inline-block;
    font-size: 16px;
    height:24px;
    color:#0066ff;
    float:right;
    text-align: right;
}

.no-data-available{
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color:#d9d9d9;
}

.list-item .data-container5{
    margin:10px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
}

.list-item .relative-container .imgitem-container5 img{
    height:50px;
    width:50px;
    border-radius: 50px;
    margin:auto;
}

.list-item .relative-container .imgitem-container5 {
    position: absolute;
    left:-10px;
    top:-2px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .moments-name{
    font-size: 15px !important;
    color:#005580;
    margin-left: 60px;
}
.list-item .moments-desc{
    margin-top: 15px;
    font-size: 14px !important;
    color:#666666;
}

.list-item .moments-date{
    font-size: 12px !important;
    color:#666666;
    margin-left: 60px;
}

.moments-texbox{
    margin-bottom:5px;
}

.btn-addcomment{
    font-size: 13px !important;
    margin-top: 5px;
}

.moments-comment{
    margin-top:10px;
}


.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.online-column{
}

.online-category{
    font-size: 12px !important;
}
.online-commodity{
    font-size: 14px !important;
}


.no-data-bill {
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #888;
  font-size: 16px;
}

.payment-scroll-view {
  position: absolute;
  top: 116px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #fff;
}

.payment-scroll-view2 {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #fff;
  margin-bottom: 70px;
}

.payment-select-unit {
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.payment-icons {
  background-color: #fff;
}

.payment-icons-container {
  border-bottom: 1px solid #d9d9d9;
  overflow: auto;
}

.payment-icon-column {
}

.payment-link-label {
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  color: #8c8c8c;
}
.payment-link-label img {
  width: 40px;
  height: 40px;
}
table.table-icon {
  width: inherit;
}

.payment-link-active {
  color: #00abff !important;
}

.no-data {
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #888;
  font-size: 20px;
}

.pad {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.fullbutton {
  width: 100%;
}

label {
  padding: 8px;
}

.payment-page {
  background-color: #fff;
}

.payment-title {
  text-align: center;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* .payment-amount{
    text-align:center;
    color:#e60000;
    font-size: 20px;
    position: relative;
    padding-top:5px;
    padding-bottom:5px;
    text-decoration: line-through;
} */

.payment-amount {
  text-align: center;
  /* color:#e60000; */
  font-size: 20px;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  /* text-decoration: line-through; */
}

.payment-amount-diskon {
  text-align: center;
  color: #000000;
  font-size: 20px;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.payment-amount-label {
  position: absolute;
  left: 20px;
  top: 13px;
  font-size: 14px;
  color: #4d4d4d;
}

.payment-issuedate {
  font-size: 24px;
  text-align: center;
  padding: 5px;
  color: #999999;
}

table.payment-info-container {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #d9d9d9;
  margin-top: 10px;
}
table.payment-info-container tr {
  border-bottom: 1px solid #d9d9d9;
}
table.payment-info-container td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  background-color: #fcfcfc;
  color: #4d4d4d;
}
table.payment-info-container td.payment-info-value {
  text-align: right;
  color: #333333;
  font-size: 15px;
}

.payment-button-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 10px;
  background-color: white;
}
.payment-button-container button {
  border-radius: 0px;
  height: 50px;
  border-radius: 10px;
}

.pending {
  color: #ff8d00 !important;
}

.paid {
  color: #0066ff !important;
}

.Received {
  color: #008dc1 !important;
}
.modal_payment {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
.formpayment {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
}
.labelpayment {
  margin-bottom: 0rem;
  font-weight: 350;
}


.Modal {
    position: absolute;
    top: 120px;
    left: 340px;
    right: 340px;
    bottom: 120px;
    background-color: papayawhip;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
  }
.list-icon {
  font-size: 35px;
}

span.paid {
  color: #00abff;
}

span.Received {
  color: #008dc1;
}

span.pending {
  color: #ff8d00;
}

.payment-list-item {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  /* visibility: hidden; */
}

.payment-list-item .name-container {
  font-size: 16px;
  color: #000;
}

.payment-list-item .category-container {
  font-size: 13px;
  color: #8c8c8c;
}
.payment-list-item .customerid-container {
  font-size: 13px;
  color: #8c8c8c;
}

.payment-list-item .paiddate-container {
  font-size: 12px;
  color: #29a329;
  text-align: right;
}
.payment-list-item .Received-container {
  font-size: 12px;
  color: #008dc1;
  text-align: right;
}
.payment-list-item .unpaid-container {
  font-size: 12px;
  color: #ff8d00;
  text-align: right;
}

.payment-list-item .amount-container {
  color: #666666;
  font-size: 15px;
  text-align: right;
}

.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.pad{
	padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.fullbutton{
    width:100%;
}

label {
  padding: 8px;
}

.payment-page{
    background-color:#fff;
}

.payment-title{
    text-align: center;
    font-size: 22px;
    padding-top:10px;
    padding-bottom:10px;
}

.payment-amount{
    text-align:center;
    color:#e60000;
    font-size: 24px;
    position: relative;
    padding-top:5px;
    padding-bottom:5px;
}

.payment-amount-label{
    position: absolute;
    left:20px;
    top:13px;
    font-size: 14px;
    color:#4d4d4d;
}

.payment-issuedate{
    font-size: 12px;
    text-align: center;
    padding:5px;
    color:#999999;
}

table.payment-info-container{
    width:100%;
    border-collapse: collapse;
    border-top:1px solid #d9d9d9;
    margin-top:10px;
}
table.payment-info-container tr{
    border-bottom:1px solid #d9d9d9;
}
table.payment-info-container td{
    padding-top:15px;
    padding-bottom:15px;
    padding-left: 20px;
    padding-right:20px;
    font-size: 14px;
    background-color:#fcfcfc;
    color:#4d4d4d;
}
table.payment-info-container td.payment-info-value{
    text-align: right;
    color:#333333;
    font-size: 15px;
}

.payment-button-container{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
}
.payment-button-container button{
    border-radius:0px;
    height:50px;
}

.pending{
    color:#ff8d00 !important;
}

.paid{
    color:#0066ff !important;
}
.modal_payment{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
} 
.formpayment{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 300;
}
.labelpayment{
    margin-bottom: 0rem;
    font-weight: 350;

}

.button {
    background-color: #03721b; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  .button:disabled {
    opacity: 0.5;
  }
.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.pad{
	padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.fullbutton{
    width:100%;
}

label {
  padding: 8px;
}

.payment-page{
    background-color:#fff;
}

.payment-title{
    text-align: center;
    font-size: 22px;
    padding-top:10px;
    padding-bottom:10px;
}

.payment-amount{
    text-align:center;
    color:#e60000;
    font-size: 24px;
    position: relative;
    padding-top:5px;
    padding-bottom:5px;
}

.payment-amount-label{
    position: absolute;
    left:20px;
    top:13px;
    font-size: 14px;
    color:#4d4d4d;
}

.payment-issuedate{
    font-size: 12px;
    text-align: center;
    padding:5px;
    color:#999999;
}

table.payment-info-container{
    width:100%;
    border-collapse: collapse;
    border-top:1px solid #d9d9d9;
    margin-top:10px;
}
table.payment-info-container tr{
    border-bottom:1px solid #d9d9d9;
}
table.payment-info-container td{
    padding-top:15px;
    padding-bottom:15px;
    padding-left: 20px;
    padding-right:20px;
    font-size: 14px;
    background-color:#fcfcfc;
    color:#4d4d4d;
}
table.payment-info-container td.payment-info-value{
    text-align: right;
    color:#333333;
    font-size: 15px;
}

.payment-button-container{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
}
.payment-button-container button{
    border-radius:0px;
    height:50px;
}

.pending{
    color:#ff8d00 !important;
}

.paid{
    color:#0066ff !important;
}
.modal_payment{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
} 
.formpayment{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 300;
}
.labelpayment{
    margin-bottom: 0rem;
    font-weight: 350;

}
.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.pad{
	padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.fullbutton{
    width:100%;
}

label {
  padding: 8px;
}

.payment-page{
    background-color:#fff;
}

.payment-title{
    text-align: center;
    font-size: 22px;
    padding-top:10px;
    padding-bottom:10px;
}

.payment-amount{
    text-align:center;
    color:#e60000;
    font-size: 24px;
    position: relative;
    padding-top:5px;
    padding-bottom:5px;
}

.payment-amount-label{
    position: absolute;
    left:20px;
    top:13px;
    font-size: 14px;
    color:#4d4d4d;
}

.payment-issuedate{
    font-size: 12px;
    text-align: center;
    padding:5px;
    color:#999999;
}

table.payment-info-container{
    width:100%;
    border-collapse: collapse;
    border-top:1px solid #d9d9d9;
    margin-top:10px;
}
table.payment-info-container tr{
    border-bottom:1px solid #d9d9d9;
}
table.payment-info-container td{
    padding-top:15px;
    padding-bottom:15px;
    padding-left: 20px;
    padding-right:20px;
    font-size: 14px;
    background-color:#fcfcfc;
    color:#4d4d4d;
}
table.payment-info-container td.payment-info-value{
    text-align: right;
    color:#333333;
    font-size: 15px;
}

.payment-button-container{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
}
.payment-button-container button{
    border-radius:0px;
    height:50px;
}

.pending{
    color:#ff8d00 !important;
}

.paid{
    color:#0066ff !important;
}
.modal_payment{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
} 
.formpayment{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 300;
}
.labelpayment{
    margin-bottom: 0rem;
    font-weight: 350;

}
.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}


.payment-scroll-view-multiple{
    position: absolute;
    top:45px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fff;
    margin-bottom: 70px;
}

.payment-select-unit{
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
}
  
.payment-icons{
    background-color:#fff;
}

.payment-icons-container{
    border-bottom:1px solid #d9d9d9;
    overflow: auto;
}

.payment-icon-column{

}

.payment-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}
.payment-link-label img{
    width:40px;
    height:40px;
}
table.table-icon{
    width:inherit;
}

.payment-link-active{
    color:#00abff !important;
}

.list-icon{
    font-size: 35px;
}

span.paid{
    color: #00abff;
}

span.pending{
    color: #ff8d00;
}

.payment-list-item{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
    /* visibility: hidden; */
}

.payment-list-item .name-container{
    font-size:16px;
    color:#000;
}

.payment-list-item .category-container{
    font-size: 13px; 
    color:#8c8c8c;
}
.payment-list-item .customerid-container{
    font-size: 13px; 
    color:#8c8c8c;
}

.payment-list-item .paiddate-container{
    font-size: 12px;
    color:#29a329;
    text-align: right;
}
.payment-list-item .unpaid-container{
    font-size: 12px;
    color:#ff8d00;
    text-align: right;
}

.payment-list-item .amount-container{
    color:#666666;
    font-size: 15px;
    text-align: right;
}

.fullbuttonMultiple{
    width:100%;
    border-radius: 20px;
}
.list-icon{
    font-size: 35px;
}

span.paid{
    color: #00abff;
}

span.pending{
    color: #ff8d00;
}

.payment-list-item{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
    /* visibility: hidden; */
}

.payment-list-item .name-container{
    font-size:16px;
    color:#000;
}

.payment-list-item .category-container{
    font-size: 13px; 
    color:#8c8c8c;
}
.payment-list-item .customerid-container{
    font-size: 13px; 
    color:#8c8c8c;
}

.payment-list-item .paiddate-container{
    font-size: 12px;
    color:#29a329;
    text-align: right;
}
.payment-list-item .unpaid-container{
    font-size: 12px;
    color:#ff8d00;
    text-align: right;
}

.payment-list-item .amount-container{
    color:#666666;
    font-size: 15px;
    text-align: right;
}

.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.payment-scroll-view{
    position: absolute;
    top:50px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fff;
}

.payment-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}
.payment-link-label img{
    width:40px;
    height:40px;
}
table.table-icon{
    width:inherit;
}

.payment-link-active{
    color:#00abff !important;
}
.list-icon{
    font-size: 35px;
}

span.paid{
    color: #00abff;
}

span.pending{
    color: #ff8d00;
}

.visitor-list-item{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
}

.visitor-list-item .name-container{
    font-size:16px;
    color:#000;
}

.visitor-list-item .address-container{
    font-size: 13px; 
    color:#8c8c8c;
}
.visitor-list-item .date-container{
    font-size: 13px; 
    color:#8c8c8c;
}

.visitor-list-item .image-container{
    position: absolute;
    left:0px;
    top:0px;
    width:90px;
    height:100px;
    vertical-align: middle;
    text-align: center;

}

.visitor-list-item .image-container img{
    max-height:80px;
    max-width:80px;
    margin:auto;
}
.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.pad{
	padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.fullbutton{
    width:100%;
}

label {
  padding: 8px;
}

.uservisit-page{
    background-color:#fff;
}

.uservisit-title{
    text-align: center;
    font-size: 22px;
    padding-top:10px;
    padding-bottom:10px;
}

.uservisit-amount{
    text-align:center;
    color:#e60000;
    font-size: 24px;
    position: relative;
    padding-top:5px;
    padding-bottom:5px;
}

.uservisit-amount-label{
    position: absolute;
    left:20px;
    top:13px;
    font-size: 14px;
    color:#4d4d4d;
}

.uservisit-issuedate{
    font-size: 12px;
    text-align: center;
    padding:5px;
    color:#999999;
}

table.uservisit-info-container{
    width:100%;
    border-collapse: collapse;
    border-top:1px solid #d9d9d9;
    margin-top:10px;
}
table.uservisit-info-container tr{
    border-bottom:1px solid #d9d9d9;
}
table.uservisit-info-container td{
    padding-top:15px;
    padding-bottom:15px;
    padding-left: 20px;
    padding-right:20px;
    font-size: 14px;
    background-color:#fcfcfc;
    color:#4d4d4d;
}
table.uservisit-info-container td.uservisit-info-value{
    text-align: right;
    color:#333333;
    font-size: 15px;
}

.uservisit-button-container{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
}
.uservisit-button-container button{
    border-radius:0px;
    height:50px;
}

.pending{
    color:#ff8d00 !important;
}

.paid{
    color:#0066ff !important;
}
.service-center{
    padding-top:10px;
}

.service-center .padding-container-left{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px; 
    padding-right:5px;
}
.service-center .padding-container-right{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:5px; 
    padding-right:10px;
}

.service-center .img-container{
    text-align: center;
    position: relative;
    height: 150px;
    background-color: #fff;
    border:1px solid #d9d9d9;
}
.service-center .img-container img{
    width:100%;
    max-height: 150px;
    margin: auto;
}

.service-center .img-container .name{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    padding-top:2px;
    padding-bottom:2px;
    color:#ffffff;
    text-align: center;
    font-size: 14px;
}

.service-list{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
.services-list-col-dot{
    padding:5px 10px;
    text-align: center;
    width:30px;
    font-size: 9px;
}

.services-list-col1{
    padding:10px 10px;
}
.services-list-col1 .services-name{
    /* font-size: 16px;
    color:#000; */

    font-size: 14px;
    color:#8c8c8c;
}
.services-list-col1 .services-desc{
    font-size: 14px;
    color:#000;
}

.services-list-col-price{
    padding:10px;
    text-align: right;
    padding-right:20px;
}
.services-list-col-price .services-price{
    font-size: 14px;
    color:#4d4d4d;
}

.services-list-col-qty{
    padding-right:10px;
    width:90px;
}
.services-list-col-qty .services-btn{
    display: inline-block;
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color:#ff9900;
    margin-top:-2px;
}
.services-list-col-qty .services-qty{
    display: inline-block;
    margin-right:3px;
    margin-left:3px;
    border:1px solid #d9d9d9;
    width:30px;
    text-align: center;
    font-size: 14px;
}

.service-status-label{
    color:#8c8c8c;
    font-size: 13px;
    text-align: center;
}

.service-status-value{
    font-size: 14px;
    text-align: center;   
}

.services-scroll-view{
    position: absolute;
    top:130px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fafafa;
}

.services-footer{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#005ce6;
    color:#fff;
    font-size: 16px;
    text-align: center;
    height:50px;
    line-height: 50px;
    vertical-align: middle;
}

.service-icons{
    background-color:#fff;
	
}

.service-icons-container{
    border-bottom:1px solid #d9d9d9;
    overflow: auto;
}

.service-icon-column{
	
}

.service-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}

.service-link-label img{
    width:40px;
    height:40px;
}

table.table-icon{
    width:inherit;
}

.service-link-active{
    color:#00abff !important;
	padding-bottom: 25px;
}

.tab-section-service{
    padding-top:4px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:35px;
}
.tab-section-service .tab-item{
    text-align: center;
    font-size: 16px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.tab-section-service .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}
.sub-header-service{
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    padding:10px 20px;
    position: relative;
    display: flex;
}

.back-container{
    display: inline-block;
    font-size: 16px;
    color:#0066ff;
}
.search-container{
    position: absolute;
    top:5px;
    right:60px;
    left:80px;
}
.search-container input{
    border:1px solid #d9d9d9;
    color:#333;
    border-radius:10px;
    width:100%;
    padding: 4px 15px;
    padding-left:35px;
}
.search-container input:focus{
    border:1px solid #0066ff;
}

.search-container .magnify{
    position: absolute;
    top:9px;
    left:10px;
    color:#808080;
}

.title-container{
    display: inline-block;
    margin:auto;
    height:24px;
}
.right-container{
    display: inline-block;
    font-size: 16px;
    height:24px;
    color:#0066ff;
    float:right;
    text-align: right;
}
.service-center-detail{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.service-center-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.service-center-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.service-center-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.service-center-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.service-center-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.service-center-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.service-center-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.service-center-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.service-center-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.service-center-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 50px;
    background-color:#fcfcfc;
}

.service-center-confirmation{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:40px;
    left:0px;
    right:0px;
    overflow: auto;
    height: inherit;
}

.service-center-confirmation .sc-form-label{
    color:#8c8c8c;
    margin-top:10px;
}

.service-center-confirmation .sc-form-input {
    border: 1px solid #d9d9d9;
}

.service-center-confirmation .sc-form-input input, textarea{
    padding:3px 10px;
    width: 100%;
}


.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#005ce6;
    padding:8px;
    text-align: center;
}
.service-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.service-center-form1{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
}

.service-center-form{
    padding:0px;
    position: absolute;
    top:55px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
    padding-bottom: 60px;
    margin-bottom: 60px;
}


.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    font-weight: bold;
    margin-bottom:5px;
}

.field-container .bottom-bordered{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:5px;
}
.field-container .field-value{
    
}
.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
    margin-bottom: 20px;
}

select.service-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.service-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}

.submit-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:rgb(0, 0, 0);
    background-color:#f8f8f8;
    margin-top: 10px;
    padding:12px;
    text-align: center;
}

.service-button-container{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    padding: 5px;
    background-color: white;
}
.service-button-container button{
    border-radius:0px;
    height:50px;
    border-radius: 10px;
}

.continer-payment-method-service{
    margin-bottom: 50px;
}

.continer-request-service{
    margin-bottom: 10px;
}

.service-list-menu{
    background-color: #e9e9e9;
    padding: 10px;
    font-weight: bold;
}

.next-button-service{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}
.addon-item .relative-container  {
    margin-bottom:10px;
    position:relative;
}

.addon-item .relative-container .name-container{
    font-size: 14px !important;
    color: rgb(0, 0, 0);
    /* font-weight: bold; */
	/* display: inline-block; */
	vertical-align: middle;
}

.addon-item .relative-container .qty-container{
    position: absolute;
    right:10px;
    /*bottom:10px;*/
    text-align:center;
    display: inline-block;
    height:24px;
}
.addon-item .relative-container .qty-container .qty-value{
    text-align: center;
    display: inline-block;
    margin: auto;
    padding-left:3px;
    padding-right:3px;
    background-color:#ffffff;
	vertical-align: middle;
    float:right;
    width:24px;
}
.addon-item .relative-container .qty-container .plus-minus-button{
    width:100px;
	display: inline-block;
	text-align: center;
}
.addon-item .relative-container .qty-container .plus-minus-button .plus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#B0E0E6;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float:right;
    margin-left:10px;
    
}
.addon-item .relative-container .qty-container .plus-minus-button .minus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#B0E0E6;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float: right;
	margin-right:10px;
}

.service-list{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
    border-radius: 2px;
}

.service-center-category-page{
    background-color:#fff;
}

.servicecentercategory-icons-container{
}
.servicecentercategory-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.servicecentercategory-icons-container .servicecentercategory-icon-column{
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top:10px;
    margin-bottom:5px;
}

.category-left-column{
    padding-left:20px; 
    padding-right:10px;
}
.category-right-column{
    padding-left:10px;
    padding-right:20px;
}

.service-center-category-content{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.2);
    border-radius:10px;
    overflow: hidden;
}

.servicecentercategory-link-label img.image-250 {
    width: 100%;
}

.servicecentercategory-link-label{
    
}

.servicecenter-category-label-name{
    font-size: 15px;
    padding:5px;
    color:#4d4d4d; 
}
.room-reservation{
    padding-top:10px;
}

.room-reservation .padding-container-left{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px; 
    padding-right:5px;
}
.room-reservation .padding-container-right{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:5px; 
    padding-right:10px;
}

.room-reservation .img-container{
    text-align: center;
    position: relative;
    height: 150px;
    background-color: #fff;
    border:1px solid #d9d9d9;
}
.room-reservation .img-container img{
    width:100%;
    max-height: 150px;
    margin: auto;
}

.room-reservation .img-container .name{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    padding-top:2px;
    padding-bottom:2px;
    color:#ffffff;
    text-align: center;
    font-size: 14px;
}

.room-list{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
.room-list-col-dot{
    padding:5px 10px;
    text-align: center;
    width:30px;
    font-size: 9px;
}

.room-list-col1{
    padding:15px 10px;
}
.room-list-col1 .room-name{
    font-size: 16px;
    color:#000;
}
.room-list-col1 .room-desc{
    font-size: 13px;
    color:#8c8c8c;
}

.room-list-col-price{
    padding:10px;
    text-align: right;
    padding-right:20px;
}
.room-list-col-price .room-price{
    font-size: 14px;
    color:#4d4d4d;
}

.room-list-col-qty{
    padding-right:10px;
    width:90px;
}
.room-list-col-qty .room-btn{
    display: inline-block;
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color:#ff9900;
    margin-top:-2px;
}
.room-list-col-qty .room-qty{
    display: inline-block;
    margin-right:3px;
    margin-left:3px;
    border:1px solid #d9d9d9;
    width:30px;
    text-align: center;
    font-size: 14px;
}

.room-status-label{
    color:#8c8c8c;
    font-size: 13px;
    text-align: center;
}

.room-status-value{
    font-size: 16px;
    text-align: center;   
}

.room-scroll-view{
    position: absolute;
    top:116px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fafafa;
}

.room-footer{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#005ce6;
    color:#fff;
    font-size: 16px;
    text-align: center;
    height:50px;
    line-height: 50px;
    vertical-align: middle;
}

.room-icons{
    background-color:#fff;
}

.room-icons-container{
    border-bottom:1px solid #d9d9d9;
    overflow: auto;
}

.room-icon-column{

}

.room-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}

.room-link-label img{
    width:40px;
    height:40px;
}

.rooms-list-col1 {
    padding-top:10px;
    padding-bottom:10px;
}

.rooms-list-col1 .room-name{
    font-size:16px;
}
.rooms-list-col1 .room-checkin{
    font-size:13px;
    color:#8c8c8c;
}
.rooms-list-col1 .room-checkout{
    font-size:13px;
    color:#8c8c8c;
}

span.pending{
	color:#00abff;
}

span.paid{
    color: #ff8d00;
}

span.cancelled{
    color: #c0c0c0;
}

.activity-reservation{
    padding-top:10px;
}

.activity-reservation .padding-container-left{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px; 
    padding-right:5px;
}
.activity-reservation .padding-container-right{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:5px; 
    padding-right:10px;
}

.activity-reservation .img-container{
    text-align: center;
    position: relative;
    height: 150px;
    background-color: #fff;
    border:1px solid #d9d9d9;
}
.activity-reservation .img-container img{
    width:100%;
    max-height: 150px;
    margin: auto;
}

.activity-reservation .img-container .name{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    padding-top:2px;
    padding-bottom:2px;
    color:#ffffff;
    text-align: center;
    font-size: 14px;
}

.activity-list{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
.activity-list-col-dot{
    padding:5px 10px;
    text-align: center;
    width:30px;
    font-size: 9px;
}

.activity-list-col1{
    padding:15px 10px;
}
.activity-list-col1 .activity-name{
    font-size: 16px;
    color:#000;
}
.activity-list-col1 .activity-desc{
    font-size: 13px;
    color:#8c8c8c;
}

.activity-list-col-price{
    padding:10px;
    text-align: right;
    padding-right:20px;
}
.activity-list-col-price .activity-price{
    font-size: 14px;
    color:#4d4d4d;
}

.activity-list-col-qty{
    padding-right:10px;
    width:90px;
}
.activity-list-col-qty .activity-btn{
    display: inline-block;
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color:#ff9900;
    margin-top:-2px;
}
.activity-list-col-qty .activity-qty{
    display: inline-block;
    margin-right:3px;
    margin-left:3px;
    border:1px solid #d9d9d9;
    width:30px;
    text-align: center;
    font-size: 14px;
}

.activity-status-label{
    color:#8c8c8c;
    font-size: 13px;
    text-align: center;
}

.activity-status-value{
    font-size: 16px;
    text-align: center;   
}

.activity-scroll-view{
    position: absolute;
    top:116px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fafafa;
}

.activity-footer{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#005ce6;
    color:#fff;
    font-size: 16px;
    text-align: center;
    height:50px;
    line-height: 50px;
    vertical-align: middle;
}

.activity-icons{
    background-color:#fff;
}

.activity-icons-container{
    border-bottom:1px solid #d9d9d9;
    overflow: auto;
}

.activity-icon-column{

}

.activity-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}

.activity-link-label img{
    width:40px;
    height:40px;
}

.activities-list-col1 {
    padding-top:10px;
    padding-bottom:10px;
}

.activities-list-col1 .activity-name{
    font-size:16px;
}
.activities-list-col1 .activity-checkin{
    font-size:13px;
    color:#8c8c8c;
}
.activities-list-col1 .activity-checkout{
    font-size:13px;
    color:#8c8c8c;
}

.activity-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}

.activity-link-label img{
    width:40px;
    height:40px;
}
.room-category-page{
    background-color:#fff;
}

.roomcategory-icons-container{
}
.roomcategory-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.roomcategory-icons-container .roomcategory-icon-column{
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top:10px;
    margin-bottom:5px;
}

.category-left-column{
    padding-left:20px; 
    padding-right:10px;
}
.category-right-column{
    padding-left:10px;
    padding-right:20px;
}

.room-category-content{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.2);
    border-radius:10px;
    overflow: hidden;
}

.roomcategory-link-label img.image-250 {
    width: 100%;
}

.roomcategory-link-label{
    
}

.room-category-label-name{
    font-size: 15px;
    padding:5px;
    color:#4d4d4d; 
}
.service-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.service-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    margin-bottom:5px;
    font-weight: bold;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.service-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.service-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}
.submit-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:rgb(0, 0, 0);
    background-color:#f8f8f8;
    margin-top: 10px;
    padding:12px;
    text-align: center;
}

.list-item-wedding .commodity-name{
    /*font-size: 18px !important;*/
    font-weight: bold;
}

.list-item-wedding .commodity-imgitem-container{
    position: absolute;
    left:0px;
    top:0px;
    width:80px;
    height:80px;
    line-height: 80px;
    /*border:1px solid #d9d9d9;*/
    vertical-align: middle;
    text-align: center;

}

.list-item-wedding .commodity-imgitem-container img{
    max-height:80px;
    max-width:80px;
    margin:auto;
}

.list-item-wedding .data-container .commodity-tags-container{
    padding-left:0px;
}
.list-item-wedding .data-container .commodity-tags-container .tag{
    font-size: 12px;
    background-color:#f2f2f2;
    color:#808080;
    display: inline;
    padding:1px 5px;
    border-radius: 0px;
    margin-right:3px;

}

.list-item-wedding .commodity-price-container{
    color:#ff9933;
    font-size: 14px;
    max-height: 38px;
    overflow: auto;
}

.list-item-wedding .commodity-price-container span{
    font-size: 16px;
}
.list-item-wedding .commodity-price-container span.stroke-price{
    font-size: 12px;
    color:#cccccc;
    text-decoration: line-through;
}

.list-item-wedding .commodity-sold-container{
    font-size:20px;
    color:#a6a6a6;
    margin-top:5px;
}

.list-item-wedding .relative-container .qty-container{
    position: absolute;
    right:10px;
    bottom:10px;
    text-align:center;
	display: inline-block;
}
.list-item-wedding .relative-container .qty-container .qty-value{
    text-align: center;
    display: inline-block;
    margin: auto;
    padding-left:3px;
    padding-right:3px;
    background-color:#ffffff;
	vertical-align: middle;
	float:right;
}
.list-item-wedding .relative-container .qty-container .plus-minus-button{
    width:100px;
	display: inline-block;
	text-align: center;
}
.list-item-wedding .relative-container .qty-container .plus-minus-button .plus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#ff9933;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float:right;
    margin-left:10px;
    
}
.list-item-wedding .relative-container .qty-container .plus-minus-button .minus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#ff9933;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float: right;
	margin-right:10px;
}

.list-item-wedding .commodity-data-container{
    border-bottom:0px;
    margin-bottom:10px;
}

.product-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.product-content .product{
    color:#000;
    font-size: 16px;
    margin-top:5px;
	text-align: center;
}

.product-content .product-title{
    color:#000;
    font-size: 20px;
    margin-top:5px;
	text-align: center;
	font-weight: bold;
}

.product-content .product-image {
	text-align: center;
}

.product-content .product-image img{
    width:100%;
	height:100%;
    max-height: 300px;
	max-width: 300px;
    margin: auto;
}

.product-content .product-description{
    font-size: 16px;
    margin-top:5px;
	text-align: center;
}

.product-content .product-price{
    font-size: 14px;
    color:#ff6666;
    margin-top:5px;
	text-align: center;
	font-weight: bold;
}

.product-content .product-tags-container{
    padding-left:0px;
	font-size: 12px;
	text-align: center;
}
.product-content .product-tags-container .tag{
    font-size: 12px;
    background-color:#f2f2f2;
    color:#808080;
    display: inline;
    padding:1px 5px;
    border-radius: 0px;
    margin-right:3px;

}
.addon-item .relative-container  {
    margin-bottom:10px;
    position:relative;
}

.addon-item .relative-container .name-container{
    /*font-size: 18px !important;*/
    font-weight: bold;
	display: inline-block;
	vertical-align: middle;
}

.addon-item .relative-container .qty-container{
    position: absolute;
    right:10px;
    /*bottom:10px;*/
    text-align:center;
    display: inline-block;
    height:24px;
}
.addon-item .relative-container .qty-container .qty-value{
    text-align: center;
    display: inline-block;
    margin: auto;
    padding-left:3px;
    padding-right:3px;
    background-color:#ffffff;
	vertical-align: middle;
    float:right;
    width:24px;
}
.addon-item .relative-container .qty-container .plus-minus-button{
    width:100px;
	display: inline-block;
	text-align: center;
}
.addon-item .relative-container .qty-container .plus-minus-button .plus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#B0E0E6;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float:right;
    margin-left:10px;
    
}
.addon-item .relative-container .qty-container .plus-minus-button .minus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#B0E0E6;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float: right;
	margin-right:10px;
}

.room-reservation-detail{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.room-reservation-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.room-reservation-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.room-reservation-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.room-reservation-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.room-reservation-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.room-reservation-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.room-reservation-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.room-reservation-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.room-reservation-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.room-reservation-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 100px;
    background-color:#fcfcfc;
}

.room-category-page{
    background-color:#fff;
}

.roomcategory-icons-container{
}
.roomcategory-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.roomcategory-icons-container .roomcategory-icon-column{
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top:10px;
    margin-bottom:5px;
}

.category-left-column{
    padding-left:20px; 
    padding-right:10px;
}
.category-right-column{
    padding-left:10px;
    padding-right:20px;
}

.room-category-content{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.2);
    border-radius:10px;
    overflow: hidden;
}

.roomcategory-link-label img.image-250 {
    width: 100%;
}

.roomcategory-link-label{
    
}

.room-category-label-name{
    font-size: 15px;
    padding:5px;
    color:#4d4d4d; 
}
.service-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.service-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    margin-bottom:5px;
    font-weight: bold;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.service-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.service-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}
.submit-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:rgb(0, 0, 0);
    background-color:#f8f8f8;
    margin-top: 10px;
    padding:12px;
    text-align: center;
}
.room-reservation-detail{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.room-reservation-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.room-reservation-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.room-reservation-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.room-reservation-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.room-reservation-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.room-reservation-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.room-reservation-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.room-reservation-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.room-reservation-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.room-reservation-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 100px;
    background-color:#fcfcfc;
}

.room-reservation-detail{
    padding:10px;
    position: absolute;
    top:55px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.room-reservation-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.room-reservation-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.room-reservation-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.room-reservation-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.room-reservation-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.room-reservation-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.room-reservation-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.room-reservation-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.room-reservation-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.room-reservation-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 100px;
    background-color:#fcfcfc;
}

.service-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.service-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    margin-bottom:5px;
    font-weight: bold;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.service-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.service-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}

.room-category-page{
    background-color:#fff;
}

.roomcategory-icons-container{
}
.roomcategory-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.roomcategory-icons-container .roomcategory-icon-column{
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top:10px;
    margin-bottom:5px;
}

.category-left-column{
    padding-left:20px; 
    padding-right:10px;
}
.category-right-column{
    padding-left:10px;
    padding-right:20px;
}

.room-category-content{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.2);
    border-radius:10px;
    overflow: hidden;
}

.roomcategory-link-label img.image-250 {
    width: 100%;
}

.roomcategory-link-label{
    
}

.room-category-label-name{
    font-size: 15px;
    padding:5px;
    color:#4d4d4d; 
}
.activity-reservation-detail{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.activity-reservation-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.activity-reservation-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.activity-reservation-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.activity-reservation-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.activity-reservation-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.activity-reservation-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.activity-reservation-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.activity-reservation-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.activity-reservation-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.activity-reservation-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 100px;
    background-color:#fcfcfc;
}

.service-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.service-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    margin-bottom:5px;
    font-weight: bold;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.service-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.service-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}

.addon-item .relative-container  {
    margin-bottom:10px;
    position:relative;
}

.addon-item .relative-container .name-container{
    /*font-size: 18px !important;*/
    font-weight: bold;
	display: inline-block;
	vertical-align: middle;
}

.addon-item .relative-container .qty-container{
    position: absolute;
    right:10px;
    /*bottom:10px;*/
    text-align:center;
    display: inline-block;
    height:24px;
}
.addon-item .relative-container .qty-container .qty-value{
    text-align: center;
    display: inline-block;
    margin: auto;
    padding-left:3px;
    padding-right:3px;
    background-color:#ffffff;
	vertical-align: middle;
	float:right;
}
.addon-item .relative-container .qty-container .plus-minus-button{
    width:100px;
	display: inline-block;
	text-align: center;
}
.addon-item .relative-container .qty-container .plus-minus-button .plus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#B0E0E6;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float:right;
    margin-left:10px;
    
}
.addon-item .relative-container .qty-container .plus-minus-button .minus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#B0E0E6;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float: right;
	margin-right:10px;
}

.activity-category-page{
    background-color:#fff;
}

.activityategory-icons-container{
}
.activitycategory-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.activitycategory-icons-container .activitycategory-icon-column{
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top:10px;
    margin-bottom:5px;
}

.activity-category-content{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.2);
    border-radius:10px;
    overflow: hidden;
}

.activitycategory-link-label img.image-250 {
    width: 100%;
}

.activitycategory-link-label{
    
}

.activity-category-label-name{
    font-size: 15px;
    padding:5px;
    color:#4d4d4d; 
}
.directory-category-page{
    background-color:#fff;
}

.directorycategory-icons-container{
}
.directorycategory-icons-container::after{
    content: " ";
    display: table;
    clear: both;
}
.directorycategory-icons-container .directorycategory-icon-column{
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top:10px;
    margin-bottom:5px;
}

.category-left-column{
    padding-left:20px; 
    padding-right:10px;
}
.category-right-column{
    padding-left:10px;
    padding-right:20px;
}

.directory-category-content{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.2);
    border-radius:10px;
    overflow: hidden;
}

.directorycategory-link-label img.image-250 {
    width: 100%;
}

.directorycategory-link-label{
    
}

.directory-category-label-name{
    font-size: 15px;
    padding:5px;
    color:#4d4d4d; 
}
.no-data {
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #888;
  font-size: 20px;
}

.directory-scroll-view {
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #fff;
}

.directory-link-label {
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  color: #8c8c8c;
}
.directory-link-label img {
  width: 40px;
  height: 40px;
}
table.table-icon {
  width: inherit;
}

.directory-link-active {
  color: #00abff !important;
}

.tab-section {
  padding-top: 4px;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  height: 30px;
}
.tab-section .tab-item {
  text-align: center;
  font-size: 12px;
  padding-bottom: 5px;
  color: #bfbfbf;
}
.tab-section .active-tab {
  color: #0066ff;
  border-bottom: 2px solid #0066ff;
}

.directory-all-tab-section {
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 4px;
  padding-right: 4px;
  background-color: #fff;
  border-right: 1px solid #d9d9d9;
}

.directory-all-tab-section .tab-item-directory-all {
  text-align: center;
  font-size: 12px;
  padding-bottom: 5px;
  color: #bfbfbf;
}
.directory-all-tab-section .active-tab-directory-all {
  color: #0066ff;
  border-bottom: 2px solid #0066ff;
}

.directory-all-list-section {
  position: absolute;
  top: 50px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  overflow: auto;
}
.directory-all-list-section .list {
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;
}
.directory-all-list-section .list .title {
  color: #0066ff;
  font-size: 16px;
  font-weight: bold;
}

.directory-all-list-section .list .shortdesc {
  color: #404040;
  font-size: 14px;
  margin-top: 4px;
}
.directory-all-list-section .list .time {
  color: #a6a6a6;
  font-size: 12px;
  margin-top: 3px;
}
.directory-all-list-section .list .shortdesc {
  color: #333;
  font-size: 13px;
  margin-top: 3px;
}
.directory-all-list-section .no-data {
  color: #8c8c8c;
  font-size: 18px;
  text-align: center;
  height: 100px;
  line-height: 100px;
  vertical-align: middle;
}

.directory-all-list-section .loadmore-container {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #0066ff;
  text-decoration: underline;
}

.directory-all-list-section .loadmore-loading-container {
  text-align: center;
  padding: 5px;
}
.directory-all-list-section .loadmore-loading-container img {
  width: 50px;
  height: 50px;
}

.text-mode {
  height: auto;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
}

.tree-image-mode {
  height: auto;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
}

.image-tree-mode {
  position: absolute;
  left: 0px;
  top: -10px;
  width: 90px;
  height: 90px;
  vertical-align: middle;
  text-align: center;
}

.thumbnail {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
}
.thumbnail img.portrait {
  width: 100%;
  height: auto;
}

.center-cropped {
  width: 100px;
  height: 100px;
  overflow: hidden;
  padding: 6px;
  margin: auto;
}
.center-cropped img {
  height: 100%;
  min-width: 100%;
  left: 50%;
  position: relative;
  /* background-color: #dbdbdb;
    padding: 6px; */
  transform: translateX(-50%);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.center-cropped img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.img-cropped {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 90px;
  vertical-align: middle;
  text-align: center;
}
.img-cropped img {
  height: 100%;
  min-width: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

.img-cropped img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.category-Text {
  color: #fff;
  background-color: #0066ff;
  /* display: inline-block; */
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.descriptime {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.descript-video {
  position: relative;
  display: flex;
  flex-direction: row;
}

.tree-mode-image {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  text-align: center;
}

.timeupdate {
  flex: 1 1;
  text-align: left;
  font-size: small;
}

.reading {
  flex: 1 1;
  text-align: right;
  font-size: small;
}

.image-big-mode {
  background-color: white;
  width: 100%;
  height: 200px;
}
.image-big-mode img {
  width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  background-color: #dbdbdb;
  transform: translateX(-50%);
}

.small-video {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 100px;
  height: auto;
  vertical-align: middle;
  text-align: center;
}

.descript-container-border {
  border-bottom: 1px solid #d9d9d9;
}

.descript-container-small {
  margin-left: 100px;
  height: 100px;
  padding-left: 10px;
  position: relative;
}

.phone-address-text-name {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
  /* display: inline; */
}

.directoryname-text {
  color: #0066ff;
  font-size: 14px;
  height: auto;
  text-overflow: inherit;
  overflow: hidden;
  white-space: normal;
}

.tab-section-category-directory-all {
  /* padding-left: 10px;
    padding-right: 10px; */
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.tab-section-all-directory-all-cateogry {
  /* padding-left: 10px; */
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.tab-section-list-colom {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: inherit;
  overflow: auto;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);

  /* position: 'absolute',
  top:0,bottom:0,left:0,right:0,flex:1,backgroundColor:'rgba(0,0,0,.2)',alignItems: 'center',alignSelf: 'center',justifyContent: 'center',padding:100,elevation: 6 */
}

.tab-section-list-directory-all-category {
  padding: 6px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fafbfc;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.type-selection {
  margin-left: 6px;
  text-align: left;
  justify-content: center;
  font-size: 14px;
  border-left: 3px solid #0066ff;
}

.listing-item-category-directory-all {
  flex-direction: column;
}

.listing-item-down-category-directory-all {
  flex-direction: column;
  border-right: 1px solid #d9d9d9;
}

.icon-filter-cateory-directory-all {
  margin-left: 10px;
  margin-right: 5px;
  flex-wrap: nowrap;
  flex-direction: column;
}

.icon-undo-filter-cateory-directory-all {
  padding-left: 10px;
  padding-right: 6px;
  flex-wrap: nowrap;
  flex-direction: column;
  border-left: 1px solid #d9d9d9;
}

.data-item-tab-category {
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.tab-section-all-list-cateogry {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.listing-item-category-directory-all-down {
  flex-direction: column;
}

.directory-all-category-tab-section {
  padding: 4px;
  background-color: #fff;
  height: auto;
  overflow: auto;
  white-space: nowrap;
}

.directory-all-category-tab-section .tab-item-directory-all {
  text-align: center;
  font-size: 12px;
  padding-bottom: 5px;
  padding-bottom: 5px;
  /* margin: 6px; */
  background-color: #fafbfc;
  color: #bfbfbf;
}
.directory-all-category-tab-section .active-tab-directory-all {
  color: #0066ff;
  border-bottom: 2px solid #0066ff;
}

.directory-all-list-section-data {
  position: absolute;
  top: 50px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  overflow: auto;
}
.directory-all-list-section-data .list {
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;
}
.directory-all-list-section-data .list .title {
  color: #0066ff;
  font-size: 16px;
  font-weight: bold;
}

.directory-all-list-section-data .list .shortdesc {
  color: #404040;
  font-size: 14px;
  margin-top: 4px;
}
.directory-all-list-section-data .list .time {
  color: #a6a6a6;
  font-size: 12px;
  margin-top: 3px;
}
.directory-all-list-section-data .list .shortdesc {
  color: #333;
  font-size: 13px;
  margin-top: 3px;
}
.directory-all-list-section-data .no-data {
  color: #8c8c8c;
  font-size: 18px;
  text-align: center;
  height: 100px;
  line-height: 100px;
  vertical-align: middle;
}

.directory-all-list-section-data .loadmore-container {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #0066ff;
  text-decoration: underline;
}

.directory-all-list-section-data .loadmore-loading-container {
  text-align: center;
  padding: 5px;
}
.directory-all-list-section-data .loadmore-loading-container img {
  width: 50px;
  height: 50px;
}

.directory-list-item{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:10px;
}

.directory-list-item .name-container{
    font-size:16px;
    color:#000;
}

.directory-list-item .phone-container{
    font-size: 13px; 
    color:#8c8c8c;
}
.directory-list-item .address-container{
    font-size: 13px; 
    color:#8c8c8c;
}

.directory-list-item .image-container{
    position: absolute;
    left:0px;
    top:20px;
    width:90px;
    height:100px;
    vertical-align: middle;
    text-align: center;

}

.directory-list-item .image-container img{
    max-height:80px;
    max-width:80px;
    margin:auto;
}
.whitebg{
    background-color:#fff;
	width: 100%;
}
.top-navigation{
    padding:5px 10px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
}
.top-navigation .back-button{
    color:#0066ff;
    display: inline;
}

.directory-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
	top: 10px;
	width: 100%;
}

.directory-content  img{
    max-height:100%;
    max-width:100%;
    margin-top: 10px;;
}

.directory-content .directory-text{
    color:#111;
    font-size: 20px;
    margin-top:5px;
	text-align: left;
	margin-left: 40px;
	width: 100%;
}

.directory-content .directory-social{
    color:#0645AD;
    font-size: 14px;
    margin-top:5px;
	text-align: left;
	width: 100%;
	margin-left: 0px;
	vertical-align: middle;
}

.directory-content .directory-social img{
    width:20px;
    height:20px;
	vertical-align: middle;
	margin-top: -5px;
}

.map-container{
    width:100%;
    padding-top:5px;
    padding-bottom:5px;
    border-top:1px solid #d9d9d9;
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
    margin-bottom:10px;
    height: 300px;
}
.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.directory-scroll-view{
    position: absolute;
    top:45px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fff;
}

.directory-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}
.directory-link-label img{
    width:40px;
    height:40px;
}
table.table-icon{
    width:inherit;
}

.directory-link-active{
    color:#00abff !important;
}
.merchant-profile {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile .menu-section-container {
}
.merchant-profile .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile .menu-section-container td.category-column .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile .menu-banner-container {
  padding: 10px;
}
.merchant-profile .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url(/smart/static/media/Food.23e5535c.png) no-repeat top;
  background-size: 100% 150px;
}
.merchant-profile .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile .merchant-info-container table {
  width: 100%;
}
.merchant-profile .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile .cart-icon {
  font-size: 17px;
}

.merchant-payment .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url(/smart/static/media/Food.23e5535c.png) no-repeat top;
  background-size: 100% 110px;
}

.merchant-payment .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  background-color: #ff9933;
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}

.list-item .foodgarden-name {
  font-size: 15px !important;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
  font-family: monospace;
}

.list-item .foodgarden-imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  line-height: 95px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  justify-items: center;
  background-color: white;
}

.list-item .foodgarden-imgitem-container img {
  width: 90%;
  height: 80%;
  max-height: 200px;
  max-width: 200px;
  margin: auto;
}

.list-item .data-container .foodgarden-tags-container {
  padding-left: 0px;
}
.list-item .data-container .foodgarden-tags-container .tag {
  font-size: 12px;
  background-color: #f2f2f2;
  color: #808080;
  display: inline;
  padding: 1px 5px;
  border-radius: 0px;
  margin-right: 3px;
}

.list-item .foodgarden-price-container {
  color: #000000;
  font-size: 14px;
  max-height: 38px;
  overflow: auto;
}

.list-item .foodgarden-shortdesc-container {
  color: #999999;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60ch;
}

.list-item .foodgarden-price-container span {
  font-size: 16px;
}
.list-item .foodgarden-price-container span.stroke-price {
  font-size: 12px;
  color: #cccccc;
  text-decoration: line-through;
}

.list-item .foodgarden-sold-container {
  font-size: 12px;
  color: #a6a6a6;
  margin-top: 5px;
}

.list-item .relative-container .qty-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-align: center;
  display: inline-block;
}
.list-item .relative-container .qty-container .qty-value {
  text-align: center;
  display: inline-block;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  vertical-align: middle;
  float: right;
}
.list-item .relative-container .qty-container .plus-minus-button {
  width: 100px;
  display: inline-block;
  text-align: center;
}
/* .list-item .relative-container .qty-container .plus-minus-button .plus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-left: 10px;
  font-weight: bold;
}
.list-item .relative-container .qty-container .plus-minus-button .minus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-right: 10px;
  font-weight: bold;
} */

.list-item .foodgarden-data-container {
  border-bottom: 0px;
  margin-bottom: 10px;
}

.list-item .data-container {
  margin-left: 100px;
  height: 100px;
  padding-left: 10px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
}

.product-content {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.product-content .product {
  color: #000;
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.product-content .product-title {
  color: #000;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}

.product-content .product-image {
  text-align: center;
}

.product-content .product-image img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  margin: auto;
}

.product-content .product-description {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.product-content .product-price {
  font-size: 14px;
  color: #ff6666;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}

.product-content .product-tags-container {
  padding-left: 0px;
  font-size: 12px;
  text-align: center;
}
.product-content .product-tags-container .tag {
  font-size: 12px;
  background-color: #f2f2f2;
  color: #808080;
  display: inline;
  padding: 1px 5px;
  border-radius: 0px;
  margin-right: 3px;
}

.product-content .relative-container .qty-container {
  /* position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px; */
  margin-top: 10px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  float: right;
  position: relative;
  left: -38%;
}
.product-content .relative-container .qty-container .qty-value {
  text-align: center;
  display: inline-block;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  vertical-align: middle;
  float: right;
}

.product-content .qty-container .plus-minus-button .plus-button {
  border-radius: 25%;
  height: 25px;
  width: 25px;
  line-height: 24px;
  /* font-family: fantasy; */
  font-weight: 800;
  vertical-align: middle;
  background-color: #007bff;
  /* background-color: #ff9933; */
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-left: 10px;
}

.product-content .qty-container .plus-minus-button .minus-button {
  border-radius: 25%;
  height: 25px;
  width: 25px;
  line-height: 24px;
  /* font-family: fantasy; */
  font-weight: 800;
  vertical-align: middle;
  background-color: #007bff;
  /* background-color: #ff9933; */
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.promo {
  display: inline-block;
  background-color: #00ace6;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 12px;
  margin-right: 3px;
  font-family: monospace;
}

.promo-modal {
  display: inline-block;
  background-color: #00ace6;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 12px;
  margin-right: 3px;
  text-align: center;
  font-family: monospace;
}







.traffic-camera{
    padding:20px;
}

.traffic-camera .camera-container{
    border:1px solid #d9d9d9;
}
.traffic-camera .camera-container .camera-header{
    background-color:#3399ff;
    color:#fff;
    padding:2px 10px;
}
.traffic-camera .camera-container .camera-body{
    
}
.orderfood-center {
  padding-top: 10px;
}

.orderfood-center .padding-container-left {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
}
.orderfood-center .padding-container-right {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
}

.orderfood-center .img-container {
  text-align: center;
  position: relative;
  height: 150px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.orderfood-center .img-container img {
  width: 100%;
  max-height: 150px;
  margin: auto;
}

.orderfood-center .img-container .name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}

.orderfood-list {
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}
.orderfoods-list-col-dot {
  padding: 5px 10px;
  text-align: center;
  width: 30px;
  font-size: 9px;
}

.orderfoods-list-col1 {
  padding: 15px 10px;
}
.orderfoods-list-col1 .orderfoods-name {
  font-size: 12px;
  /* color:#000; */
  color: #8c8c8c;
}
.orderfoods-list-col1 .orderfoods-desc {
  font-size: 14px;
  color: #000;
}

.orderfoods-list-col-price {
  padding: 10px;
  text-align: right;
  padding-right: 20px;
}
.orderfoods-list-col-price .orderfoods-price {
  font-size: 14px;
  color: #4d4d4d;
}

.orderfoods-list-col-qty {
  padding-right: 10px;
  width: 90px;
}
.orderfoods-list-col-qty .orderfoods-btn {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #ff9900;
  margin-top: -2px;
}
.orderfoods-list-col-qty .orderfoods-qty {
  display: inline-block;
  margin-right: 3px;
  margin-left: 3px;
  border: 1px solid #d9d9d9;
  width: 30px;
  text-align: center;
  font-size: 14px;
}

.orderfood-status-label {
  color: #8c8c8c;
  font-size: 13px;
  text-align: center;
}

.orderfood-status-value {
  font-size: 16px;
  text-align: center;
}

.orderfoods-scroll-view {
  position: absolute;
  top: 76px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #fafafa;
}

.orderfoods-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #005ce6;
  color: #fff;
  font-size: 16px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}

.orderfood-icons {
  background-color: #fff;
}

.orderfood-icons-container {
  border-bottom: 1px solid #d9d9d9;
  overflow: auto;
}

.orderfood-icon-column {
}

.orderfood-link-label {
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  color: #8c8c8c;
}

.orderfood-link-label img {
  width: 40px;
  height: 40px;
}

table.table-icon {
  width: inherit;
}

.orderfood-link-active {
  color: #00abff !important;
  padding-bottom: 25px;
}

.sub-header-history{
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    padding:10px 20px;
    position: relative;
    display: flex;
}

.back-container{
    display: inline-block;
    font-size: 16px;
    color:#0066ff;
}
.search-container{
    position: absolute;
    top:5px;
    right:50px;
    left:80px;
}
.search-container input{
    border:1px solid #d9d9d9;
    color:#333;
    border-radius:10px;
    width:100%;
    padding: 4px 15px;
    padding-left:35px;
}
.search-container input:focus{
    border:1px solid #0066ff;
}

.search-container .magnify{
    position: absolute;
    top:9px;
    left:10px;
    color:#808080;
}

.title-container{
    display: inline-block;
    margin:auto;
    height:24px;
}
.right-container{
    display: inline-block;
    font-size: 16px;
    height:24px;
    color:#0066ff;
    float:right;
    text-align: right;
}
.order-activity-detail{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.order-activity-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.order-activity-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.order-activity-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.order-activity-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.order-activity-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.order-activity-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.order-activity-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.order-activity-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.order-activity-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.order-activity-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 50px;
    background-color:#fcfcfc;
}

.historyfood-center{
    padding-top:10px;
}

.historyfood-center .padding-container-left{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px; 
    padding-right:5px;
}
.historyfood-center .padding-container-right{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:5px; 
    padding-right:10px;
}

.historyfood-center .img-container{
    text-align: center;
    position: relative;
    height: 150px;
    background-color: #fff;
    border:1px solid #d9d9d9;
}
.historyfood-center .img-container img{
    width:100%;
    max-height: 150px;
    margin: auto;
}

.historyfood-center .img-container .name{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    padding-top:2px;
    padding-bottom:2px;
    color:#ffffff;
    text-align: center;
    font-size: 14px;
}

.historyfood-list{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
.historyfoods-list-col-dot{
    padding:5px 10px;
    text-align: center;
    width:30px;
    font-size: 9px;
}

.historyfoods-list-col1{
    padding:15px 10px;
}
.historyfoods-list-col1 .historyfoods-name{
    font-size: 14px;
    /* color:#000; */
    color:#8c8c8c;
}
.historyfoods-list-col1 .historyfoods-desc{
    font-size: 14px;
    color:#000;

}

.historyfoods-list-col-price{
    padding:10px;
    text-align: right;
    padding-right:20px;
}
.historyfoods-list-col-price .historyfoods-price{
    font-size: 14px;
    color:#4d4d4d;
}

.historyfoods-list-col-qty{
    padding-right:10px;
    width:90px;
}
.historyfoods-list-col-qty .historyfoods-btn{
    display: inline-block;
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color:#ff9900;
    margin-top:-2px;
}
.historyfoods-list-col-qty .historyfoods-qty{
    display: inline-block;
    margin-right:3px;
    margin-left:3px;
    border:1px solid #d9d9d9;
    width:30px;
    text-align: center;
    font-size: 14px;
}

.historyfood-status-label{
    color:#8c8c8c;
    font-size: 13px;
    text-align: center;
}

.historyfood-status-value{
    font-size: 16px;
    text-align: center;   
}

.historyfoods-scroll-view{
    position: absolute;
    top:130px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fafafa;
}

.historyfoods-footer{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#005ce6;
    color:#fff;
    font-size: 16px;
    text-align: center;
    height:50px;
    line-height: 50px;
    vertical-align: middle;
}

.historyfood-icons{
    background-color:#fff;
	
}

.historyfood-icons-container{
    border-bottom:1px solid #d9d9d9;
    overflow: auto;
}

.historyfood-icon-column{
	
}

.historyfood-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}

.historyfood-link-label img{
    width:40px;
    height:40px;
}

table.table-icon{
    width:inherit;
}

.historyfood-link-active{
    color:#00abff !important;
	padding-bottom: 25px;
}
.history-order-food-detail{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.history-order-food-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.history-order-food-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.history-order-food-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.history-order-food-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.history-order-food-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.history-order-food-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.history-order-food-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.history-order-food-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.history-order-food-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.history-order-food-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 50px;
    background-color:#fcfcfc;
}

.historyservice-center{
    padding-top:10px;
}

.historyservice-center .padding-container-left{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px; 
    padding-right:5px;
}
.historyservice-center .padding-container-right{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:5px; 
    padding-right:10px;
}

.historyservice-center .img-container{
    text-align: center;
    position: relative;
    height: 150px;
    background-color: #fff;
    border:1px solid #d9d9d9;
}
.historyservice-center .img-container img{
    width:100%;
    max-height: 150px;
    margin: auto;
}

.historyservice-center .img-container .name{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    padding-top:2px;
    padding-bottom:2px;
    color:#ffffff;
    text-align: center;
    font-size: 14px;
}

.historyservice-list{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
.historyservices-list-col-dot{
    padding:5px 10px;
    text-align: center;
    width:30px;
    font-size: 9px;
}

.historyservices-list-col1{
    padding:15px 10px;
}
.historyservices-list-col1 .historyservices-name{
    font-size: 14px;
    /* color:#000; */
    color:#8c8c8c;
}
.historyservices-list-col1 .historyservices-desc{
    font-size: 14px;
    color:#000;

}

.historyservices-list-col-price{
    padding:10px;
    text-align: right;
    padding-right:20px;
}
.historyservices-list-col-price .historyservices-price{
    font-size: 14px;
    color:#4d4d4d;
}

.historyservices-list-col-qty{
    padding-right:10px;
    width:90px;
}
.historyservices-list-col-qty .historyservices-btn{
    display: inline-block;
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color:#ff9900;
    margin-top:-2px;
}
.historyservices-list-col-qty .historyservices-qty{
    display: inline-block;
    margin-right:3px;
    margin-left:3px;
    border:1px solid #d9d9d9;
    width:30px;
    text-align: center;
    font-size: 14px;
}

.historyservice-status-label{
    color:#8c8c8c;
    font-size: 13px;
    text-align: center;
}

.historyservice-status-value{
    font-size: 16px;
    text-align: center;   
}

.historyservices-scroll-view{
    position: absolute;
    top:130px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: auto;
    background-color:#fafafa;
}

.historyservices-footer{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#005ce6;
    color:#fff;
    font-size: 16px;
    text-align: center;
    height:50px;
    line-height: 50px;
    vertical-align: middle;
}

.historyservice-icons{
    background-color:#fff;
	
}

.historyservice-icons-container{
    border-bottom:1px solid #d9d9d9;
    overflow: auto;
}

.historyservice-icon-column{
	
}

.historyservice-link-label{
    text-align: center;
    padding:5px 10px;
    font-size: 12px;
    color:#8c8c8c;
}

.historyservice-link-label img{
    width:40px;
    height:40px;
}

table.table-icon{
    width:inherit;
}

.historyservice-link-active{
    color:#00abff !important;
	padding-bottom: 25px;
}
.history-service-center-detail{
    padding:10px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
}

.history-service-center-detail .information-container{
    border:1px solid #d9d9d9;
    background-color: #fff;
}

.history-service-center-detail .information-container .information-header{
    color:#fff;
    background-color:#00ace6;
    text-align: center;
    font-size: 14px;
}
.history-service-center-detail .information-container .information-body{
    padding:8px;
    font-size: 14px;
}

.next-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}

.table-list td{
    padding-top:3px;
    padding-bottom:3px;
}
.td-point {
    font-size: 9px;
    width:20px;
}
.td-qty{
    width:50px;
}
.td-price{
    text-align: right;
    width:100px;
}

.service-total-container{
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #d9d9d9;
}
.total-label{
    display: inline;
}
.total-amount{
    display: inline-block;
    float: right;
}


table.history-service-center-info{
    width:100%;
    border-collapse: collapse;
    border:1px solid #d9d9d9;
}
table.history-service-center-info tr{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}
table.history-service-center-info td{
    width:50%;
    font-size: 14px;
    padding-top:15px;
    padding-bottom:15px;
}
table.history-service-center-info td.col1{
    color:#666666;
    padding-left:10px;
}
table.history-service-center-info td.col2{
    color:#333;
    font-size: 15px;
    text-align: right;
    padding-right:10px;
}
table.history-service-center-info td.col3{
    color:#666666;
    font-size: 15px;
    padding-left:10px;
    padding-right:10px;
}
table.history-service-center-info td.col3 .textarea-note{
    padding:8px;
    margin-top:5px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    min-height: 50px;
    background-color:#fcfcfc;
}

.container-button {
  padding: 10px;
  margin-top: 10px;
  text-align: right;
}

.button-submit-complain {
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}

.customer-center-form-title {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  padding-top: 20px;
  color: #005ce6;
}

.customer-center-form {
  padding: 0px;
  position: absolute;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  background-color: #ffffff;
}

.field-container {
  background-color: #fff;
  padding: 5px 20px;
  padding-top: 10px;
}

.field-container .field-label {
  color: #000;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}
.field-container .field-value {
}

.field-container .field-value .time {
  text-align: center;
}

.field-container .field-value input,
select,
textarea {
  border: 0px;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px;
  background-color: #fcfcfc;
  padding: 5px;
  width: 100%;
}

select.customer-select {
  width: 100%;
  padding: 0px;
  text-align: right;
}
select.customer-select option {
  text-align: right;
}
.field-container .react-datepicker-wrapper {
  display: inherit;
}
.field-container .react-datepicker__input-container {
  display: inherit;
}
.date-picker {
  width: 100%;
}

.card-alert {
  margin: 10px;
}

.card-title {
  background-color: rgb(7, 94, 255);
  padding: 10px;
  color: white;
  font-size: 12;
}

.card-body {
  padding: 10px;
}

.card-text {
  text-align: center;
  justify-content: center;
  font-size: medium;
  font-weight: bold;
}

.card-option {
  /* text-align: center; */
  justify-content: center;
  font-size: small;
  font-weight: bold;
}

.option {
  text-align: center;
  font-size: 16px;
  font-family: monospace;
}

.link-button {
  background-color: rgb(7, 94, 255);
  color: white;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  text-align: right;
  border-radius: 8px;
}

.reschedule-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.reschedule-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    margin-bottom:5px;
    font-weight: bold;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.reschedule-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.reschedule-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}

.button-bottom{
    background-color: #005ce6;
    color: white;
    text-align: center;
    width: 100%;
}
.reschedule-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.reschedule-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    margin-bottom:5px;
    font-weight: bold;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.reschedule-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.reschedule-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}

.button-bottom{
    background-color: #005ce6;
    color: white;
    text-align: center;
    width: 100%;
}
.login-container{
    width:100%;
    height:100vh;
    background:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url(/smart/static/media/bg-login.aad7e455.jpg);
    background-size: cover;
    background-blend-mode: overlay;
}

.login-box-container{
    position: absolute;
    top:50%;
    left:50%;
    width:340px;
    background-color:#fff;
    border-radius:8px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.5);
    transform: translate(-50%, -50%);

    padding:20px;
}

.logo{
    text-align: center;
    padding:10px;
}
.logo img{
    width:200px;
}

.login-title{
    font-size: 18px;
    color:#3377ff;
    text-align: center;
    margin-bottom:10px;
}
.login-input{
    margin-bottom:8px;
    
}
.login-input input{
    padding:5px 15px;
    background-color:#fff;
    border-radius:5px;
    border:1px solid #808080;
    width:100%;
    box-sizing: border-box;
     -webkit-box-sizing:border-box;
     -moz-box-sizing: border-box;
}

.login-error{
    text-align: center;
    color:#cc0000;
    font-size: 13px;
}

.button-container{
    margin-top:20px;
}

.login-btn-login{
    background-color:#29a329;
    border:0px;
    width:100%;
    color:#fff;
    border-radius:8px;
    padding:8px;
}
.no-data{
    height:150px;
    line-height: 150px;
    text-align: center;
    color:#888;
    font-size: 20px;
}

.pad{
	padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.fullbutton{
    width:100%;
}

label {
  padding: 8px;
}

.payment-page{
    background-color:#fff;
}

.payment-title{
    text-align: center;
    font-size: 22px;
    padding-top:10px;
    padding-bottom:10px;
}

.payment-amount{
    text-align:center;
    color:#e60000;
    font-size: 24px;
    position: relative;
    padding-top:5px;
    padding-bottom:5px;
}

.payment-amount-label{
    position: absolute;
    left:20px;
    top:13px;
    font-size: 14px;
    color:#4d4d4d;
}

.payment-issuedate{
    font-size: 12px;
    text-align: center;
    padding:5px;
    color:#999999;
}

table.payment-info-container{
    width:100%;
    border-collapse: collapse;
    border-top:1px solid #d9d9d9;
    margin-top:10px;
}
table.payment-info-container tr{
    border-bottom:1px solid #d9d9d9;
}
table.payment-info-container td{
    padding-top:15px;
    padding-bottom:15px;
    padding-left: 20px;
    padding-right:20px;
    font-size: 14px;
    background-color:#fcfcfc;
    color:#4d4d4d;
}
table.payment-info-container td.payment-info-value{
    text-align: right;
    color:#333333;
    font-size: 15px;
}

.payment-button-container{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
}
.payment-button-container button{
    border-radius:0px;
    height:50px;
}

.pending{
    color:#ff8d00 !important;
}

.paid{
    color:#0066ff !important;
}
.modal_payment{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
} 
.formpayment{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 300;
}
.labelpayment{
    margin-bottom: 0rem;
    font-weight: 350;

}

.button {
    background-color: #03721b; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  .button:disabled {
    opacity: 0.5;
  }
.tab-section{
    padding-top:4px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:30px;
}
.tab-section .tab-item{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.tab-section .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}

.feedback-tab-section{
    height:30px;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 4px;
    padding-right: 4px;
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
}

.feedback-tab-section .tab-item-feedback{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.feedback-tab-section .active-tab-feedback{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}


.feedback-list-section{
    position: absolute;
    top:70px;
    bottom:10px;
    left:0px;
    right:0px;
    background-color:#fff;
    padding-left:5px;
    padding-right:5px;
    overflow: auto;
}
.feedback-list-section .list{
    border-bottom:1px solid #d9d9d9;
    padding:5px;
}
.feedback-list-section .list .title{
    color:#0066ff;
    font-size: 16px;
    font-weight: bold;
}

.feedback-list-section .list .shortdesc{
    color:#404040;
    font-size: 14px;
    margin-top:4px;
}
.feedback-list-section .list .time{
    color:#a6a6a6;
    font-size: 12px;
    margin-top:3px;
}
.feedback-list-section .list .shortdesc{
    color:#333;
    font-size: 13px;
    margin-top:3px;
}
.feedback-list-section .no-data{
    color:#8c8c8c;
    font-size:18px;
    text-align: center;
    height:100px;
    line-height: 100px; 
    vertical-align: middle;
}

.feedback-list-section .loadmore-container{
    text-align: center;
    padding:10px;
    font-size: 16px;
    color:#0066ff;
    text-decoration: underline;
}

.feedback-list-section .loadmore-loading-container{
    text-align: center;
    padding:5px;

}
.feedback-list-section .loadmore-loading-container img{
    width:50px;
    height:50px;
}

.text-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.tree-image-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.image-tree-mode{
    position: absolute;
    left: 0px;
    top: -10px;
    width: 90px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .thumbnail img {
    position: absolute;
    height: 100%;
    width: auto;
    transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait {
    width: 100%;
    height: auto;
  }
  

  .center-cropped {
    width: 100px;
    height: 100px;
    overflow:hidden;
    padding: 6px;
    margin: auto;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    /* background-color: #dbdbdb;
    padding: 6px; */
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }
  .center-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }


  .img-cropped {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
  }
  .img-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }

  .img-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  

.category-Text{
    color: #fff;
    background-color: #0066ff;
    /* display: inline-block; */
    padding: 0px 1px;
    margin-right: 5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.descriptime{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.descriptime{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.descript-video{
    position: relative;
    display: flex;
    flex-direction: row;
}

.tree-mode-image{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-align: center;
}

.timeupdate{
    flex: 1 1;
    text-align: left;
    font-size: small;
}

.reading{
    flex: 1 1;
    text-align: right;
    font-size: small;
}

.image-big-mode{
    background-color: white;
    width:100%;
    height:200px;
  }
  .image-big-mode img{
    width:100%;
    height:100%;
    left: 50%;
    position: relative;
    background-color: #dbdbdb;
    transform: translateX(-50%);
  }



  .small-video{
    position: absolute;
    left: 0px;
    top: 5px;
    width: 100px;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .descript-container-border{
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
  }

  .descript-container-small{
    margin-left: 100px;
    height: 100px;
    padding-left: 10px;
    position: relative;
  }

  .tanggal-keluhan{
    color: #333;
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    /* display: inline; */
  }
  .tanggal-response{
    color: #333;
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    /* display: inline; */
  }

  .description-keluhan{
    color: rgb(0, 0, 0);
    font-size: 11px;
  }

  .desc-text{
    color: #999999;
    font-size: 12px;
    height: auto;
    text-overflow: inherit;
    overflow: hidden;
    white-space: normal;
  }

  .status-keluhan{
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .color-status-done{
     font-size: 11px;
     color:#0066ff !important;
  }
  .color-status-proggres{
    color: #ff8d00 !important;
    font-size: 11px;
  }



.tab-section-category-feedback{
    /* padding-left: 10px;
    padding-right: 10px; */
    border-bottom:1px solid #d9d9d9;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: inherit;
    overflow: auto;
}

.tab-section-all-feedback-cateogry{
  /* padding-left: 10px; */
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.tab-section-list-colom{
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: inherit;
  overflow: auto;
  z-index: 1;
  background-color: rgba(0,0,0,.2);
  

  /* position: 'absolute',
  top:0,bottom:0,left:0,right:0,flex:1,backgroundColor:'rgba(0,0,0,.2)',alignItems: 'center',alignSelf: 'center',justifyContent: 'center',padding:100,elevation: 6 */

}

.tab-section-list-feedback-category{
  padding: 6px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fafbfc;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;

}

.type-selection{
    margin-left: 6px;
    text-align: left;
    justify-content: center;
    font-size: 14px;
    border-left :3px solid #0066ff;
}

.listing-item-category-feedback{
    flex-direction: column;
}

.listing-item-down-category-feedback{
  flex-direction: column;
  border-right: 1px solid #d9d9d9;
}

.icon-filter-cateory-feedback{
    margin-left: 10px;
    margin-right: 5px;
    flex-wrap: nowrap;
    flex-direction: column;
}

.icon-undo-filter-cateory-feedback{
  padding-left: 10px;
  padding-right: 6px;
  flex-wrap: nowrap;
  flex-direction: column;
  border-left: 1px solid #d9d9d9;
  
}

.data-item-tab-category{
  padding: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}


.tab-section-all-list-cateogry{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.listing-item-category-feedback-down{
  flex-direction: column;
}

.feedback-category-tab-section{
  padding:4px;
  background-color:#fff;
  height: auto;
  overflow: auto;
  white-space: nowrap;
}


.feedback-category-tab-section .tab-item-feedback{
  text-align: center;
  font-size: 12px;
  padding-bottom:5px;
  padding-bottom: 5px;
  /* margin: 6px; */
  background-color: #fafbfc;
  color:#bfbfbf;
}
.feedback-category-tab-section .active-tab-feedback{
  color:#0066ff;
  border-bottom:2px solid #0066ff;
}


.feedback-list-section-data{
  position: absolute;
  top:50px;
  bottom:10px;
  left:0px;
  right:0px;
  background-color:#fff;
  /* padding-left:5px;
  padding-right:5px; */
  overflow: auto;
}
.feedback-list-section-data .list{
  border-bottom:1px solid #d9d9d9;
  /* padding:5px; */
}
.feedback-list-section-data .list .title{
  color:#0066ff;
  font-size: 16px;
  font-weight: bold;
}

.feedback-list-section-data .list .shortdesc{
  color:#404040;
  font-size: 14px;
  margin-top:4px;
}
.feedback-list-section-data .list .time{
  color:#a6a6a6;
  font-size: 12px;
  margin-top:3px;
}
.feedback-list-section-data .list .shortdesc{
  color:#333;
  font-size: 13px;
  margin-top:3px;
}
.feedback-list-section-data .no-data{
  color:#8c8c8c;
  font-size:18px;
  text-align: center;
  height:100px;
  line-height: 100px; 
  vertical-align: middle;
}

.feedback-list-section-data .loadmore-container{
  text-align: center;
  padding:10px;
  font-size: 16px;
  color:#0066ff;
  text-decoration: underline;
}

.feedback-list-section-data .loadmore-loading-container{
  text-align: center;
  padding:5px;

}
.feedback-list-section-data .loadmore-loading-container img{
  width:50px;
  height:50px;
}
.date-feedback-keluhan{
    display: inline-block;
    float: left;
}
.date-feedback-respone{
    display: inline-block;
    float: left;
}

.coloum-feedback{
    flex-direction: column;
}
.health-park-introduction{
    background-color:#fff;
}

.health-park-introduction .tab-section{
    padding-top:0px !important;
    background-color:rgb(255, 255, 255);
    border-bottom:1px solid #d9d9d9;
    height:40px;
}
.health-park-introduction .tab-section .tab-item{
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    color:#000;
    height:39px;
    line-height: 39px;
    padding-bottom:0px !important;
}
.health-park-introduction .tab-section .active-tab{
    color:rgb(117, 15, 233);
    border-bottom:2px solid rgb(117, 15, 233);
}

.health-park-introduction .body-section{
    padding-left: 0px;
    padding-right: 0px;

}

.health-park-introduction .img-container{
    width: 100%;
    margin-bottom:10px;
}
.health-park-introduction .img-container img{
    width: 100%;
}


.health-park-introduction .img-container2{
    width: 100%;
    margin-bottom:2px;
}
.health-park-introduction .img-container2 img{
    width: 100%;
}
.health-park-introduction .title{
    padding:2px;
    color:#000;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-weight: bold;
    margin-bottom:10px;
}
.health-park-introduction .description{
    padding:2px;
    color:#000;
    font-size:14px;
    text-align: justify;
}

.health-list{
    border-bottom:10px solid #d9d9d9;
    background-color:#fff;
}

.health-list2{
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
}

.health-list-col-dot{
    padding:5px 10px;
    text-align: center;
    width:30px;
    font-size: 9px;
}

.health-list-col1{
    padding:15px 10px;
}
.health-list-col1 .health-name{
    font-size: 16px;
    color:#000;
}
.health-list-col1 .health-desc{
    font-size: 13px;
    color:#8c8c8c;
}

.health-list-col-price{
    padding:10px;
    text-align: right;
    padding-right:20px;
}
.health-list-col-price .health-price{
    font-size: 14px;
    color:#4d4d4d;
}

.health-list-col-qty{
    padding-right:10px;
    width:90px;
}
.health-list-col-qty .health-btn{
    display: inline-block;
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color:#ff9900;
    margin-top:-2px;
}
.health-list-col-qty .health-qty{
    display: inline-block;
    margin-right:3px;
    margin-left:3px;
    border:1px solid #d9d9d9;
    width:30px;
    text-align: center;
    font-size: 14px;
}

.health-list-col1 .health-checkin{
    font-size:13px;
    color:#8c8c8c;
}
.health-list-col1 .health-checkout{
    font-size:13px;
    color:#8c8c8c;
}

.today-declare{
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-bottom:1px solid #d9d9d9;
}

.today-declare-u{
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-bottom:5px solid #d9d9d9;
}

.flex-left{
    flex: 1 1;
    font-size: small;
    text-align: left;
}

.flex-right{
    flex: 1 1;
    text-align: right;
    font-size: small;
}


.daily-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:35px;
    color:#005ce6;
}

.daily-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    /* overflow: auto; */
    background-color:#ffffff;
    padding-bottom: 40px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#666;
    font-size: 14px;
    margin-bottom:5px;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.daily-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.daily-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.field-container .some-custom-class{
    position: relative;
}
.date-picker{
    width:100%;
}

.field-container .form-check-input{
    position: relative;
    margin-left: 20px;
}

.label-radiobutton{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
.trip-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:35px;
    color:#005ce6;
}

.trip-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    /* overflow: auto; */
    background-color:#ffffff;
    padding-bottom: 40px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#666;
    font-size: 14px;
    margin-bottom:5px;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.trip-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.trip-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.field-container .some-custom-class{
    position: relative;
}
.date-picker{
    width:100%;
}

.field-container .form-check-input{
    position: relative;
    margin-left: 20px;
}

.daily-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:35px;
    color:#005ce6;
}

.daily-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    /* overflow: auto; */
    background-color:#ffffff;
    padding-bottom: 40px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#666;
    font-size: 14px;
    margin-bottom:5px;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.daily-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.daily-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.field-container .some-custom-class{
    position: relative;
}
.date-picker{
    width:100%;
}

.field-container .form-check-input{
    position: relative;
    margin-left: 20px;
}
.trip-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:35px;
    color:#005ce6;
}

.trip-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    /* overflow: auto; */
    background-color:#ffffff;
    padding-bottom: 40px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#666;
    font-size: 14px;
    margin-bottom:5px;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.trip-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.trip-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.field-container .some-custom-class{
    position: relative;
}
.date-picker{
    width:100%;
}

.field-container .form-check-input{
    position: relative;
    margin-left: 20px;
}
.tab-section{
    padding-top:4px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:30px;
}
.tab-section .tab-item{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.tab-section .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}
.covidnews-list-section{
    position: absolute;
    top:80px;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#fff;
    padding-left:5px;
    padding-right:5px;
    overflow: auto;
}
.covidnews-list-section .list{
    border-bottom:1px solid #d9d9d9;
    padding:5px;
}
.covidnews-list-section .list .title{
    color:#0066ff;
    font-size: 16px;
    font-weight: bold;
}

.covidnews-list-section .list .shortdesc{
    color:#404040;
    font-size: 14px;
    margin-top:4px;
}
.covidnews-list-section .list .time{
    color:#a6a6a6;
    font-size: 12px;
    margin-top:3px;
}
.covidnews-list-section .list .shortdesc{
    color:#333;
    font-size: 13px;
    margin-top:3px;
}
.covidnews-list-section .no-data{
    color:#8c8c8c;
    font-size:18px;
    text-align: center;
    height:100px;
    line-height: 100px; 
    vertical-align: middle;
}

.covidnews-list-section .loadmore-container{
    text-align: center;
    padding:10px;
    font-size: 16px;
    color:#0066ff;
    text-decoration: underline;
}

.covidnews-list-section .loadmore-loading-container{
    text-align: center;
    padding:5px;

}
.covidnews-list-section .loadmore-loading-container img{
    width:50px;
    height:50px;
}

.text-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.tree-image-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.image-tree-mode{
    position: absolute;
    left: 0px;
    top: -10px;
    width: 90px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .thumbnail img {
    position: absolute;
    height: 100%;
    width: auto;
    transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait {
    width: 100%;
    height: auto;
  }
  

  .center-cropped {
    width: 100px;
    height: 100px;
    overflow:hidden;
    padding: 6px;
    margin: auto;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    /* background-color: #dbdbdb;
    padding: 6px; */
    transform: translateX(-50%);
  }


  .img-cropped {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
  }
  .img-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }

  .img-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  

.category-Text{
    color: #fff;
    background-color: #0066ff;
    /* display: inline-block; */
    padding: 0px 1px;
    margin-right: 5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.descriptime{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.descript-video{
    position: relative;
    display: flex;
    flex-direction: row;
}

.tree-mode-image{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-align: center;
}

.timeupdate{
    flex: 1 1;
    text-align: left;
    font-size: small;
}

.reading{
    flex: 1 1;
    text-align: right;
    font-size: small;
}

.image-big-mode{
    background-color: white;
    width:100%;
    height:200px;
  }
  .image-big-mode img{
    width:100%;
    height:100%;
    left: 50%;
    position: relative;
    background-color: #dbdbdb;
    transform: translateX(-50%);
  }



  .small-video{
    position: absolute;
    left: 0px;
    top: 5px;
    width: 100px;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .descript-container-border{
    border-bottom: 1px solid #d9d9d9;
  }

  .descript-container-small{
    margin-left: 100px;
    height: 100px;
    padding-left: 10px;
    position: relative;
  }

  .headline-text-name{
    color: #333;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    /* display: inline; */
  }

  .sortdesc-headline-text{
    color: #999999;
    font-size: 12px;
    height: 38px;
    text-overflow: inherit;
    overflow: hidden;
    white-space: normal;
  }

.tab-section-category-news{
    padding: 10px;
    border-bottom:1px solid #d9d9d9;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: inherit;
    overflow: auto;
}

.listing-item-category-news{
    flex: 1 1;
}

.icon-filter-cateory-news{
    flex:0 1
}


.covid-center-form-title{
  font-size: 18px;
  text-align: center;
  padding:10px;
  padding-top:20px;
  color:#005ce6;
}

.covid-center-form1{
  padding:0px;
  position: absolute;
  top:80px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow: auto;
  background-color:#ffffff;
}

.covid-center-form{
  padding:0px;
  position: absolute;
  top:80px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow: auto;
  background-color:#ffffff;
}


.field-container{
  background-color:#fff;
  padding:5px 20px;
  padding-top:10px;
}

.field-container .field-label{
  color:#000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom:5px;
}

.field-container .bottom-bordered{
  border-bottom:1px solid #d9d9d9;
  padding-bottom:5px;
}
.field-container .field-value{
  
}
.field-container .field-value input,select, textarea{
  border:0px;
  border-bottom:1px solid #d9d9d9 !important;
  border-radius: 0px;
  background-color:#fcfcfc;
  padding:5px;
  width:100%;
  margin-bottom: 20px;
}

select.covid-select{
  width:100%;
  padding:0px;
  text-align:right;
}
select.covid-select option{
  text-align:right;
}
.field-container .react-datepicker-wrapper{
  display: inherit;
}
.field-container .react-datepicker__input-container{
  display: inherit;
}
.date-picker{
  width:100%;
}

.submit-button{
  position: absolute;
  bottom:0;
  left:0;
  right:0;
  color:rgb(0, 0, 0);
  background-color:#f8f8f8;
  margin-top: 10px;
  padding:12px;
  text-align: center;
}

.covid-button-container{
  position: absolute;
  bottom:0px;
  left:0px;
  right:0px;
  padding: 5px;
  background-color: white;
}
.covid-button-container button{
  border-radius:0px;
  height:50px;
  border-radius: 10px;
}

.continer-payment-method-covid{
  margin-bottom: 50px;
}

.continer-request-covid{
  margin-bottom: 10px;
}

.covid-list-menu{
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.whitebg{
    background-color:#fff;
}
.top-navigation{
    padding:5px 10px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
}
.top-navigation .back-button{
    color:#0066ff;
    display: inline;
}

.heart-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.heart-content .heart-title{
    color:#000;
    font-size: 16px;
    font-weight: bold;
    margin-top:5px;
}
.heart-content .heart-description{
    font-size: 14px;
    text-align: justify;
    margin-top:5px;
}
.heart-content .heart-datetime{
    font-size: 12px;
    color:#bfbfbf;
    text-align: left;
    margin-top:5px;
}
.whitebg{
    background-color:#fff;
}
.top-navigation{
    padding:5px 10px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
}
.top-navigation .back-button{
    color:#0066ff;
    display: inline;
}

.heart-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.heart-content .heart-title{
    color:#000;
    font-size: 16px;
    font-weight: bold;
    margin-top:5px;
}
.heart-content .heart-description{
    font-size: 14px;
    text-align: justify;
    margin-top:5px;
}
.heart-content .heart-datetime{
    font-size: 12px;
    color:#bfbfbf;
    text-align: left;
    margin-top:5px;
}
.history-list-section{
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:#fff;
    padding-left:5px;
    padding-right:5px;
    overflow: auto;
}
.history-list-section .list{
    border-bottom:1px solid #d9d9d9;
    padding:5px;
}
.history-list-section .list .title{
    color:#0066ff;
    font-size: 16px;
    font-weight: bold;
}

.history-list-section .list .shortdesc{
    color:#404040;
    font-size: 14px;
    margin-top:4px;
}
.history-list-section .list .time{
    color:#a6a6a6;
    font-size: 12px;
    margin-top:3px;
}
.history-list-section .list .shortdesc{
    color:#333;
    font-size: 13px;
    margin-top:3px;
}
.history-list-section .no-data{
    color:#8c8c8c;
    font-size:18px;
    text-align: center;
    height:100px;
    line-height: 100px; 
    vertical-align: middle;
}

.history-list-section .loadmore-container{
    text-align: center;
    padding:10px;
    font-size: 16px;
    color:#0066ff;
    text-decoration: underline;
}

.history-list-section .loadmore-loading-container{
    text-align: center;
    padding:5px;

}
.history-list-section .loadmore-loading-container img{
    width:50px;
    height:50px;
}

.text-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.tree-image-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.image-tree-mode{
    position: absolute;
    left: 0px;
    top: -10px;
    width: 90px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .thumbnail img {
    position: absolute;
    height: 100%;
    width: auto;
    transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait {
    width: 100%;
    height: auto;
  }
  

  .center-cropped {
    width: 100px;
    height: 100px;
    overflow:hidden;
    padding: 6px;
    margin: auto;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    /* background-color: #dbdbdb;
    padding: 6px; */
    transform: translateX(-50%);
  }


  .img-cropped {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
  }
  .img-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }

  .img-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  

.category-Text{
    color: #fff;
    background-color: #0066ff;
    /* display: inline-block; */
    padding: 0px 1px;
    margin-right: 5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.history-descime{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.history-desc-video{
    position: relative;
    display: flex;
    flex-direction: row;
}

.tree-mode-image{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-align: center;
}

.timeupdate{
    flex: 1 1;
    text-align: left;
    font-size: small;
}

.reading{
    flex: 1 1;
    text-align: right;
    font-size: small;
}

.image-big-mode{
    background-color: white;
    width:100%;
    height:150px;
  }
  .image-big-mode img{
    width:100%;
    height:100%;
    left: 50%;
    position: relative;
    background-color: #dbdbdb;
    transform: translateX(-50%);
  }



  .small-video{
    position: absolute;
    left: 0px;
    top: 5px;
    width: 100px;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .history-desc-container-border{
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
  }

  .history-desc-container-small{
    height: auto;
    padding-left: 10px;
    position: relative;
  }

  .headline-text-name{
    color: #333;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    /* display: inline; */
  }

  .sortdesc-headline-text{
    color: #999999;
    font-size: 12px;
    height: 38px;
    text-overflow: inherit;
    overflow: hidden;
    white-space: normal;
  }

.tab-section-category-news{
    padding: 10px;
    border-bottom:1px solid #d9d9d9;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: inherit;
    overflow: auto;
}

.listing-item-category-news{
    flex: 1 1;
}

.icon-filter-cateory-news{
    flex:0 1
}

.whitebg{
    background-color:#fff;
}
.top-navigation{
    padding:5px 10px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
}
.top-navigation .back-button{
    color:#0066ff;
    display: inline;
}

.news-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.news-content .news-title{
    color:#000;
    font-size: 16px;
    font-weight: bold;
    margin-top:5px;
}
.news-content .news-description{
    font-size: 14px;
    text-align: justify;
    margin-top:5px;
}
.news-content .news-datetime{
    font-size: 12px;
    color:#bfbfbf;
    text-align: left;
    margin-top:5px;
}

.descriptime-detail{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.tab-section{
    padding-top:4px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    height:30px;
}
.tab-section .tab-item{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.tab-section .active-tab{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}

.wfh-tab-section{
    height:30px;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 4px;
    padding-right: 4px;
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
}

.wfh-tab-section .tab-item-wfh{
    text-align: center;
    font-size: 12px;
    padding-bottom:5px;
    color:#bfbfbf;
}
.wfh-tab-section .active-tab-wfh{
    color:#0066ff;
    border-bottom:2px solid #0066ff;
}


.wfh-list-section{
    position: absolute;
    top:45px;
    bottom:10px;
    left:0px;
    right:0px;
    background-color:#fff;
    padding-left:5px;
    padding-right:5px;
    overflow: auto;
}
.wfh-list-section .list{
    border-bottom:1px solid #d9d9d9;
    padding:5px;
}
.wfh-list-section .list .title{
    color:#0066ff;
    font-size: 16px;
    font-weight: bold;
}

.wfh-list-section .list .shortdesc{
    color:#404040;
    font-size: 14px;
    margin-top:4px;
}
.wfh-list-section .list .time{
    color:#a6a6a6;
    font-size: 12px;
    margin-top:3px;
}
.wfh-list-section .list .shortdesc{
    color:#333;
    font-size: 13px;
    margin-top:3px;
}
.wfh-list-section .no-data{
    color:#8c8c8c;
    font-size:18px;
    text-align: center;
    height:100px;
    line-height: 100px; 
    vertical-align: middle;
}

.wfh-list-section .loadmore-container{
    text-align: center;
    padding:10px;
    font-size: 16px;
    color:#0066ff;
    text-decoration: underline;
}

.wfh-list-section .loadmore-loading-container{
    text-align: center;
    padding:5px;

}
.wfh-list-section .loadmore-loading-container img{
    width:50px;
    height:50px;
}

.text-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.tree-image-mode{
    height: auto;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.image-tree-mode{
    position: absolute;
    left: 0px;
    top: -10px;
    width: 90px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .thumbnail img {
    position: absolute;
    height: 100%;
    width: auto;
    transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait {
    width: 100%;
    height: auto;
  }
  

  .center-cropped {
    width: 100px;
    height: 100px;
    overflow:hidden;
    padding: 6px;
    margin: auto;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    /* background-color: #dbdbdb;
    padding: 6px; */
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }
  .center-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }


  .img-cropped {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 90px;
    vertical-align: middle;
    text-align: center;
  }
  .img-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;

  }

  .img-cropped img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  

.category-Text{
    color: #fff;
    background-color: #0066ff;
    /* display: inline-block; */
    padding: 0px 1px;
    margin-right: 5px;
    font-size: 12px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.descriptime{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.descript-video{
    position: relative;
    display: flex;
    flex-direction: row;
}

.tree-mode-image{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-align: center;
}

.timeupdate{
    flex: 1 1;
    text-align: left;
    font-size: small;
}

.reading{
    flex: 1 1;
    text-align: right;
    font-size: small;
}

.image-big-mode{
    background-color: white;
    width:100%;
    height:200px;
  }
  .image-big-mode img{
    width:100%;
    height:100%;
    left: 50%;
    position: relative;
    background-color: #dbdbdb;
    transform: translateX(-50%);
  }



  .small-video{
    position: absolute;
    left: 0px;
    top: 5px;
    width: 100px;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .descript-container-border{
    border-bottom: 1px solid #d9d9d9;
  }

  .descript-container-small{
    margin-left: 100px;
    height: 100px;
    padding-left: 10px;
    position: relative;
  }

  .headline-text-name{
    color: #333;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    /* display: inline; */
  }

  .sortdesc-headline-text{
    color: #999999;
    font-size: 12px;
    height: 40px;
    text-overflow: inherit;
    overflow: hidden;
    white-space: normal;
  }

.tab-section-category-wfh{
    /* padding-left: 10px;
    padding-right: 10px; */
    border-bottom:1px solid #d9d9d9;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: inherit;
    overflow: auto;
}

.tab-section-all-wfh-cateogry{
  /* padding-left: 10px; */
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.tab-section-list-colom{
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: inherit;
  overflow: auto;
  z-index: 1;
  background-color: rgba(0,0,0,.2);
  

  /* position: 'absolute',
  top:0,bottom:0,left:0,right:0,flex:1,backgroundColor:'rgba(0,0,0,.2)',alignItems: 'center',alignSelf: 'center',justifyContent: 'center',padding:100,elevation: 6 */

}

.tab-section-list-wfh-category{
  padding: 6px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fafbfc;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;

}

.type-selection{
    margin-left: 6px;
    text-align: left;
    justify-content: center;
    font-size: 14px;
    border-left :3px solid #0066ff;
}

.listing-item-category-wfh{
    flex-direction: column;
}

.listing-item-down-category-wfh{
  flex-direction: column;
  border-right: 1px solid #d9d9d9;
}

.icon-filter-cateory-wfh{
    margin-left: 10px;
    margin-right: 5px;
    flex-wrap: nowrap;
    flex-direction: column;
}

.icon-undo-filter-cateory-wfh{
  padding-left: 10px;
  padding-right: 6px;
  flex-wrap: nowrap;
  flex-direction: column;
  border-left: 1px solid #d9d9d9;
  
}

.data-item-tab-category{
  padding: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}


.tab-section-all-list-cateogry{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom:1px solid #d9d9d9;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: inherit;
  overflow: auto;
}

.listing-item-category-wfh-down{
  flex-direction: column;
}

.wfh-category-tab-section{
  padding:4px;
  background-color:#fff;
  height: auto;
  overflow: auto;
  white-space: nowrap;
}


.wfh-category-tab-section .tab-item-wfh{
  text-align: center;
  font-size: 12px;
  padding-bottom:5px;
  padding-bottom: 5px;
  /* margin: 6px; */
  background-color: #fafbfc;
  color:#bfbfbf;
}
.wfh-category-tab-section .active-tab-wfh{
  color:#0066ff;
  border-bottom:2px solid #0066ff;
}


.wfh-list-section-data{
  position: absolute;
  top:45px;
  bottom:10px;
  left:0px;
  right:0px;
  background-color:#fff;
  padding-left:5px;
  padding-right:5px;
  overflow: auto;
}
.wfh-list-section-data .list{
  border-bottom:1px solid #d9d9d9;
  padding:5px;
}
.wfh-list-section-data .list .title{
  color:#0066ff;
  font-size: 16px;
  font-weight: bold;
}

.wfh-list-section-data .list .shortdesc{
  color:#404040;
  font-size: 14px;
  margin-top:4px;
}
.wfh-list-section-data .list .time{
  color:#a6a6a6;
  font-size: 12px;
  margin-top:3px;
}
.wfh-list-section-data .list .shortdesc{
  color:#333;
  font-size: 13px;
  margin-top:3px;
}
.wfh-list-section-data .no-data{
  color:#8c8c8c;
  font-size:18px;
  text-align: center;
  height:100px;
  line-height: 100px; 
  vertical-align: middle;
}

.wfh-list-section-data .loadmore-container{
  text-align: center;
  padding:10px;
  font-size: 16px;
  color:#0066ff;
  text-decoration: underline;
}

.wfh-list-section-data .loadmore-loading-container{
  text-align: center;
  padding:5px;

}
.wfh-list-section-data .loadmore-loading-container img{
  width:50px;
  height:50px;
}
.whitebg{
    background-color:#fff;
}
.top-navigation{
    padding:5px 10px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
}
.top-navigation .back-button{
    color:#0066ff;
    display: inline;
}
.privacy-title {
    color:#000;
    font-size: 20px;
    font-weight: bold;
    margin-top:5px;
    margin-bottom: 10px;
    padding:10px;
    border-bottom:2px solid #000;
}

.privacy-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.privacy-content .privacy-description{
    font-size: 14px;
    text-align: justify;
    margin-top:5px;
}
.privacy-content .privacy-datetime{
    font-size: 12px;
    color:#bfbfbf;
    text-align: left;
    margin-top:5px;
}
.text-bold{
    font-weight: bold;
}
.text-right{
    text-align: right;
}


.merchant-profile-market {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile-market .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile-market .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile-market .menu-section-container {
}
.merchant-profile-market .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile-market .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile-market
  .menu-section-container
  td.category-column
  .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile-market .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile-market .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile-market
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile-market .menu-banner-container {
  padding: 10px;
}
.merchant-profile-market .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile-market .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile-market .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile-market .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile-market .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url(/smart/static/media/Market.ae7c47ff.png) no-repeat top;
  background-size: 100% 150px;
}
.merchant-profile-market .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile-market .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile-market .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile-market .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile-market .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile-market .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile-market .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile-market .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile-market .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile-market .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile-market .merchant-info-container table {
  width: 100%;
}
.merchant-profile-market .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile-market .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile-market .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile-market .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile-market .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile-market .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile-market .cart-icon {
  font-size: 17px;
}

.merchant-payment-market .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url(/smart/static/media/Market.ae7c47ff.png) no-repeat top;
  background-size: 100% 110px;
}

.merchant-payment-market .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment-market .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment-market .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment-market .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment-market .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  /* background-color: #ff9933; */
  background-color: #007bff;
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}

.merchant-profile-online {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile-online .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile-online .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile-online .menu-section-container {
}
.merchant-profile-online .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile-online .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile-online
  .menu-section-container
  td.category-column
  .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile-online .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile-online .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile-online
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile-online .menu-banner-container {
  padding: 10px;
}
.merchant-profile-online .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile-online .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile-online .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile-online .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile-online .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url(/smart/static/media/Automotive.932ae93c.png) no-repeat
    top;
  background-size: 100% 150px;
}
.merchant-profile-online .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile-online .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile-online .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile-online .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile-online .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile-online .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile-online .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile-online .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile-online .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile-online .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile-online .merchant-info-container table {
  width: 100%;
}
.merchant-profile-online .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile-online .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile-online .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile-online .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile-online .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile-online .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile-online .cart-icon {
  font-size: 17px;
}

.merchant-payment-online .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url(/smart/static/media/Automotive.932ae93c.png) no-repeat
    top;
  background-size: 100% 110px;
}

.merchant-payment-online .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment-online .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment-online .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment-online .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment-online .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  background-color: #007bff;
  /* background-color: #ff9933; */
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}

.merchant-profile-house {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile-house .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile-house .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile-house .menu-section-container {
}
.merchant-profile-house .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile-house .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile-house
  .menu-section-container
  td.category-column
  .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile-house .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile-house .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile-house
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile-house .menu-banner-container {
  padding: 10px;
}
.merchant-profile-house .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile-house .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile-house .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile-house .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile-house .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url(/smart/static/media/House-Keeping.4b430a96.png)
    no-repeat top;
  background-size: 100% 150px;
}
.merchant-profile-house .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile-house .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile-house .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile-house .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile-house .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile-house .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile-house .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile-house .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile-house .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile-house .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile-house .merchant-info-container table {
  width: 100%;
}
.merchant-profile-house .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile-house .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile-house .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile-house .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile-house .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile-house .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile-house .cart-icon {
  font-size: 17px;
}

.merchant-payment-house .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url(/smart/static/media/House-Keeping.4b430a96.png)
    no-repeat top;
  background-size: 100% 110px;
}

.merchant-payment-house .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment-house .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment-house .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment-house .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment-house .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  background-color: #007bff;
  /* background-color: #ff9933; */
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}

.merchant-profile-maintenance {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile-maintenance .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile-maintenance .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile-maintenance .menu-section-container {
}
.merchant-profile-maintenance .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile-maintenance .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile-maintenance
  .menu-section-container
  td.category-column
  .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile-maintenance
  .menu-section-container
  td.category-column
  .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile-maintenance .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile-maintenance
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile-maintenance .menu-banner-container {
  padding: 10px;
}
.merchant-profile-maintenance .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile-maintenance .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile-maintenance .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile-maintenance .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile-maintenance .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url(/smart/static/media/Maintenence.143010bd.png)
    no-repeat top;
  background-size: 100% 150px;
}
.merchant-profile-maintenance .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile-maintenance .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile-maintenance .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile-maintenance .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile-maintenance .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile-maintenance .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile-maintenance .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile-maintenance .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile-maintenance .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile-maintenance .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile-maintenance .merchant-info-container table {
  width: 100%;
}
.merchant-profile-maintenance .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile-maintenance .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile-maintenance .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile-maintenance .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile-maintenance .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile-maintenance .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile-maintenance .cart-icon {
  font-size: 17px;
}

.merchant-payment-maintenance .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url(/smart/static/media/Maintenence.143010bd.png)
    no-repeat top;
  background-size: 100% 110px;
}

.merchant-payment-maintenance .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment-maintenance .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment-maintenance .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment-maintenance .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment-maintenance .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  /* background-color: #ff9933; */
  background-color: #007bff;
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}

.merchant-profile-moving {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile-moving .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile-moving .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile-moving .menu-section-container {
}
.merchant-profile-moving .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile-moving .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile-moving
  .menu-section-container
  td.category-column
  .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile-moving .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile-moving .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile-moving
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile-moving .menu-banner-container {
  padding: 10px;
}
.merchant-profile-moving .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile-moving .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile-moving .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile-moving .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile-moving .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url(/smart/static/media/Moving.86b431af.png) no-repeat top;
  background-size: 100% 150px;
}
.merchant-profile-moving .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile-moving .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile-moving .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile-moving .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile-moving .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile-moving .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile-moving .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile-moving .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile-moving .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile-moving .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile-moving .merchant-info-container table {
  width: 100%;
}
.merchant-profile-moving .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile-moving .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile-moving .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile-moving .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile-moving .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile-moving .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile-moving .cart-icon {
  font-size: 17px;
}

.merchant-payment-moving .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url(/smart/static/media/Moving.86b431af.png) no-repeat top;
  background-size: 100% 110px;
}

.merchant-payment-moving .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment-moving .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment-moving .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment-moving .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment-moving .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  /* background-color: #ff9933; */
  background-color: #007bff;
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}

.merchant-profile-massages {
  position: fixed;
  top: 47px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  overflow: auto;
}

.merchant-profile-massages .picture-container {
  background-color: #fff;
  text-align: center;
  padding: 0px !important;
  height: 151px;
  line-height: 151px;
  vertical-align: middle;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0px;
}
.merchant-profile-massages .picture-container img {
  max-height: 150px;
  max-width: 150px;
  vertical-align: middle;
  margin-top: -4px;
}

.merchant-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.merchant-info .relative-container {
  position: relative;
}

.merchant-info .imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  /*border:1px solid #d9d9d9;*/
  vertical-align: middle;
  text-align: center;
}

.merchant-info .imgitem-container img {
  max-height: 60px;
  max-width: 60px;
  margin: auto;
}
.merchant-info .data-container {
  margin-left: 90px;
  height: 110px;
  padding-left: 5px;
  position: relative;
  padding-bottom: 10px;
  /*border-bottom:1px solid #d9d9d9;*/
}

.merchant-info .full-left {
  margin-left: 0px;
}

.merchant-info .data-container .name-container {
  color: #333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.merchant-info .data-container .name-container .category {
  color: #fff;
  background-color: #00cc99;
  display: inline;
  padding: 0px 1px;
  margin-right: 5px;
  font-size: 12px;
}
.merchant-info .data-container .shortdesc-container {
  color: #999999;
  font-size: 12px;
  height: 53px;
  overflow: hidden;
}
.merchant-info .data-container .tags-container {
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding-left: 5px;
}
.merchant-info .data-container .tags-container .tag {
  font-size: 12px;
  background-color: #e6e6e6;
  color: #808080;
  display: inline;
  padding: 3px 10px;
  border-radius: 10px;
  margin-right: 3px;
}

.merchant-profile-massages .menu-section-container {
}
.merchant-profile-massages .menu-section-container table {
  width: 100%;
  border-collapse: collapse;
}
.merchant-profile-massages .menu-section-container td.category-column {
  width: 80px;
  background-color: #fdfdfd;
  color: #8c8c8c;
  vertical-align: top;
  padding-right: 0px;
}
.merchant-profile-massages
  .menu-section-container
  td.category-column
  .category-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.merchant-profile-massages .menu-section-container td.category-column .active {
  background-color: #fff;
  color: #333333;
  font-weight: bold;
}

.merchant-profile-massages .menu-section-container td.menu-column {
  vertical-align: top;
}
.merchant-profile-massages
  .menu-section-container
  td.menu-column
  .commodity-list-container {
  /* padding-bottom:10px; */
}

.order-summary {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.continer-payment-method {
  margin-bottom: 120px;
}

.continer-payment-summary {
  margin-bottom: 0px;
}

.continer-detail-address {
  margin-bottom: 10px;
}
.payment-method {
  background-color: #e9e9e9;
  padding: 10px;
  font-weight: bold;
}

.shopping-cart-button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #ff9933;
  color: #000;
  padding: 3px 10px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.merchant-profile-massages .menu-banner-container {
  padding: 10px;
}
.merchant-profile-massages .menu-banner-container img {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}
.merchant-profile-massages .tabs-container {
  border-bottom: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.merchant-profile-massages .tabs-container .tab {
  display: inline-block;
  padding: 0px 15px;
  color: #808080;
}
.merchant-profile-massages .tabs-container .tab-active {
  display: inline-block;
  border-bottom: 1px solid #ff9933;
  color: #ff9933;
}

.merchant-profile-massages .profile-outer-container {
  padding: 10px;
  padding-top: 50px;
  background: url(/smart/static/media/Message.0cc0dc4d.png) no-repeat
    top;
  background-size: 100% 150px;
}
.merchant-profile-massages .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(221, 220, 220, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(179, 177, 177, 0.8);
  position: relative;
  margin-top: 85px;
  background-color: #fff;
}
.merchant-profile-massages .profile-inner-container .profile-name {
  font-style: normal;
  font-size: 20px;
  font-family: monospace;
}
.merchant-profile-massages .profile-inner-container .profile-desc {
  font-size: 14px;
  color: #808080;
  font-family: serif;
  font-family: initial;
}
.merchant-profile-massages .profile-inner-container .profile-close {
  /* float: right; */
  text-align: right;
  padding-right: 6px;
  font-weight: bold;
  color: #ff0018;
}

.merchant-profile-massages .profile-inner-container .profile-tags {
  margin-top: 5px;
}
.merchant-profile-massages .profile-inner-container .profile-tags .tag {
  display: inline-block;
  /* background-color: #ff9933; */
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 14px;
  margin-right: 3px;
  font-style: italic;
}
.merchant-profile-massages .profile-inner-container .profile-img {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 6px;
  background-color: "#fff";
}
.merchant-profile-massages .profile-inner-container .profile-img img {
  width: 95%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(179, 177, 177, 0.8);
}

.merchant-profile-massages .no-info {
  height: 140px;
  text-align: center;
  background-color: #fff;
  color: #808080;
  line-height: 140px;
  vertical-align: middle;
}

.merchant-profile-massages .merchant-info-container {
  padding: 20px 10px;
}
.merchant-profile-massages .merchant-info-container table {
  width: 100%;
}
.merchant-profile-massages .merchant-info-container td {
  padding: 8px 5px;
  font-size: 14px;
}
.merchant-profile-massages .merchant-info-container tr {
  border-bottom: 1px solid #f2f2f2;
}

.merchant-profile-massages .floating-cart {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  z-index: 1000;
  background-color: #d9d9d9;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.merchant-profile-massages .floating-cart table.table-cart {
  width: 100%;
  height: 50px;
}
.merchant-profile-massages .floating-cart table.table-cart td {
  padding-left: 20px;
  padding-right: 20px;
}
.merchant-profile-massages .floating-cart table.table-cart td.total-col {
  text-align: right;
}
.merchant-profile-massages .cart-icon {
  font-size: 17px;
}

.merchant-payment-massages .profile-outer-container {
  padding: 10px;
  padding-top: 40px;
  background-color: #000000;
  /* background:url('../../../images/merchantbg.jpg') no-repeat top; */
  background: url(/smart/static/media/Message.0cc0dc4d.png) no-repeat
    top;
  background-size: 100% 110px;
}

.merchant-payment-massages .close-button {
  height: 24px;
  width: 30px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  float: right;
}

.merchant-payment-massages .profile-inner-container {
  border-radius: 6px;
  box-shadow: 0px 8px 15px 8px rgba(242, 242, 242, 0.5);
  padding: 6px 10px;
  border: 1px solid rgba(163, 163, 163, 0.8);
  position: relative;
  background-color: #fff;
  margin-top: 75px;
}
.merchant-payment-massages .profile-inner-container .profile-name {
  font-size: 18px;
  text-align: center;
}
.merchant-payment-massages .profile-inner-container .profile-desc {
  font-size: 22px;
  text-align: center;
  color: #059228;
}
.merchant-payment-massages .profile-inner-container .profile-items {
  margin-top: 5px;
  text-align: center;
  color: #a6a6a6;
}

.payment-select-method {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.payment-btn-container {
  position: absolute;
  margin: 10px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 60;
  /* background-color: #ff9933; */
  background-color: #007bff;
  /* border-top: 1px solid #d9d9d9; */
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  z-index: 1000;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.payment-btn-container button {
  border-radius: 0px;
  height: 50px;
}

