.container-button {
  padding: 10px;
  margin-top: 10px;
  text-align: right;
}

.button-submit-complain {
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}

.customer-center-form-title {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  padding-top: 20px;
  color: #005ce6;
}

.customer-center-form {
  padding: 0px;
  position: absolute;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  background-color: #ffffff;
}

.field-container {
  background-color: #fff;
  padding: 5px 20px;
  padding-top: 10px;
}

.field-container .field-label {
  color: #000;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}
.field-container .field-value {
}

.field-container .field-value .time {
  text-align: center;
}

.field-container .field-value input,
select,
textarea {
  border: 0px;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px;
  background-color: #fcfcfc;
  padding: 5px;
  width: 100%;
}

select.customer-select {
  width: 100%;
  padding: 0px;
  text-align: right;
}
select.customer-select option {
  text-align: right;
}
.field-container .react-datepicker-wrapper {
  display: inherit;
}
.field-container .react-datepicker__input-container {
  display: inherit;
}
.date-picker {
  width: 100%;
}

.card-alert {
  margin: 10px;
}

.card-title {
  background-color: rgb(7, 94, 255);
  padding: 10px;
  color: white;
  font-size: 12;
}

.card-body {
  padding: 10px;
}

.card-text {
  text-align: center;
  justify-content: center;
  font-size: medium;
  font-weight: bold;
}

.card-option {
  /* text-align: center; */
  justify-content: center;
  font-size: small;
  font-weight: bold;
}

.option {
  text-align: center;
  font-size: 16px;
  font-family: monospace;
}

.link-button {
  background-color: rgb(7, 94, 255);
  color: white;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  text-align: right;
  border-radius: 8px;
}
