.service-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.service-center-form1{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
}

.service-center-form{
    padding:0px;
    position: absolute;
    top:55px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
    padding-bottom: 60px;
    margin-bottom: 60px;
}


.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    font-weight: bold;
    margin-bottom:5px;
}

.field-container .bottom-bordered{
    border-bottom:1px solid #d9d9d9;
    padding-bottom:5px;
}
.field-container .field-value{
    
}
.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
    margin-bottom: 20px;
}

select.service-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.service-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}

.submit-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:rgb(0, 0, 0);
    background-color:#f8f8f8;
    margin-top: 10px;
    padding:12px;
    text-align: center;
}

.service-button-container{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    padding: 5px;
    background-color: white;
}
.service-button-container button{
    border-radius:0px;
    height:50px;
    border-radius: 10px;
}

.continer-payment-method-service{
    margin-bottom: 50px;
}

.continer-request-service{
    margin-bottom: 10px;
}

.service-list-menu{
    background-color: #e9e9e9;
    padding: 10px;
    font-weight: bold;
}

.next-button-service{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:#fff;
    background-color:#00ace6;
    padding:8px;
    text-align: center;
}