.list-item .foodgarden-name {
  font-size: 15px !important;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
  font-family: monospace;
}

.list-item .foodgarden-imgitem-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  line-height: 95px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  justify-items: center;
  background-color: white;
}

.list-item .foodgarden-imgitem-container img {
  width: 90%;
  height: 80%;
  max-height: 200px;
  max-width: 200px;
  margin: auto;
}

.list-item .data-container .foodgarden-tags-container {
  padding-left: 0px;
}
.list-item .data-container .foodgarden-tags-container .tag {
  font-size: 12px;
  background-color: #f2f2f2;
  color: #808080;
  display: inline;
  padding: 1px 5px;
  border-radius: 0px;
  margin-right: 3px;
}

.list-item .foodgarden-price-container {
  color: #000000;
  font-size: 14px;
  max-height: 38px;
  overflow: auto;
}

.list-item .foodgarden-shortdesc-container {
  color: #999999;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60ch;
}

.list-item .foodgarden-price-container span {
  font-size: 16px;
}
.list-item .foodgarden-price-container span.stroke-price {
  font-size: 12px;
  color: #cccccc;
  text-decoration: line-through;
}

.list-item .foodgarden-sold-container {
  font-size: 12px;
  color: #a6a6a6;
  margin-top: 5px;
}

.list-item .relative-container .qty-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-align: center;
  display: inline-block;
}
.list-item .relative-container .qty-container .qty-value {
  text-align: center;
  display: inline-block;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  vertical-align: middle;
  float: right;
}
.list-item .relative-container .qty-container .plus-minus-button {
  width: 100px;
  display: inline-block;
  text-align: center;
}
/* .list-item .relative-container .qty-container .plus-minus-button .plus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-left: 10px;
  font-weight: bold;
}
.list-item .relative-container .qty-container .plus-minus-button .minus-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-right: 10px;
  font-weight: bold;
} */

.list-item .foodgarden-data-container {
  border-bottom: 0px;
  margin-bottom: 10px;
}

.list-item .data-container {
  margin-left: 100px;
  height: 100px;
  padding-left: 10px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
}

.product-content {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.product-content .product {
  color: #000;
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.product-content .product-title {
  color: #000;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}

.product-content .product-image {
  text-align: center;
}

.product-content .product-image img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  margin: auto;
}

.product-content .product-description {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.product-content .product-price {
  font-size: 14px;
  color: #ff6666;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}

.product-content .product-tags-container {
  padding-left: 0px;
  font-size: 12px;
  text-align: center;
}
.product-content .product-tags-container .tag {
  font-size: 12px;
  background-color: #f2f2f2;
  color: #808080;
  display: inline;
  padding: 1px 5px;
  border-radius: 0px;
  margin-right: 3px;
}

.product-content .relative-container .qty-container {
  /* position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px; */
  margin-top: 10px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  float: right;
  position: relative;
  left: -38%;
}
.product-content .relative-container .qty-container .qty-value {
  text-align: center;
  display: inline-block;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  vertical-align: middle;
  float: right;
}

.product-content .qty-container .plus-minus-button .plus-button {
  border-radius: 25%;
  height: 25px;
  width: 25px;
  line-height: 24px;
  /* font-family: fantasy; */
  font-weight: 800;
  vertical-align: middle;
  background-color: #007bff;
  /* background-color: #ff9933; */
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-left: 10px;
}

.product-content .qty-container .plus-minus-button .minus-button {
  border-radius: 25%;
  height: 25px;
  width: 25px;
  line-height: 24px;
  /* font-family: fantasy; */
  font-weight: 800;
  vertical-align: middle;
  background-color: #007bff;
  /* background-color: #ff9933; */
  color: #fff;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.promo {
  display: inline-block;
  background-color: #00ace6;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 12px;
  margin-right: 3px;
  font-family: monospace;
}

.promo-modal {
  display: inline-block;
  background-color: #00ace6;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 12px;
  margin-right: 3px;
  text-align: center;
  font-family: monospace;
}
