.service-center-form-title{
    font-size: 18px;
    text-align: center;
    padding:10px;
    padding-top:20px;
    color:#005ce6;
}

.service-center-form{
    padding:0px;
    position: absolute;
    top:45px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color:#ffffff;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.field-container{
    background-color:#fff;
    padding:5px 20px;
    padding-top:10px;
}

.field-container .field-label{
    color:#000;
    font-size: 14px;
    margin-bottom:5px;
    font-weight: bold;
}
.field-container .field-value{
    
}

.field-container .field-value .time{
    text-align: center;
}

.field-container .field-value input,select, textarea{
    border:0px;
    border-bottom:1px solid #d9d9d9 !important;
    border-radius: 0px;
    background-color:#fcfcfc;
    padding:5px;
    width:100%;
}

select.service-select{
    width:100%;
    padding:0px;
    text-align:right;
}
select.service-select option{
    text-align:right;
}
.field-container .react-datepicker-wrapper{
    display: inherit;
}
.field-container .react-datepicker__input-container{
    display: inherit;
}
.date-picker{
    width:100%;
}
.submit-button{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    color:rgb(0, 0, 0);
    background-color:#f8f8f8;
    margin-top: 10px;
    padding:12px;
    text-align: center;
}

.list-item-wedding .commodity-name{
    /*font-size: 18px !important;*/
    font-weight: bold;
}

.list-item-wedding .commodity-imgitem-container{
    position: absolute;
    left:0px;
    top:0px;
    width:80px;
    height:80px;
    line-height: 80px;
    /*border:1px solid #d9d9d9;*/
    vertical-align: middle;
    text-align: center;

}

.list-item-wedding .commodity-imgitem-container img{
    max-height:80px;
    max-width:80px;
    margin:auto;
}

.list-item-wedding .data-container .commodity-tags-container{
    padding-left:0px;
}
.list-item-wedding .data-container .commodity-tags-container .tag{
    font-size: 12px;
    background-color:#f2f2f2;
    color:#808080;
    display: inline;
    padding:1px 5px;
    border-radius: 0px;
    margin-right:3px;

}

.list-item-wedding .commodity-price-container{
    color:#ff9933;
    font-size: 14px;
    max-height: 38px;
    overflow: auto;
}

.list-item-wedding .commodity-price-container span{
    font-size: 16px;
}
.list-item-wedding .commodity-price-container span.stroke-price{
    font-size: 12px;
    color:#cccccc;
    text-decoration: line-through;
}

.list-item-wedding .commodity-sold-container{
    font-size:20px;
    color:#a6a6a6;
    margin-top:5px;
}

.list-item-wedding .relative-container .qty-container{
    position: absolute;
    right:10px;
    bottom:10px;
    text-align:center;
	display: inline-block;
}
.list-item-wedding .relative-container .qty-container .qty-value{
    text-align: center;
    display: inline-block;
    margin: auto;
    padding-left:3px;
    padding-right:3px;
    background-color:#ffffff;
	vertical-align: middle;
	float:right;
}
.list-item-wedding .relative-container .qty-container .plus-minus-button{
    width:100px;
	display: inline-block;
	text-align: center;
}
.list-item-wedding .relative-container .qty-container .plus-minus-button .plus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#ff9933;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float:right;
    margin-left:10px;
    
}
.list-item-wedding .relative-container .qty-container .plus-minus-button .minus-button{
    border-radius:50%;
    height:24px;
    width:24px;
    line-height: 24px;
    vertical-align: middle;
    background-color:#ff9933;
    color:#000;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    float: right;
	margin-right:10px;
}

.list-item-wedding .commodity-data-container{
    border-bottom:0px;
    margin-bottom:10px;
}

.product-content{
    background-color:#fff;
    padding-left:10px;
    padding-right:10px;
}

.product-content .product{
    color:#000;
    font-size: 16px;
    margin-top:5px;
	text-align: center;
}

.product-content .product-title{
    color:#000;
    font-size: 20px;
    margin-top:5px;
	text-align: center;
	font-weight: bold;
}

.product-content .product-image {
	text-align: center;
}

.product-content .product-image img{
    width:100%;
	height:100%;
    max-height: 300px;
	max-width: 300px;
    margin: auto;
}

.product-content .product-description{
    font-size: 16px;
    margin-top:5px;
	text-align: center;
}

.product-content .product-price{
    font-size: 14px;
    color:#ff6666;
    margin-top:5px;
	text-align: center;
	font-weight: bold;
}

.product-content .product-tags-container{
    padding-left:0px;
	font-size: 12px;
	text-align: center;
}
.product-content .product-tags-container .tag{
    font-size: 12px;
    background-color:#f2f2f2;
    color:#808080;
    display: inline;
    padding:1px 5px;
    border-radius: 0px;
    margin-right:3px;

}