
.list-item .data-container3{
    margin:10px;
    padding-left:5px;
    position: relative;
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
}

.list-item .relative-container .imgitem-container3 img{
    height:50px;
    width:50px;
    border-radius: 50px;
    object-fit: cover;
    margin:auto;
}

.list-item .relative-container .imgitem-container3{
    position: absolute;
    left:-10px;
    top:-2px;
    width:90px;
    height:90px;
    vertical-align: middle;
    text-align: center;

}

.list-item .imgitem-container2{
    position: absolute;
    left:0px;
    top:0px;
    width:90px;
    text-align: center;
    padding-top:5px;

}

.list-item .imgitem-container2 img{
    max-height:70px;
    max-width:70px;
    margin:auto;
}

.list-item .moments-name{
    font-size: 15px !important;
    color:#005580;
    margin-left: 60px;
}
.list-item .moments-desc{
    margin-top: 15px;
    font-size: 14px !important;
    color:#666666;
}

.list-item .moments-date{
    font-size: 12px !important;
    color:#666666;
    margin-left: 60px;
}

.comment-container{
    margin-bottom:5px;
    
    background-color:#f2f2f2;
    font-size: 13px;
    padding:5px;
}

.commenter-label{
    font-size: 13px;
    color:#808080;
    margin-bottom:2px;
}
.commenter-value{
    font-size: 14px !important;
    color:#404040;
}

.showmore{
    font-size: 13px;
    color:#005580;
    margin-top:10px;
}

.addcomment-container{
    margin-top:10px;
    font-size: 13px;
}
.addcomment-container button{
    font-size: 13px;
}

.commenter{
    color:#005580;
}