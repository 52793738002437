.login-container{
    width:100%;
    height:100vh;
    background:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url('../../../images/bg-login.jpg');
    background-size: cover;
    background-blend-mode: overlay;
}

.login-box-container{
    position: absolute;
    top:50%;
    left:50%;
    width:340px;
    background-color:#fff;
    border-radius:8px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.5);
    transform: translate(-50%, -50%);

    padding:20px;
}

.logo{
    text-align: center;
    padding:10px;
}
.logo img{
    width:200px;
}

.login-title{
    font-size: 18px;
    color:#3377ff;
    text-align: center;
    margin-bottom:10px;
}
.login-input{
    margin-bottom:8px;
    
}
.login-input input{
    padding:5px 15px;
    background-color:#fff;
    border-radius:5px;
    border:1px solid #808080;
    width:100%;
    box-sizing: border-box;
     -webkit-box-sizing:border-box;
     -moz-box-sizing: border-box;
}

.login-error{
    text-align: center;
    color:#cc0000;
    font-size: 13px;
}

.button-container{
    margin-top:20px;
}

.login-btn-login{
    background-color:#29a329;
    border:0px;
    width:100%;
    color:#fff;
    border-radius:8px;
    padding:8px;
}